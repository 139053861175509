/// <reference types="./layout.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $button from "../../ds/ds/components/button.mjs";
import * as $modal from "../../ds/ds/components/modal.mjs";
import * as $utils from "../../ds/ds/utils.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import { toList } from "../gleam.mjs";
import * as $ai_config from "../view/ai_config.mjs";
import * as $ai_search from "../view/ai_search.mjs";
import * as $automations from "../view/automations.mjs";
import * as $ingestion_control from "../view/ingestion_control.mjs";
import * as $navbar from "../view/navbar.mjs";
import * as $organization_setup from "../view/organization_setup.mjs";
import * as $overview from "../view/overview.mjs";
import * as $s from "../view/styles.mjs";

function view_rerun_insights(org_id) {
  let title = ("Are you sure you want to rerun the insights for " + org_id) + "?";
  let explanations = "Make sure that if you've already ran this action, it is completed. Also, it would be preferable not to upload sources in the Content Library during the process.";
  let on_cancel = $e.on_click(new $msg.UserClosedModal());
  let on_confirm = $e.on_click(new $msg.UserReRanInsights());
  return $modal.modal(
    toList([]),
    toList([
      $modal.body(
        toList([$utils.stop_propagation()]),
        toList([
          $modal.title(toList([]), toList([$h.text(title)])),
          $modal.explanations(toList([]), toList([$h.text(explanations)])),
          $modal.actions(
            toList([]),
            toList([
              $button.primary(toList([on_cancel]), toList([$h.text("Cancel")])),
              $button.secondary(
                toList([on_confirm]),
                toList([$h.text("Submit")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function view_reingest_data(org_id) {
  let title = ("Are you sure you want to reingest the data for " + org_id) + "?";
  let explanations = "This will go through all the data sources in the database, create messages of ingestion, upgrade all versions and update the weaviate.";
  let on_cancel = $e.on_click(new $msg.UserClosedModal());
  let on_confirm = $e.on_click(new $msg.UserReIngestedData());
  return $modal.modal(
    toList([]),
    toList([
      $modal.body(
        toList([$utils.stop_propagation()]),
        toList([
          $modal.title(toList([]), toList([$h.text(title)])),
          $modal.explanations(toList([]), toList([$h.text(explanations)])),
          $modal.actions(
            toList([]),
            toList([
              $button.primary(toList([on_cancel]), toList([$h.text("Cancel")])),
              $button.secondary(
                toList([on_confirm]),
                toList([$h.text("Submit")]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

function view_modal(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.display_modal;
  if ($1 instanceof $data.NoModal) {
    return $el.none();
  } else if ($1 instanceof $data.ReRunInsights) {
    let $2 = $data.current_org_id(data);
    if ($2 instanceof $option.Some) {
      let org_id = $2[0];
      return view_rerun_insights(org_id);
    } else {
      return $el.none();
    }
  } else {
    let $2 = $data.current_org_id(data);
    if ($2 instanceof $option.Some) {
      let org_id = $2[0];
      return view_reingest_data(org_id);
    } else {
      return $el.none();
    }
  }
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $el.fragment(
    toList([
      $s.layout(
        toList([]),
        toList([
          $navbar.navbar(model),
          $s.main(
            toList([$a.id("layout-main")]),
            toList([
              (() => {
                let $1 = data.route;
                if ($1 instanceof $route.Overview) {
                  return $overview.view(model);
                } else if ($1 instanceof $route.ContentLibrary) {
                  return $ingestion_control.view(model);
                } else if ($1 instanceof $route.OrganizationSetup) {
                  return $organization_setup.view(model);
                } else if ($1 instanceof $route.AiConfig) {
                  return $ai_config.view(model);
                } else if ($1 instanceof $route.AiSearch) {
                  return $ai_search.view(model);
                } else if ($1 instanceof $route.Automations) {
                  return $automations.view(model);
                } else {
                  return $el.none();
                }
              })(),
            ]),
          ),
        ]),
      ),
      view_modal(model),
    ]),
  );
}
