/// <reference types="./styles.d.mts" />
import * as $colors from "../../ds/ds/colors.mjs";
import * as $css from "../../sketch/sketch/css.mjs";
import * as $length from "../../sketch/sketch/css/length.mjs";
import { px, vh } from "../../sketch/sketch/css/length.mjs";
import * as $h from "../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../gleam.mjs";

export function layout(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.grid_template_columns("auto 1fr"),
      $css.grid_template_areas(toList(["navbar main"])),
      $css.height(vh(100)),
      $css.color($colors.light.onyx),
      $css.overflow_y("hidden"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function main(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.padding(px(40)),
      $css.gap(px(32)),
      $css.overflow_y("auto"),
      $css.height(vh(100)),
    ]),
  );
  return $h.main(_pipe, attrs, children);
}
