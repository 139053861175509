/// <reference types="./ai_config.d.mts" />
import * as $ai_config from "../../common/data/ai_config.mjs";
import { AiConfig, Config, LLMConfig, QueryConfig } from "../../common/data/ai_config.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $ai_config_effects from "../admin/effects/ai_config.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import { toList } from "../gleam.mjs";

function set_ai_config_effect(data) {
  return $effect.batch(
    toList([
      (() => {
        let _pipe = data.ai_config;
        let _pipe$1 = $option.map(
          _pipe,
          (_capture) => {
            return $ai_config_effects.set_ai_config(data, _capture);
          },
        );
        return $option.unwrap(_pipe$1, $effect.none());
      })(),
    ]),
  );
}

function set_style_guidelines(ai_config, style_guidelines) {
  return $option.map(
    ai_config,
    (ai_config) => {
      let _record = ai_config;
      return new AiConfig(
        _record.org_id,
        (() => {
          let _record$1 = ai_config.config;
          return new Config(
            new $option.Some(style_guidelines),
            _record$1.ai_system,
            _record$1.llm_config,
            _record$1.models_config,
            _record$1.query_config,
            _record$1.retriever_config,
            _record$1.pii_vocabulary,
          );
        })(),
      );
    },
  );
}

function set_pii_vocabulary_in_ai_config(ai_config, pii_vocabulary) {
  return $option.map(
    ai_config,
    (ai_config) => {
      let config = (() => {
        let _record = ai_config.config;
        return new Config(
          _record.style_guidelines,
          _record.ai_system,
          _record.llm_config,
          _record.models_config,
          _record.query_config,
          _record.retriever_config,
          pii_vocabulary,
        );
      })();
      let _record = ai_config;
      return new AiConfig(_record.org_id, config);
    },
  );
}

function set_ai_system(ai_config, ai_system) {
  return $option.map(
    ai_config,
    (ai_config) => {
      let _record = ai_config;
      return new AiConfig(
        _record.org_id,
        (() => {
          let _record$1 = ai_config.config;
          return new Config(
            _record$1.style_guidelines,
            new $option.Some(ai_system),
            _record$1.llm_config,
            _record$1.models_config,
            _record$1.query_config,
            _record$1.retriever_config,
            _record$1.pii_vocabulary,
          );
        })(),
      );
    },
  );
}

function set_llm_limit(ai_config, llm_limit) {
  let $ = $int.parse(llm_limit);
  if ($.isOk()) {
    let llm_limit$1 = $[0];
    return $option.map(
      ai_config,
      (ai_config) => {
        let _record = ai_config;
        return new AiConfig(
          _record.org_id,
          (() => {
            let _record$1 = ai_config.config;
            return new Config(
              _record$1.style_guidelines,
              _record$1.ai_system,
              new $option.Some(new LLMConfig(llm_limit$1)),
              _record$1.models_config,
              _record$1.query_config,
              _record$1.retriever_config,
              _record$1.pii_vocabulary,
            );
          })(),
        );
      },
    );
  } else {
    return ai_config;
  }
}

function set_query_limit(ai_config, query_limit) {
  let $ = $int.parse(query_limit);
  if ($.isOk()) {
    let query_limit$1 = $[0];
    return $option.map(
      ai_config,
      (ai_config) => {
        let _record = ai_config;
        return new AiConfig(
          _record.org_id,
          (() => {
            let _record$1 = ai_config.config;
            return new Config(
              _record$1.style_guidelines,
              _record$1.ai_system,
              _record$1.llm_config,
              _record$1.models_config,
              $option.map(
                ai_config.config.query_config,
                (c) => {
                  let _record$2 = c;
                  return new QueryConfig(
                    query_limit$1,
                    _record$2.alpha,
                    _record$2.parent_lookup,
                    _record$2.hyde,
                    _record$2.extract_question,
                    _record$2.translate_on,
                  );
                },
              ),
              _record$1.retriever_config,
              _record$1.pii_vocabulary,
            );
          })(),
        );
      },
    );
  } else {
    return ai_config;
  }
}

function set_query_alpha(ai_config, alpha) {
  let parse_int = (() => {
    let _pipe = $int.parse(alpha);
    return $result.map(_pipe, $int.to_float);
  })();
  let $ = (() => {
    let _pipe = $float.parse(alpha);
    return $result.lazy_or(_pipe, () => { return parse_int; });
  })();
  if ($.isOk()) {
    let alpha$1 = $[0];
    return $option.map(
      ai_config,
      (ai_config) => {
        let _record = ai_config;
        return new AiConfig(
          _record.org_id,
          (() => {
            let _record$1 = ai_config.config;
            return new Config(
              _record$1.style_guidelines,
              _record$1.ai_system,
              _record$1.llm_config,
              _record$1.models_config,
              $option.map(
                ai_config.config.query_config,
                (c) => {
                  let _record$2 = c;
                  return new QueryConfig(
                    _record$2.limit,
                    alpha$1,
                    _record$2.parent_lookup,
                    _record$2.hyde,
                    _record$2.extract_question,
                    _record$2.translate_on,
                  );
                },
              ),
              _record$1.retriever_config,
              _record$1.pii_vocabulary,
            );
          })(),
        );
      },
    );
  } else {
    return ai_config;
  }
}

function set_query_translate_on(ai_config, bool) {
  return $option.map(
    ai_config,
    (ai_config) => {
      let _record = ai_config;
      return new AiConfig(
        _record.org_id,
        (() => {
          let _record$1 = ai_config.config;
          return new Config(
            _record$1.style_guidelines,
            _record$1.ai_system,
            _record$1.llm_config,
            _record$1.models_config,
            $option.map(
              ai_config.config.query_config,
              (c) => {
                let _record$2 = c;
                return new QueryConfig(
                  _record$2.limit,
                  _record$2.alpha,
                  _record$2.parent_lookup,
                  _record$2.hyde,
                  _record$2.extract_question,
                  new $option.Some(bool),
                );
              },
            ),
            _record$1.retriever_config,
            _record$1.pii_vocabulary,
          );
        })(),
      );
    },
  );
}

function set_model(ai_config, model) {
  return $option.map(
    ai_config,
    (ai_config) => {
      let _record = ai_config;
      return new AiConfig(
        _record.org_id,
        (() => {
          let _record$1 = ai_config.config;
          return new Config(
            _record$1.style_guidelines,
            _record$1.ai_system,
            _record$1.llm_config,
            $option.map(
              ai_config.config.models_config,
              (c) => {
                let _record$2 = c;
                return new $ai_config.ModelsConfig(
                  _record$2.rag,
                  _record$2.rag_temperature,
                  _record$2.target,
                  new $option.Some(model),
                  new $option.Some(model),
                  new $option.Some(model),
                  new $option.Some(model),
                  toList([model]),
                );
              },
            ),
            _record$1.query_config,
            _record$1.retriever_config,
            _record$1.pii_vocabulary,
          );
        })(),
      );
    },
  );
}

function set_retriever_option(ai_config, retriever_option) {
  return $option.map(
    ai_config,
    (ai_config) => {
      let _record = ai_config;
      return new AiConfig(
        _record.org_id,
        (() => {
          let _record$1 = ai_config.config;
          return new Config(
            _record$1.style_guidelines,
            _record$1.ai_system,
            _record$1.llm_config,
            _record$1.models_config,
            _record$1.query_config,
            new $option.Some(new $ai_config.RetrieverConfig(retriever_option)),
            _record$1.pii_vocabulary,
          );
        })(),
      );
    },
  );
}

export function update(data, msg) {
  if (msg instanceof $msg.UserWroteStyleGuidelines) {
    let value = msg.value;
    return [
      (() => {
        let _record = data;
        return new $data.Data(
          _record.access_token,
          _record.client,
          _record.content_library,
          _record.ingestion_control_sources,
          _record.ingestion_control_sources_visibility,
          _record.ai_search_results,
          _record.ai_search_input,
          _record.display_modal,
          _record.loading,
          _record.permissions,
          _record.route,
          _record.user,
          _record.users,
          value,
          _record.questions_answers_examples,
          _record.ai_config,
          _record.tags,
          _record.tag_input,
          _record.qm_rows,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof $msg.UserSubmittedStyleGuidelines) {
    let _pipe = set_style_guidelines(
      data.ai_config,
      data.style_guidelines_input,
    );
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    let _pipe$2 = $data.reset_style_guidelines_input(_pipe$1);
    return ((data) => { return [data, set_ai_config_effect(data)]; })(_pipe$2);
  } else if (msg instanceof $msg.UserWroteQuestionsAnswersExamples) {
    let value = msg.value;
    return [
      (() => {
        let _record = data;
        return new $data.Data(
          _record.access_token,
          _record.client,
          _record.content_library,
          _record.ingestion_control_sources,
          _record.ingestion_control_sources_visibility,
          _record.ai_search_results,
          _record.ai_search_input,
          _record.display_modal,
          _record.loading,
          _record.permissions,
          _record.route,
          _record.user,
          _record.users,
          _record.style_guidelines_input,
          value,
          _record.ai_config,
          _record.tags,
          _record.tag_input,
          _record.qm_rows,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof $msg.UserSubmittedQuestionsAnswersExamples) {
    return [
      (() => {
        let _record = data;
        return new $data.Data(
          _record.access_token,
          _record.client,
          _record.content_library,
          _record.ingestion_control_sources,
          _record.ingestion_control_sources_visibility,
          _record.ai_search_results,
          _record.ai_search_input,
          _record.display_modal,
          _record.loading,
          _record.permissions,
          _record.route,
          _record.user,
          _record.users,
          _record.style_guidelines_input,
          "",
          _record.ai_config,
          _record.tags,
          _record.tag_input,
          _record.qm_rows,
        );
      })(),
      $ai_config_effects.send_style_guidelines_generation_request(data),
    ];
  } else if (msg instanceof $msg.ApiReturnedAiConfig) {
    let ai_config = msg.value;
    let _pipe = $data.set_ai_config(data, ai_config);
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedGeneratedStyleGuidelines) {
    let value = msg.value;
    let _pipe = set_style_guidelines(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return ((data) => { return [data, set_ai_config_effect(data)]; })(_pipe$1);
  } else if (msg instanceof $msg.ApiSavedAiConfig) {
    let ai_config = msg.ai_config;
    let _pipe = (() => {
      let _record = data;
      return new $data.Data(
        _record.access_token,
        _record.client,
        _record.content_library,
        _record.ingestion_control_sources,
        _record.ingestion_control_sources_visibility,
        _record.ai_search_results,
        _record.ai_search_input,
        _record.display_modal,
        _record.loading,
        _record.permissions,
        _record.route,
        _record.user,
        _record.users,
        _record.style_guidelines_input,
        _record.questions_answers_examples,
        ai_config,
        _record.tags,
        _record.tag_input,
        _record.qm_rows,
      );
    })();
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.UserClickedAddPiiVocabulary) {
    let updated_vocabulary = (() => {
      let _pipe = data.ai_config;
      let _pipe$1 = $option.map(
        _pipe,
        (ai_config) => {
          let pii_vocabulary = ai_config.config.pii_vocabulary;
          let _pipe$1 = pii_vocabulary;
          return $list.append(_pipe$1, toList([""]));
        },
      );
      return $option.unwrap(_pipe$1, toList([]));
    })();
    let _pipe = set_pii_vocabulary_in_ai_config(
      data.ai_config,
      updated_vocabulary,
    );
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserRemovedPiiVocabulary) {
    let index = msg.index;
    let updated_vocabulary = (() => {
      let _pipe = data.ai_config;
      let _pipe$1 = $option.map(
        _pipe,
        (ai_config) => {
          let pii_vocabulary = ai_config.config.pii_vocabulary;
          let _pipe$1 = pii_vocabulary;
          let _pipe$2 = $list.index_map(_pipe$1, (s, i) => { return [i, s]; });
          let _pipe$3 = $list.filter(
            _pipe$2,
            (tag) => { return tag[0] !== index; },
          );
          return $list.map(_pipe$3, $pair.second);
        },
      );
      return $option.unwrap(_pipe$1, toList([]));
    })();
    let _pipe = set_pii_vocabulary_in_ai_config(
      data.ai_config,
      updated_vocabulary,
    );
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserSubmittedAiConfig) {
    return [data, set_ai_config_effect(data)];
  } else if (msg instanceof $msg.UserResetStyleGuidelines) {
    let _pipe = set_style_guidelines(data.ai_config, "");
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return ((data) => { return [data, set_ai_config_effect(data)]; })(_pipe$1);
  } else if (msg instanceof $msg.UserUpdatedAiSystem) {
    let value = msg.value;
    let _pipe = set_ai_system(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedLlmLimit) {
    let value = msg.value;
    let _pipe = set_llm_limit(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedPiiVocabulary) {
    let index = msg.index;
    let value = msg.value;
    let updated_vocabulary = (() => {
      let _pipe = data.ai_config;
      let _pipe$1 = $option.map(
        _pipe,
        (ai_config) => {
          let pii_vocabulary = ai_config.config.pii_vocabulary;
          let _pipe$1 = pii_vocabulary;
          let _pipe$2 = $list.index_map(_pipe$1, (s, i) => { return [i, s]; });
          let _pipe$3 = $list.key_set(_pipe$2, index, value);
          return $list.map(_pipe$3, $pair.second);
        },
      );
      return $option.unwrap(_pipe$1, toList([]));
    })();
    let _pipe = set_pii_vocabulary_in_ai_config(
      data.ai_config,
      updated_vocabulary,
    );
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedQueryLimit) {
    let value = msg.value;
    let _pipe = set_query_limit(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedQueryAlpha) {
    let value = msg.value;
    let _pipe = set_query_alpha(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedTranslateOn) {
    let value = msg.bool;
    let _pipe = set_query_translate_on(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.UserUpdatedModel) {
    let value = msg.value;
    let _pipe = set_model(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  } else {
    let value = msg.value;
    let _pipe = set_retriever_option(data.ai_config, value);
    let _pipe$1 = ((_capture) => { return $data.set_ai_config(data, _capture); })(
      _pipe,
    );
    return $pair.new$(_pipe$1, $effect.none());
  }
}
