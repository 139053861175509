/// <reference types="./inspect_source.d.mts" />
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList } from "../../gleam.mjs";
import * as $common from "../../view/ingestion_control/common.mjs";

export function view(model, source_id) {
  return $el.fragment(
    toList([
      (() => {
        let _pipe = $h.div(
          toList([]),
          toList([
            $button.primary(
              toList([
                $e.on_click(new $msg.UserClickedOnFetchDataPoints(source_id)),
              ]),
              toList([$h.text("Get data points")]),
            ),
          ]),
        );
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
      $common.preview_source(model, source_id, new $option.None()),
    ]),
  );
}
