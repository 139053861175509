/// <reference types="./skeleton.d.mts" />
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../sketch/sketch/css/length.mjs";
import * as $transform from "../../../sketch/sketch/css/transform.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import { toList } from "../../gleam.mjs";

function skeleton_loader(child) {
  let _pipe = $css.class$(
    toList([
      $css.position("absolute"),
      $css.top(px(0)),
      $css.bottom(px(0)),
      $css.left(px(0)),
      $css.right(px(0)),
      $css.background($colors.light.grey_50),
      $css.overflow("hidden"),
      $css.border_radius(px(4)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

function skeleton_wave() {
  let _pipe = $css.class$(
    toList([
      $css.position("relative"),
      $css.overflow("hidden"),
      $css.background(
        ("linear-gradient(90deg, transparent, " + $colors.light.grey_200) + ", transparent)",
      ),
      $css.animation("wave 1s linear 0.5s infinite alternate"),
      $css.transform(toList([$transform.translate_x(percent(100))])),
      $css.position("absolute"),
      $css.top(px(0)),
      $css.bottom(px(0)),
      $css.left(px(0)),
      $css.right(px(0)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([]));
}

export function skeleton(width, height) {
  let _pipe = $css.class$(
    toList([
      $css.position("relative"),
      $css.width(px(width)),
      $css.height(px(height)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([skeleton_loader(skeleton_wave())]));
}

export function full_width(height) {
  let _pipe = $css.class$(
    toList([
      $css.position("relative"),
      $css.width(percent(100)),
      $css.height(px(height)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([skeleton_loader(skeleton_wave())]));
}

export function hide_loading(value, width, height, next) {
  if (!value.isOk()) {
    return skeleton(width, height);
  } else {
    let value$1 = value[0];
    return next(value$1);
  }
}
