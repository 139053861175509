/// <reference types="./navbar.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function navbar(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.padding_("40px"),
      $css.width(px(350)),
      $css.background($colors.light.grey_100),
      $css.gap(px(60)),
      $css.flex_direction("column"),
      $css.overflow("auto"),
      $css.z_index(90_005),
      $css.transition("width .6s"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function logo_style() {
  return $css.class$(
    toList([
      $css.display("flex"),
      $css.max_height(px(35)),
      $css.gap(px(14)),
      $css.width(percent(100)),
    ]),
  );
}

export function logo(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(logo_style())]));
  return $h.div(_pipe, attrs, children);
}

export function logo_text(attrs, children) {
  let _pipe = $css.class$(toList([$css.padding_("7px 0"), $css.flex("1")]));
  return $h.div(_pipe, attrs, children);
}

export function navbar_main(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(32)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function nav_links(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(8)),
    ]),
  );
  return $h.nav(_pipe, attrs, children);
}

export function nav_link(color, active, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.lg($text.medium)),
      $css.display("flex"),
      $css.flex_direction("row"),
      $css.align_items("center"),
      $css.gap(px(8)),
      $css.border_radius(px(8)),
      $css.color(color),
      $css.text_decoration("none"),
      $css.padding(px(14)),
      $css.transition("all .3s"),
      $css.background(
        (() => {
          if (active) {
            return $colors.light.grey_200;
          } else {
            return $colors.light.transparent;
          }
        })(),
      ),
      $css.color(
        (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return color;
          }
        })(),
      ),
      $css.hover(
        (() => {
          if (!active) {
            return toList([$css.background($colors.light.white)]);
          } else {
            return toList([]);
          }
        })(),
      ),
    ]),
  );
  return $h.a(_pipe, attrs, children);
}

export function navbar_settings(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(32)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(child) {
  let _pipe = $css.class$(
    toList([$css.width(px(24)), $css.height(px(24)), $css.flex_shrink(0.0)]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}
