/// <reference types="./button.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $html from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $text from "../../ds/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function icon(child) {
  let _pipe = $css.class$(
    toList([$css.width(px(20)), $css.height(px(20)), $css.flex_shrink(0.0)]),
  );
  return $html.div(_pipe, toList([]), toList([child]));
}

export function static$() {
  return $attribute.attribute("data-static", "true");
}

export function color(color) {
  return $attribute.style(toList([["--text-button-color", color]]));
}

export function content(content) {
  let _pipe = $css.class$(toList([$css.z_index(100)]));
  return $html.div(_pipe, toList([]), toList([$html.text(content)]));
}

export function text(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(button_base()),
      $css.background($colors.light.transparent),
      $css.color(("var(--text-button-color, " + $colors.light.onyx) + ")"),
      $css.hover(toList([$css.gap(px(18)), $css.margin_right(px(-6))])),
      $css.selector(
        "[data-static=true]",
        toList([$css.margin_right(px(0)), $css.gap(px(12))]),
      ),
      $css.disabled(
        toList([
          $css.color($colors.light.grey_800),
          $css.cursor("not-allowed"),
          $css.gap(px(6)),
        ]),
      ),
    ]),
  );
  return $html.button(_pipe, attrs, children);
}

function button_base() {
  return $css.class$(
    toList([
      $css.compose($text.md($text.medium)),
      $css.position("relative"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.gap(px(12)),
      $css.cursor("pointer"),
      $css.transition("all .3s"),
      $css.appearance("none"),
      $css.border("none"),
      $css.outline("none"),
      $css.overflow("hidden"),
      $css.font_family("inherit"),
    ]),
  );
}

function button_pill() {
  return $css.class$(
    toList([
      $css.compose(button_base()),
      $css.border_radius(px(8)),
      $css.padding_("10px 18px"),
      $css.text_decoration("none"),
      $css.border("1px solid " + $colors.light.onyx),
    ]),
  );
}

export function button_primary() {
  return $css.class$(
    toList([
      $css.compose(button_pill()),
      $css.background($colors.light.onyx),
      $css.color($colors.light.white),
      $css.hover(toList([$css.background($colors.light.paragraph)])),
      $css.disabled(
        toList([
          $css.background($colors.light.grey_400),
          $css.color($colors.light.grey_800),
          $css.border("1px solid " + $colors.light.grey_400),
          $css.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
}

export function button_secondary() {
  return $css.class$(
    toList([
      $css.compose(button_pill()),
      $css.background($colors.light.white),
      $css.color($colors.light.onyx),
      $css.hover(
        toList([
          $css.background($colors.light.onyx),
          $css.color($colors.light.white),
        ]),
      ),
      $css.disabled(
        toList([
          $css.background($colors.light.white),
          $css.color($colors.light.grey_600),
          $css.border("1px solid " + $colors.light.grey_400),
          $css.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
}

export function primary(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(button_primary())]));
  return $html.button(_pipe, attrs, children);
}

export function primary_link(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(button_primary())]));
  return $html.a(_pipe, attrs, children);
}

export function secondary(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(button_secondary())]));
  return $html.button(_pipe, attrs, children);
}

export function secondary_link(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose(button_secondary())]));
  return $html.a(_pipe, attrs, children);
}

export function link(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(button_base()),
      $css.background($colors.light.transparent),
      $css.color(("var(--text-button-color, " + $colors.light.onyx) + ")"),
      $css.text_decoration("none"),
      $css.hover(toList([$css.gap(px(18)), $css.margin_right(px(-6))])),
      $css.selector(
        "[data-static=true]",
        toList([$css.margin_right(px(0)), $css.gap(px(12))]),
      ),
      $css.disabled(
        toList([
          $css.color($colors.light.grey_800),
          $css.cursor("not-allowed"),
          $css.gap(px(6)),
        ]),
      ),
    ]),
  );
  return $html.a(_pipe, attrs, children);
}

export function ghost(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(button_pill()),
      $css.background($colors.light.transparent),
      $css.color($colors.light.grey_600),
      $css.text_decoration("none"),
      $css.border("none"),
      $css.hover(
        toList([
          $css.background($colors.light.grey_200),
          $css.color($colors.light.onyx),
        ]),
      ),
      $css.disabled(
        toList([
          $css.color($colors.light.grey_800),
          $css.cursor("not-allowed"),
          $css.gap(px(6)),
        ]),
      ),
    ]),
  );
  return $html.a(_pipe, attrs, children);
}
