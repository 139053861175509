/// <reference types="./organization_setup.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function section_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.sm())]));
  return $h.div(_pipe, attrs, children);
}

export function subsection_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}

export function icon_delete(attrs, child) {
  let _pipe = $css.class$(
    toList([$css.width(px(16)), $css.height(px(16)), $css.cursor("pointer")]),
  );
  return $h.button(_pipe, attrs, toList([child]));
}

export function details_tag_deletion(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(6)),
      $css.padding(px(10)),
      $css.background($colors.light.grey_100),
      $css.border_radius(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function input_wrapper(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(px(256))]));
  return $h.div(_pipe, attrs, children);
}
