/// <reference types="./display.d.mts" />
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { rem } from "../../../sketch/sketch/css/length.mjs";
import { toList } from "../../gleam.mjs";

export function xxl() {
  return $css.class$(
    toList([
      $css.font_weight("500"),
      $css.font_size(rem(4.5)),
      $css.line_height("5.625rem"),
      $css.letter_spacing("-0.045rem"),
    ]),
  );
}

export function xl() {
  return $css.class$(
    toList([
      $css.font_weight("500"),
      $css.font_size(rem(3.75)),
      $css.line_height("4.5rem"),
      $css.letter_spacing("-0.0375rem"),
    ]),
  );
}

export function lg() {
  return $css.class$(
    toList([
      $css.font_weight("500"),
      $css.font_size(rem(3.0)),
      $css.line_height("3.75rem"),
      $css.letter_spacing("-0.03rem"),
    ]),
  );
}

export function md() {
  return $css.class$(
    toList([
      $css.font_weight("500"),
      $css.font_size(rem(2.25)),
      $css.line_height("2.75rem"),
      $css.letter_spacing("-0.0225rem"),
    ]),
  );
}

export function sm() {
  return $css.class$(
    toList([
      $css.font_weight("500"),
      $css.font_size(rem(1.875)),
      $css.line_height("2.375rem"),
      $css.letter_spacing("-0.01875rem"),
    ]),
  );
}

export function xs() {
  return $css.class$(
    toList([
      $css.font_weight("500"),
      $css.font_size(rem(1.5)),
      $css.line_height("2rem"),
      $css.letter_spacing("-0.015rem"),
    ]),
  );
}
