/// <reference types="./qualification_matrix.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class QualificationMatrix extends $CustomType {
  constructor(id, title, content, qm_order, org_id) {
    super();
    this.id = id;
    this.title = title;
    this.content = content;
    this.qm_order = qm_order;
    this.org_id = org_id;
  }
}

export class NotSelected extends $CustomType {}

export class Yes extends $CustomType {}

export class No extends $CustomType {}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "title",
        $decode.string,
        (title) => {
          return $decode.field(
            "content",
            $decode.string,
            (content) => {
              return $decode.field(
                "qm_order",
                $decode.int,
                (qm_order) => {
                  return $decode.field(
                    "org_id",
                    $decode.string,
                    (org_id) => {
                      return $decode.success(
                        new QualificationMatrix(
                          id,
                          title,
                          content,
                          qm_order,
                          org_id,
                        ),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function answer_decoder() {
  return $decode.then$(
    $decode.string,
    (content) => {
      if (content === "not-selected") {
        return $decode.success(new NotSelected());
      } else if (content === "yes") {
        return $decode.success(new Yes());
      } else if (content === "no") {
        return $decode.success(new No());
      } else {
        return $decode.failure(new Yes(), "Answer");
      }
    },
  );
}

export function encode(item) {
  return $json.object(
    toList([
      ["id", $json.string(item.id)],
      ["title", $json.string(item.title)],
      ["content", $json.string(item.content)],
      ["qm_order", $json.int(item.qm_order)],
      ["org_id", $json.string(item.org_id)],
    ]),
  );
}

export function encode_answer(answer) {
  if (answer instanceof NotSelected) {
    return $json.string("not-selected");
  } else if (answer instanceof Yes) {
    return $json.string("yes");
  } else {
    return $json.string("no");
  }
}
