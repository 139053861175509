/// <reference types="./css.d.mts" />
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $font_face from "../sketch/css/font_face.mjs";
import * as $keyframe from "../sketch/css/keyframe.mjs";
import * as $length from "../sketch/css/length.mjs";
import * as $media from "../sketch/css/media.mjs";
import * as $transform from "../sketch/css/transform.mjs";
import * as $style from "../sketch/internals/cache/cache.mjs";

export function class$(styles) {
  return $style.class$(styles);
}

export function none() {
  return new $style.NoStyle();
}

export function property(field, content) {
  return new $style.Property(field, content, false);
}

export function accent_color(value) {
  return property("accent-color", value);
}

export function align_content(align) {
  return property("align-content", align);
}

export function align_items(align) {
  return property("align-items", align);
}

export function align_self(align) {
  return property("align-self", align);
}

export function all(value) {
  return property("all", value);
}

export function animation(animation) {
  return property("animation", animation);
}

export function animation_composition(animation) {
  return property("animation-composition", animation);
}

export function animation_delay(animation) {
  return property("animation-delay", animation);
}

export function animation_direction(animation) {
  return property("animation-direction", animation);
}

export function animation_duration(animation) {
  return property("animation-duration", animation);
}

export function animation_fill_mode(animation) {
  return property("animation-fill-mode", animation);
}

export function animation_iteration_count(animation) {
  return property("animation-iteration-count", animation);
}

export function animation_name(animation) {
  return property("animation-name", animation);
}

export function animation_play_state(animation) {
  return property("animation-play-state", animation);
}

export function animation_timing_function(animation) {
  return property("animation-timing-function", animation);
}

export function appearance(appearance) {
  return property("appearance", appearance);
}

export function aspect_ratio(aspect_ratio) {
  return property("aspect-ratio", aspect_ratio);
}

export function backdrop_filter(value) {
  return property("backdrop-filter", value);
}

export function backface_visibility(value) {
  return property("backface-visibility", value);
}

export function background(background) {
  return property("background", background);
}

export function background_attachment(value) {
  return property("background-attachment", value);
}

export function background_blend_mode(value) {
  return property("background-blend-mode", value);
}

export function background_clip(value) {
  return property("background-clip", value);
}

export function background_color(value) {
  return property("background-color", value);
}

export function background_image(value) {
  return property("background-image", value);
}

export function background_origin(value) {
  return property("background-origin", value);
}

export function background_position(value) {
  return property("background-position", value);
}

export function background_position_x(value) {
  return property("background-position-x", value);
}

export function background_position_y(value) {
  return property("background-position-y", value);
}

export function background_repeat(value) {
  return property("background-repeat", value);
}

export function background_size(background) {
  return property("background-size", background);
}

export function block_size(value) {
  return property("block-size", $length.to_string(value));
}

export function block_size_(value) {
  return property("block-size", value);
}

export function border(border) {
  return property("border", border);
}

export function border_block(value) {
  return property("border-block", value);
}

export function border_block_color(value) {
  return property("border-block-color", value);
}

export function border_block_end(value) {
  return property("border-block-end", value);
}

export function border_block_end_color(value) {
  return property("border-block-end-color", value);
}

export function border_block_end_style(value) {
  return property("border-block-end-style", value);
}

export function border_block_end_width(value) {
  return property("border-block-end-width", $length.to_string(value));
}

export function border_block_end_width_(value) {
  return property("border-block-end-width", value);
}

export function border_block_start(value) {
  return property("border-block-start", value);
}

export function border_block_start_color(value) {
  return property("border-block-start-color", value);
}

export function border_block_start_style(value) {
  return property("border-block-start-style", value);
}

export function border_block_start_width(value) {
  return property("border-block-start-width", $length.to_string(value));
}

export function border_block_start_width_(value) {
  return property("border-block-start-width", value);
}

export function border_block_style(value) {
  return property("border-block-style", value);
}

export function border_block_width(value) {
  return property("border-block-width", $length.to_string(value));
}

export function border_block_width_(value) {
  return property("border-block-width", value);
}

export function border_bottom(value) {
  return property("border-bottom", value);
}

export function border_bottom_color(value) {
  return property("border-bottom-color", value);
}

export function border_bottom_left_radius(border_bottom_left_radius) {
  let _pipe = $length.to_string(border_bottom_left_radius);
  return ((_capture) => {
    return property("border-bottom-left-radius", _capture);
  })(_pipe);
}

export function border_bottom_left_radius_(border_bottom_left_radius) {
  return property("border-bottom-left-radius", border_bottom_left_radius);
}

export function border_bottom_right_radius(border_bottom_right_radius) {
  let _pipe = $length.to_string(border_bottom_right_radius);
  return ((_capture) => {
    return property("border-bottom-right-radius", _capture);
  })(_pipe);
}

export function border_bottom_right_radius_(border_bottom_right_radius) {
  return property("border-bottom-right-radius", border_bottom_right_radius);
}

export function border_bottom_style(value) {
  return property("border-bottom-style", value);
}

export function border_bottom_width(value) {
  return property("border-bottom-width", $length.to_string(value));
}

export function border_bottom_width_(value) {
  return property("border-bottom-width", value);
}

export function border_collapse(value) {
  return property("border-collapse", value);
}

export function border_color(value) {
  return property("border-color", value);
}

export function border_end_end_radius(value) {
  return property("border-end-end-radius", value);
}

export function border_end_start_radius(value) {
  return property("border-end-start-radius", value);
}

export function border_image(value) {
  return property("border-image", value);
}

export function border_image_outset(value) {
  return property("border-image-outset", $length.to_string(value));
}

export function border_image_outset_(value) {
  return property("border-image-outset", value);
}

export function border_image_repeat(value) {
  return property("border-image-repeat", value);
}

export function border_image_slice(value) {
  return property("border-image-slice", value);
}

export function border_image_source(value) {
  return property("border-image-source", value);
}

export function border_image_width(value) {
  return property("border-image-width", $length.to_string(value));
}

export function border_image_width_(value) {
  return property("border-image-width", value);
}

export function border_inline(value) {
  return property("border-inline", value);
}

export function border_inline_color(value) {
  return property("border-inline-color", value);
}

export function border_inline_end(value) {
  return property("border-inline-end", value);
}

export function border_inline_end_color(value) {
  return property("border-inline-end-color", value);
}

export function border_inline_end_style(value) {
  return property("border-inline-end-style", value);
}

export function border_inline_end_width(value) {
  return property("border-inline-end-width", $length.to_string(value));
}

export function border_inline_end_width_(value) {
  return property("border-inline-end-width", value);
}

export function border_inline_start(value) {
  return property("border-inline-start", value);
}

export function border_inline_start_color(value) {
  return property("border-inline-start-color", value);
}

export function border_inline_start_style(value) {
  return property("border-inline-start-style", value);
}

export function border_inline_start_width(value) {
  return property("border-inline-start-width", $length.to_string(value));
}

export function border_inline_start_width_(value) {
  return property("border-inline-start-width", value);
}

export function border_inline_style(value) {
  return property("border-inline-style", value);
}

export function border_inline_width(value) {
  return property("border-inline-width", $length.to_string(value));
}

export function border_inline_width_(value) {
  return property("border-inline-width", value);
}

export function border_left(value) {
  return property("border-left", value);
}

export function border_left_color(value) {
  return property("border-left-color", value);
}

export function border_left_style(value) {
  return property("border-left-style", value);
}

export function border_left_width(value) {
  return property("border-left-width", $length.to_string(value));
}

export function border_left_width_(value) {
  return property("border-left-width", value);
}

export function border_radius(border_radius) {
  return property("border-radius", $length.to_string(border_radius));
}

export function border_radius_(border_radius) {
  return property("border-radius", border_radius);
}

export function border_right(value) {
  return property("border-right", value);
}

export function border_right_color(value) {
  return property("border-right-color", value);
}

export function border_right_style(value) {
  return property("border-right-style", value);
}

export function border_right_width(value) {
  return property("border-right-width", $length.to_string(value));
}

export function border_right_width_(value) {
  return property("border-right-width", value);
}

export function border_spacing(value) {
  return property("border-spacing", $length.to_string(value));
}

export function border_spacing_(value) {
  return property("border-spacing", value);
}

export function border_start_end_radius(value) {
  return property("border-start-end-radius", value);
}

export function border_start_start_radius(value) {
  return property("border-start-start-radius", value);
}

export function border_style(value) {
  return property("border-style", value);
}

export function border_top(value) {
  return property("border-top", value);
}

export function border_top_color(value) {
  return property("border-top-color", value);
}

export function border_top_left_radius(border_top_left_radius) {
  let _pipe = $length.to_string(border_top_left_radius);
  return ((_capture) => { return property("border-top-left-radius", _capture); })(
    _pipe,
  );
}

export function border_top_left_radius_(border_top_left_radius) {
  return property("border-top-left-radius", border_top_left_radius);
}

export function border_top_right_radius(border_top_right_radius) {
  let _pipe = $length.to_string(border_top_right_radius);
  return ((_capture) => { return property("border-top-right-radius", _capture); })(
    _pipe,
  );
}

export function border_top_right_radius_(border_top_right_radius) {
  return property("border-top-right-radius", border_top_right_radius);
}

export function border_top_style(value) {
  return property("border-top-style", value);
}

export function border_top_width(value) {
  return property("border-top-width", $length.to_string(value));
}

export function border_top_width_(value) {
  return property("border-top-width", value);
}

export function border_width(value) {
  return property("border-width", $length.to_string(value));
}

export function border_width_(value) {
  return property("border-width", value);
}

export function bottom(size) {
  return property("bottom", $length.to_string(size));
}

export function bottom_(size) {
  return property("bottom", size);
}

export function box_shadow(box_shadow) {
  return property("box-shadow", box_shadow);
}

export function box_sizing(box_sizing) {
  return property("box-sizing", box_sizing);
}

export function break_after(value) {
  return property("break-after", value);
}

export function break_before(value) {
  return property("break-before", value);
}

export function break_inside(value) {
  return property("break-inside", value);
}

export function caption_side(value) {
  return property("caption-side", value);
}

export function caret_color(value) {
  return property("caret-color", value);
}

export function clear(value) {
  return property("clear", value);
}

export function clip_path(value) {
  return property("clip-path", value);
}

export function color(color) {
  return property("color", color);
}

export function color_interpolation(value) {
  return property("color-interpolation", value);
}

export function color_interpolation_filters(value) {
  return property("color-interpolation-filters", value);
}

export function color_scheme(value) {
  return property("color-scheme", value);
}

export function column_count(value) {
  return property("column-count", value);
}

export function column_fill(value) {
  return property("column-fill", value);
}

export function column_gap(column_gap) {
  return property("column-gap", $length.to_string(column_gap));
}

export function column_rule(value) {
  return property("column-rule", value);
}

export function column_rule_color(value) {
  return property("column-rule-color", value);
}

export function column_rule_style(value) {
  return property("column-rule-style", value);
}

export function column_rule_width(value) {
  return property("column-rule-width", value);
}

export function column_span(value) {
  return property("column-span", value);
}

export function column_width(value) {
  return property("column-width", value);
}

export function columns(value) {
  return property("columns", value);
}

export function contain(value) {
  return property("contain", value);
}

export function contain_intrinsic_block_size(value) {
  return property("contain-intrinsic-block-size", $length.to_string(value));
}

export function contain_intrinsic_block_size_(value) {
  return property("contain-intrinsic-block-size", value);
}

export function contain_intrinsic_height(value) {
  return property("contain-intrinsic-height", $length.to_string(value));
}

export function contain_intrinsic_height_(value) {
  return property("contain-intrinsic-height", value);
}

export function contain_intrinsic_inline_size(value) {
  return property("contain-intrinsic-inline-size", $length.to_string(value));
}

export function contain_intrinsic_inline_size_(value) {
  return property("contain-intrinsic-inline-size", value);
}

export function contain_intrinsic_size(value) {
  return property("contain-intrinsic-size", $length.to_string(value));
}

export function contain_intrinsic_size_(value) {
  return property("contain-intrinsic-size", value);
}

export function contain_intrinsic_width(value) {
  return property("contain-intrinsic-width", $length.to_string(value));
}

export function contain_intrinsic_width_(value) {
  return property("contain-intrinsic-width", value);
}

export function container(value) {
  return property("container", value);
}

export function container_name(value) {
  return property("container-name", value);
}

export function container_type(value) {
  return property("container-type", value);
}

export function content(value) {
  return property("content", value);
}

export function content_visibility(value) {
  return property("content-visibility", value);
}

export function counter_increment(value) {
  return property("counter-increment", value);
}

export function counter_reset(value) {
  return property("counter-reset", value);
}

export function counter_set(value) {
  return property("counter-set", value);
}

export function cursor(cursor) {
  return property("cursor", cursor);
}

export function direction(direction) {
  return property("direction", direction);
}

export function display(display) {
  return property("display", display);
}

export function empty_cells(empty_cells) {
  return property("empty-cells", empty_cells);
}

export function field_sizing(field_sizing) {
  return property("field-sizing", field_sizing);
}

export function filter(filter) {
  return property("filter", filter);
}

export function flex(flex) {
  return property("flex", flex);
}

export function flex_basis(flex_basis) {
  return property("flex-basis", flex_basis);
}

export function flex_direction(flex_direction) {
  return property("flex-direction", flex_direction);
}

export function flex_flow(flex_flow) {
  return property("flex-flow", flex_flow);
}

export function flex_grow(flex_grow) {
  return property("flex-grow", $int.to_string(flex_grow));
}

export function flex_grow_(flex_grow) {
  return property("flex-grow", flex_grow);
}

export function flex_shrink_(flex_shrink) {
  return property("flex-shrink", flex_shrink);
}

export function flex_wrap(flex_wrap) {
  return property("flex-wrap", flex_wrap);
}

export function float(float) {
  return property("float", float);
}

export function flex_shrink(flex_shrink) {
  return property("flex-shrink", $float.to_string(flex_shrink));
}

export function font(value) {
  return property("font", value);
}

export function font_family(font_family) {
  return property("font-family", font_family);
}

export function font_feature_settings(value) {
  return property("font-feature-settings", value);
}

export function font_kerning(value) {
  return property("font-kerning", value);
}

export function font_language_override(value) {
  return property("font-language-override", value);
}

export function font_optical_sizing(value) {
  return property("font-optical-sizing", value);
}

export function font_palette(value) {
  return property("font-palette", value);
}

export function font_size(font_size) {
  return property("font-size", $length.to_string(font_size));
}

export function font_size_(font_size) {
  return property("font-size", font_size);
}

export function font_size_adjust(value) {
  return property("font-size-adjust", value);
}

export function font_stretch(value) {
  return property("font-stretch", value);
}

export function font_style(font_style) {
  return property("font-style", font_style);
}

export function font_synthesis(value) {
  return property("font-synthesis", value);
}

export function font_synthesis_small_caps(value) {
  return property("font-synthesis-small-caps", value);
}

export function font_synthesis_style(value) {
  return property("font-synthesis-style", value);
}

export function font_synthesis_weight(value) {
  return property("font-synthesis-weight", value);
}

export function font_variant(value) {
  return property("font-variant", value);
}

export function font_variant_alternates(value) {
  return property("font-variant-alternates", value);
}

export function font_variant_caps(value) {
  return property("font-variant-caps", value);
}

export function font_variant_east_asian(value) {
  return property("font-variant-east-asian", value);
}

export function font_variant_emoji(value) {
  return property("font-variant-emoji", value);
}

export function font_variant_ligatures(value) {
  return property("font-variant-ligatures", value);
}

export function font_variant_numeric(value) {
  return property("font-variant-numeric", value);
}

export function font_variant_position(value) {
  return property("font-variant-position", value);
}

export function font_variation_settings(value) {
  return property("font-variation-settings", value);
}

export function font_weight(font_weight) {
  return property("font-weight", font_weight);
}

export function forced_color_adjust(value) {
  return property("forced-color-adjust", value);
}

export function gap(gap) {
  return property("gap", $length.to_string(gap));
}

export function gap_(gap) {
  return property("gap", gap);
}

export function grid(value) {
  return property("grid", value);
}

export function grid_area(grid_area) {
  return property("grid-area", grid_area);
}

export function grid_auto_columns(grid_auto_columns) {
  return property("grid-auto-columns", grid_auto_columns);
}

export function grid_auto_flow(grid_auto_flow) {
  return property("grid-auto-flow", grid_auto_flow);
}

export function grid_auto_rows(grid_auto_rows) {
  return property("grid-auto-rows", grid_auto_rows);
}

export function grid_column(grid_column) {
  return property("grid-column", grid_column);
}

export function grid_column_end(grid_column) {
  return property("grid-column-end", grid_column);
}

export function grid_column_start(grid_column) {
  return property("grid-column-start", grid_column);
}

export function grid_row(grid_row) {
  return property("grid-row", grid_row);
}

export function grid_row_end(grid_row) {
  return property("grid-row-end", grid_row);
}

export function grid_row_start(grid_row) {
  return property("grid-row-start", grid_row);
}

export function grid_template(grid_template) {
  return property("grid-template", grid_template);
}

export function grid_template_areas(grid_template_areas) {
  let _pipe = grid_template_areas;
  let _pipe$1 = $list.map(
    _pipe,
    (content) => { return ("\"" + content) + "\""; },
  );
  let _pipe$2 = $string.join(_pipe$1, "\n");
  return ((_capture) => { return property("grid-template-areas", _capture); })(
    _pipe$2,
  );
}

export function grid_template_columns(grid_template_columns) {
  return property("grid-template-columns", grid_template_columns);
}

export function grid_template_rows(grid_template_rows) {
  return property("grid-template-rows", grid_template_rows);
}

export function hanging_punctuation(value) {
  return property("hanging-punctuation", value);
}

export function height(height) {
  return property("height", $length.to_string(height));
}

export function height_(height) {
  return property("height", height);
}

export function hyphenate_character(value) {
  return property("hyphenate-character", value);
}

export function hyphenate_limit_chars(value) {
  return property("hyphenate-limit-chars", value);
}

export function hyphens(value) {
  return property("hyphens", value);
}

export function image_orientation(value) {
  return property("image-orientation", value);
}

export function image_rendering(value) {
  return property("image-rendering", value);
}

export function initial_letter(value) {
  return property("initial-letter", value);
}

export function inline_size(value) {
  return property("inline-size", value);
}

export function inset(value) {
  return property("inset", value);
}

export function inset_block(value) {
  return property("inset-block", value);
}

export function inset_block_end(value) {
  return property("inset-block-end", value);
}

export function inset_block_start(value) {
  return property("inset-block-start", value);
}

export function inset_inline(value) {
  return property("inset-inline", value);
}

export function inset_inline_end(value) {
  return property("inset-inline-end", value);
}

export function inset_inline_start(value) {
  return property("inset-inline-start", value);
}

export function isolation(value) {
  return property("isolation", value);
}

export function justify_content(justify) {
  return property("justify-content", justify);
}

export function justify_items(justify) {
  return property("justify-items", justify);
}

export function justify_self(justify) {
  return property("justify-self", justify);
}

export function left(size) {
  return property("left", $length.to_string(size));
}

export function left_(size) {
  return property("left", size);
}

export function letter_spacing(letter_spacing) {
  return property("letter-spacing", letter_spacing);
}

export function line_break(line_break) {
  return property("line-break", line_break);
}

export function line_height(line_height) {
  return property("line-height", line_height);
}

export function list_style(list_style) {
  return property("list-style", list_style);
}

export function list_style_image(list_style_image) {
  return property("list-style-image", list_style_image);
}

export function list_style_position(list_style_position) {
  return property("list-style-position", list_style_position);
}

export function list_style_type(list_style_type) {
  return property("list-style-type", list_style_type);
}

export function margin(margin) {
  return property("margin", $length.to_string(margin));
}

export function margin_(margin) {
  return property("margin", margin);
}

export function margin_block(margin) {
  return property("margin-block", $length.to_string(margin));
}

export function margin_block_(margin) {
  return property("margin-block", margin);
}

export function margin_block_end(margin) {
  return property("margin-block-end", $length.to_string(margin));
}

export function margin_block_end_(margin) {
  return property("margin-block-end", margin);
}

export function margin_block_start(margin) {
  return property("margin-block-start", $length.to_string(margin));
}

export function margin_block_start_(margin) {
  return property("margin-block-start", margin);
}

export function margin_bottom(margin) {
  return property("margin-bottom", $length.to_string(margin));
}

export function margin_bottom_(margin) {
  return property("margin-bottom", margin);
}

export function margin_inline(margin) {
  return property("margin-inline", $length.to_string(margin));
}

export function margin_inline_(margin) {
  return property("margin-inline", margin);
}

export function margin_inline_end(margin) {
  return property("margin-inline-end", $length.to_string(margin));
}

export function margin_inline_end_(margin) {
  return property("margin-inline-end", margin);
}

export function margin_inline_start(margin) {
  return property("margin-inline-start", $length.to_string(margin));
}

export function margin_inline_start_(margin) {
  return property("margin-inline-start", margin);
}

export function margin_left(margin) {
  return property("margin-left", $length.to_string(margin));
}

export function margin_left_(margin) {
  return property("margin-left", margin);
}

export function margin_right(margin) {
  return property("margin-right", $length.to_string(margin));
}

export function margin_right_(margin) {
  return property("margin-right", margin);
}

export function margin_top(margin) {
  return property("margin-top", $length.to_string(margin));
}

export function margin_top_(margin) {
  return property("margin-top", margin);
}

export function mask(value) {
  return property("mask", value);
}

export function mask_border(value) {
  return property("mask-border", value);
}

export function mask_border_mode(value) {
  return property("mask-border-mode", value);
}

export function mask_border_outset(value) {
  return property("mask-border-outset", value);
}

export function mask_border_repeat(value) {
  return property("mask-border-repeat", value);
}

export function mask_border_slice(value) {
  return property("mask-border-slice", value);
}

export function mask_border_source(value) {
  return property("mask-border-source", value);
}

export function mask_border_width(value) {
  return property("mask-border-width", $length.to_string(value));
}

export function mask_border_width_(value) {
  return property("mask-border-width", value);
}

export function mask_clip(value) {
  return property("mask-clip", value);
}

export function mask_composite(value) {
  return property("mask-composite", value);
}

export function mask_image(value) {
  return property("mask-image", value);
}

export function mask_mode(value) {
  return property("mask-mode", value);
}

export function mask_origin(value) {
  return property("mask-origin", value);
}

export function mask_position(value) {
  return property("mask-position", value);
}

export function mask_repeat(value) {
  return property("mask-repeat", value);
}

export function mask_size(value) {
  return property("mask-size", value);
}

export function mask_type(value) {
  return property("mask-type", value);
}

export function math_depth(value) {
  return property("math-depth", value);
}

export function math_style(value) {
  return property("math-style", value);
}

export function max_block_size(value) {
  return property("max-block-size", $length.to_string(value));
}

export function max_block_size_(value) {
  return property("max-block-size", value);
}

export function max_height(height) {
  return property("max-height", $length.to_string(height));
}

export function max_height_(height) {
  return property("max-height", height);
}

export function max_inline_size(value) {
  return property("max-inline-size", $length.to_string(value));
}

export function max_inline_size_(value) {
  return property("max-inline-size", value);
}

export function max_width(width) {
  return property("max-width", $length.to_string(width));
}

export function max_width_(width) {
  return property("max-width", width);
}

export function min_block_size(value) {
  return property("min-block-size", $length.to_string(value));
}

export function min_block_size_(value) {
  return property("min-block-size", value);
}

export function min_height(height) {
  return property("min-height", $length.to_string(height));
}

export function min_height_(height) {
  return property("min-height", height);
}

export function min_inline_size(value) {
  return property("min-inline-size", $length.to_string(value));
}

export function min_inline_size_(value) {
  return property("min-inline-size", value);
}

export function min_width(width) {
  return property("min-width", $length.to_string(width));
}

export function min_width_(width) {
  return property("min-width", width);
}

export function mix_blend_mode(value) {
  return property("mix-blend-mode", value);
}

export function object_fit(object_fit) {
  return property("object-fit", object_fit);
}

export function object_position(object_position) {
  return property("object-position", object_position);
}

export function offset(offset) {
  return property("offset", offset);
}

export function offset_anchor(offset_anchor) {
  return property("offset-anchor", offset_anchor);
}

export function offset_distance(offset_distance) {
  return property("offset-distance", offset_distance);
}

export function offset_path(offset_path) {
  return property("offset-path", offset_path);
}

export function offset_position(offset_position) {
  return property("offset-position", offset_position);
}

export function offset_rotate(offset_rotate) {
  return property("offset-rotate", offset_rotate);
}

export function opacity(opacity) {
  return property("opacity", $float.to_string(opacity));
}

export function order(value) {
  return property("order", $int.to_string(value));
}

export function order_(value) {
  return property("order", value);
}

export function orphans(value) {
  return property("orphans", $int.to_string(value));
}

export function orphans_(value) {
  return property("orphans", value);
}

export function outline(outline) {
  return property("outline", outline);
}

export function outline_color(outline_color) {
  return property("outline-color", outline_color);
}

export function outline_offset(outline_offset) {
  return property("outline-offset", outline_offset);
}

export function outline_style(outline_style) {
  return property("outline-style", outline_style);
}

export function outline_width(outline_width) {
  return property("outline-width", outline_width);
}

export function overflow(overflow) {
  return property("overflow", overflow);
}

export function overflow_anchor(overflow_anchor) {
  return property("overflow-anchor", overflow_anchor);
}

export function overflow_block(overflow_block) {
  return property("overflow-block", overflow_block);
}

export function overflow_clip_margin(overflow_clip_margin) {
  return property("overflow-clip-margin", overflow_clip_margin);
}

export function overflow_inline(overflow_inline) {
  return property("overflow-inline", overflow_inline);
}

export function overflow_wrap(overflow_wrap) {
  return property("overflow-wrap", overflow_wrap);
}

export function overflow_x(overflow_x) {
  return property("overflow-x", overflow_x);
}

export function overflow_y(overflow_y) {
  return property("overflow-y", overflow_y);
}

export function overscroll_behavior(value) {
  return property("overscroll-behavior", value);
}

export function overscroll_behavior_block(value) {
  return property("overscroll-behavior-block", value);
}

export function overscroll_behavior_inline(value) {
  return property("overscroll-behavior-inline", value);
}

export function overscroll_behavior_x(value) {
  return property("overscroll-behavior-x", value);
}

export function overscroll_behavior_y(value) {
  return property("overscroll-behavior-y", value);
}

export function padding(padding) {
  return property("padding", $length.to_string(padding));
}

export function padding_(padding) {
  return property("padding", padding);
}

export function padding_block(padding) {
  return property("padding-block", $length.to_string(padding));
}

export function padding_block_(padding) {
  return property("padding-block", padding);
}

export function padding_block_end(padding) {
  return property("padding-block-end", $length.to_string(padding));
}

export function padding_block_end_(padding) {
  return property("padding-block-end", padding);
}

export function padding_block_start(padding) {
  return property("padding-block-start", $length.to_string(padding));
}

export function padding_block_start_(padding) {
  return property("padding-block-start", padding);
}

export function padding_bottom(padding) {
  return property("padding-bottom", $length.to_string(padding));
}

export function padding_bottom_(padding) {
  return property("padding-bottom", padding);
}

export function padding_inline(padding) {
  return property("padding-inline", $length.to_string(padding));
}

export function padding_inline_(padding) {
  return property("padding-inline", padding);
}

export function padding_inline_end(padding) {
  return property("padding-inline-end", $length.to_string(padding));
}

export function padding_inline_end_(padding) {
  return property("padding-inline-end", padding);
}

export function padding_inline_start(padding) {
  return property("padding-inline-start", $length.to_string(padding));
}

export function padding_inline_start_(padding) {
  return property("padding-inline-start", padding);
}

export function padding_left(padding) {
  return property("padding-left", $length.to_string(padding));
}

export function padding_left_(padding) {
  return property("padding-left", padding);
}

export function padding_right(padding) {
  return property("padding-right", $length.to_string(padding));
}

export function padding_right_(padding) {
  return property("padding-right", padding);
}

export function padding_top(padding) {
  return property("padding-top", $length.to_string(padding));
}

export function padding_top_(padding) {
  return property("padding-top", padding);
}

export function page(value) {
  return property("page", value);
}

export function paint_order(value) {
  return property("paint-order", value);
}

export function perspective(value) {
  return property("perspective", value);
}

export function perspective_origin(value) {
  return property("perspective-origin", value);
}

export function place_content(place) {
  return property("place-content", place);
}

export function place_items(place) {
  return property("place-items", place);
}

export function place_self(place) {
  return property("place-self", place);
}

export function pointer_events(pointer_events) {
  return property("pointer-events", pointer_events);
}

export function position(position) {
  return property("position", position);
}

export function print_color_adjust(print) {
  return property("print-color-adjust", print);
}

export function quotes(quotes) {
  return property("quotes", quotes);
}

export function resize(value) {
  return property("resize", value);
}

export function right(size) {
  return property("right", $length.to_string(size));
}

export function right_(size) {
  return property("right", size);
}

export function rotate(value) {
  return property("rotate", value);
}

export function row_gap(row_gap) {
  return property("row-gap", $length.to_string(row_gap));
}

export function ruby_align(value) {
  return property("ruby-align", value);
}

export function ruby_position(value) {
  return property("ruby-position", value);
}

export function scale(value) {
  return property("scale", value);
}

export function scroll_behavior(value) {
  return property("scroll-behavior", value);
}

export function scroll_margin(value) {
  return property("scroll-margin", $length.to_string(value));
}

export function scroll_margin_(value) {
  return property("scroll-margin", value);
}

export function scroll_margin_block(value) {
  return property("scroll-margin-block", $length.to_string(value));
}

export function scroll_margin_block_(value) {
  return property("scroll-margin-block", value);
}

export function scroll_margin_block_end(value) {
  return property("scroll-margin-block-end", $length.to_string(value));
}

export function scroll_margin_block_end_(value) {
  return property("scroll-margin-block-end", value);
}

export function scroll_margin_block_start(value) {
  return property("scroll-margin-block-start", $length.to_string(value));
}

export function scroll_margin_block_start_(value) {
  return property("scroll-margin-block-start", value);
}

export function scroll_margin_bottom(value) {
  return property("scroll-margin-bottom", $length.to_string(value));
}

export function scroll_margin_bottom_(value) {
  return property("scroll-margin-bottom", value);
}

export function scroll_margin_inline(value) {
  return property("scroll-margin-inline", $length.to_string(value));
}

export function scroll_margin_inline_(value) {
  return property("scroll-margin-inline", value);
}

export function scroll_margin_inline_end(value) {
  return property("scroll-margin-inline-end", $length.to_string(value));
}

export function scroll_margin_inline_end_(value) {
  return property("scroll-margin-inline-end", value);
}

export function scroll_margin_inline_start(value) {
  return property("scroll-margin-inline-start", $length.to_string(value));
}

export function scroll_margin_inline_start_(value) {
  return property("scroll-margin-inline-start", value);
}

export function scroll_margin_left(value) {
  return property("scroll-margin-left", $length.to_string(value));
}

export function scroll_margin_left_(value) {
  return property("scroll-margin-left", value);
}

export function scroll_margin_right(value) {
  return property("scroll-margin-right", $length.to_string(value));
}

export function scroll_margin_right_(value) {
  return property("scroll-margin-right", value);
}

export function scroll_margin_top(value) {
  return property("scroll-margin-top", $length.to_string(value));
}

export function scroll_margin_top_(value) {
  return property("scroll-margin-top", value);
}

export function scroll_padding(value) {
  return property("scroll-padding", $length.to_string(value));
}

export function scroll_padding_(value) {
  return property("scroll-padding", value);
}

export function scroll_padding_block(value) {
  return property("scroll-padding-block", $length.to_string(value));
}

export function scroll_padding_block_(value) {
  return property("scroll-padding-block", value);
}

export function scroll_padding_block_end(value) {
  return property("scroll-padding-block-end", $length.to_string(value));
}

export function scroll_padding_block_end_(value) {
  return property("scroll-padding-block-end", value);
}

export function scroll_padding_block_start(value) {
  return property("scroll-padding-block-start", $length.to_string(value));
}

export function scroll_padding_block_start_(value) {
  return property("scroll-padding-block-start", value);
}

export function scroll_padding_bottom(value) {
  return property("scroll-padding-bottom", $length.to_string(value));
}

export function scroll_padding_bottom_(value) {
  return property("scroll-padding-bottom", value);
}

export function scroll_padding_inline(value) {
  return property("scroll-padding-inline", $length.to_string(value));
}

export function scroll_padding_inline_(value) {
  return property("scroll-padding-inline", value);
}

export function scroll_padding_inline_end(value) {
  return property("scroll-padding-inline-end", $length.to_string(value));
}

export function scroll_padding_inline_end_(value) {
  return property("scroll-padding-inline-end", value);
}

export function scroll_padding_inline_start(value) {
  return property("scroll-padding-inline-start", $length.to_string(value));
}

export function scroll_padding_inline_start_(value) {
  return property("scroll-padding-inline-start", value);
}

export function scroll_padding_left(value) {
  return property("scroll-padding-left", $length.to_string(value));
}

export function scroll_padding_left_(value) {
  return property("scroll-padding-left", value);
}

export function scroll_padding_right(value) {
  return property("scroll-padding-right", $length.to_string(value));
}

export function scroll_padding_right_(value) {
  return property("scroll-padding-right", value);
}

export function scroll_padding_top(value) {
  return property("scroll-padding-top", $length.to_string(value));
}

export function scroll_padding_top_(value) {
  return property("scroll-padding-top", value);
}

export function scroll_snap_align(value) {
  return property("scroll-snap-align", value);
}

export function scroll_snap_stop(value) {
  return property("scroll-snap-stop", value);
}

export function scroll_snap_type(value) {
  return property("scroll-snap-type", value);
}

export function scrollbar_color(value) {
  return property("scrollbar-color", value);
}

export function scrollbar_gutter(value) {
  return property("scrollbar-gutter", value);
}

export function scrollbar_width(value) {
  return property("scrollbar-width", value);
}

export function shape_image_threshold(value) {
  return property("shape-image-threshold", $float.to_string(value));
}

export function shape_image_threshold_(value) {
  return property("shape-image-threshold", value);
}

export function shape_margin(value) {
  return property("shape-margin", $length.to_string(value));
}

export function shape_margin_(value) {
  return property("shape-margin", value);
}

export function shape_outside(value) {
  return property("shape-outside", value);
}

export function tab_size(size) {
  return property("tab-size", $length.to_string(size));
}

export function tab_size_(size) {
  return property("tab-size", size);
}

export function table_layout(layout) {
  return property("table-layout", layout);
}

export function text_align(text_align) {
  return property("text-align", text_align);
}

export function text_align_last(value) {
  return property("text-align-last", value);
}

export function text_combine_upright(value) {
  return property("text-combine-upright", value);
}

export function text_decoration(text_decoration) {
  return property("text-decoration", text_decoration);
}

export function text_decoration_color(value) {
  return property("text-decoration-color", value);
}

export function text_decoration_line(value) {
  return property("text-decoration-line", value);
}

export function text_decoration_skip_ink(value) {
  return property("text-decoration-skip-ink", value);
}

export function text_decoration_style(value) {
  return property("text-decoration-style", value);
}

export function text_decoration_thickness(value) {
  return property("text-decoration-thickness", value);
}

export function text_emphasis(value) {
  return property("text-emphasis", value);
}

export function text_emphasis_color(value) {
  return property("text-emphasis-color", value);
}

export function text_emphasis_position(value) {
  return property("text-emphasis-position", value);
}

export function text_emphasis_style(value) {
  return property("text-emphasis-style", value);
}

export function text_indent(value) {
  return property("text-indent", value);
}

export function text_justify(text_justify) {
  return property("text-justify", text_justify);
}

export function text_orientation(value) {
  return property("text-orientation", value);
}

export function text_overflow(text_overflow) {
  return property("text-overflow", text_overflow);
}

export function text_rendering(value) {
  return property("text-rendering", value);
}

export function text_shadow(value) {
  return property("text-shadow", value);
}

export function text_transform(text_transform) {
  return property("text-transform", text_transform);
}

export function text_underline_offset(value) {
  return property("text-underline-offset", $length.to_string(value));
}

export function text_underline_offset_(value) {
  return property("text-underline-offset", value);
}

export function text_underline_position(value) {
  return property("text-underline-position", value);
}

export function text_wrap(value) {
  return property("text-wrap", value);
}

export function text_wrap_mode(value) {
  return property("text-wrap-mode", value);
}

export function text_wrap_style(value) {
  return property("text-wrap-style", value);
}

export function top(size) {
  return property("top", $length.to_string(size));
}

export function top_(size) {
  return property("top", size);
}

export function touch_action(value) {
  return property("touch-action", value);
}

export function transform(transform) {
  let transform$1 = $transform.to_string(transform);
  return property("transform", transform$1);
}

export function transform_(transform) {
  return property("transform", transform);
}

export function transform_box(transform_box) {
  return property("transform-box", transform_box);
}

export function transform_origin(transform_origin) {
  return property("transform-origin", transform_origin);
}

export function transform_style(transform_style) {
  return property("transform-style", transform_style);
}

export function transition(transition) {
  return property("transition", transition);
}

export function transition_behavior(value) {
  return property("transition-behavior", value);
}

export function transition_delay(value) {
  return property("transition-delay", value);
}

export function transition_duration(value) {
  return property("transition-duration", value);
}

export function transition_property(value) {
  return property("transition-property", value);
}

export function transition_timing_function(value) {
  return property("transition-timing-function", value);
}

export function translate(translate) {
  return property("translate", translate);
}

export function unicode_bidi(value) {
  return property("unicode-bidi", value);
}

export function user_select(user_select) {
  return property("user-select", user_select);
}

export function vector_effect(value) {
  return property("vector-effect", value);
}

export function vertical_align(value) {
  return property("vertical-align", value);
}

export function visibility(visibility) {
  return property("visibility", visibility);
}

export function white_space(white_space) {
  return property("white-space", white_space);
}

export function white_space_collapse(white_space_collapse) {
  return property("white-space-collapse", white_space_collapse);
}

export function widows(value) {
  return property("widows", value);
}

export function width(width) {
  return property("width", $length.to_string(width));
}

export function width_(width) {
  return property("width", width);
}

export function will_change(value) {
  return property("will-change", value);
}

export function word_break(word_break) {
  return property("word-break", word_break);
}

export function word_spacing(word_spacing) {
  return property("word-spacing", word_spacing);
}

export function writing_mode(value) {
  return property("writing-mode", value);
}

export function z_index(z_index) {
  return property("z-index", $int.to_string(z_index));
}

export function zoom(value) {
  return property("zoom", value);
}

export function media(query, styles) {
  let media_selector = $media.to_string(query);
  return new $style.Media(media_selector, styles);
}

export function selector(value, styles) {
  return new $style.Selector(value, styles);
}

export function placeholder(styles) {
  return selector("::placeholder", styles);
}

export function selection(styles) {
  return selector("::selection", styles);
}

export function before(styles) {
  return selector("::before", styles);
}

export function after(styles) {
  return selector("::after", styles);
}

export function backdrop(styles) {
  return selector("::backdrop", styles);
}

export function cue(styles) {
  return selector("::cue", styles);
}

export function first_line(styles) {
  return selector("::first-line", styles);
}

export function grammar_error(styles) {
  return selector("::grammar-error", styles);
}

export function spelling_error(styles) {
  return selector("::spelling-error", styles);
}

export function marker(styles) {
  return selector("::marker", styles);
}

export function first_letter(styles) {
  return selector("::first-letter", styles);
}

export function file_selector_button(styles) {
  return selector("::file-selector-button", styles);
}

export function hover(styles) {
  return selector(":hover", styles);
}

export function any_link(styles) {
  return selector(":any-link", styles);
}

export function active(styles) {
  return selector(":active", styles);
}

export function focus(styles) {
  return selector(":focus", styles);
}

export function autofill(styles) {
  return selector(":autofill", styles);
}

export function buffering(styles) {
  return selector(":buffering", styles);
}

export function default$(styles) {
  return selector(":default", styles);
}

export function defined(styles) {
  return selector(":defined", styles);
}

export function empty(styles) {
  return selector(":empty", styles);
}

export function fullscreen(styles) {
  return selector(":fullscreen", styles);
}

export function in_range(styles) {
  return selector(":in-range", styles);
}

export function indeterminate(styles) {
  return selector(":indeterminate", styles);
}

export function muted(styles) {
  return selector(":muted", styles);
}

export function paused(styles) {
  return selector(":paused", styles);
}

export function playing(styles) {
  return selector(":playing", styles);
}

export function seeking(styles) {
  return selector(":seeking", styles);
}

export function stalled(styles) {
  return selector(":stalled", styles);
}

export function state(content, styles) {
  return selector((":state(" + content) + ")", styles);
}

export function user_invalid(styles) {
  return selector(":user-invalid", styles);
}

export function user_valid(styles) {
  return selector(":user-valid", styles);
}

export function volume_locked(styles) {
  return selector(":volume-locked", styles);
}

export function placeholder_shown(styles) {
  return selector(":placeholder-shown", styles);
}

export function out_of_range(styles) {
  return selector(":out-of-range", styles);
}

export function dir(direction, styles) {
  return selector((":dir(" + direction) + ")", styles);
}

export function focus_visible(styles) {
  return selector(":focus-visible", styles);
}

export function focus_within(styles) {
  return selector(":focus-within", styles);
}

export function enabled(styles) {
  return selector(":enabled", styles);
}

export function disabled(styles) {
  return selector(":disabled", styles);
}

export function read_only(styles) {
  return selector(":read-only", styles);
}

export function read_write(styles) {
  return selector(":read-write", styles);
}

export function checked(styles) {
  return selector(":checked", styles);
}

export function valid(styles) {
  return selector(":valid", styles);
}

export function invalid(styles) {
  return selector(":invalid", styles);
}

export function required(styles) {
  return selector(":required", styles);
}

export function optional(styles) {
  return selector(":optional", styles);
}

export function link(styles) {
  return selector(":link", styles);
}

export function visited(styles) {
  return selector(":visited", styles);
}

export function target(styles) {
  return selector(":target", styles);
}

export function nth_child(sel, styles) {
  return selector((":nth-child(" + sel) + ")", styles);
}

export function nth_last_child(sel, styles) {
  return selector((":nth-last-child(" + sel) + ")", styles);
}

export function nth_of_type(sel, styles) {
  return selector((":nth-of-type(" + sel) + ")", styles);
}

export function nth_last_of_type(sel, styles) {
  return selector((":nth-last-of-type(" + sel) + ")", styles);
}

export function first_child(styles) {
  return selector(":first-child", styles);
}

export function last_child(styles) {
  return selector(":last-child", styles);
}

export function only_child(styles) {
  return selector(":only-child", styles);
}

export function first_of_type(styles) {
  return selector(":first-of-type", styles);
}

export function last_of_type(styles) {
  return selector(":last-of-type", styles);
}

export function only_of_type(styles) {
  return selector(":only-of-type", styles);
}

export function child(class$, styles) {
  return new $style.Combinator(" > ", class$, styles);
}

export function descendant(class$, styles) {
  return new $style.Combinator(" ", class$, styles);
}

export function next_sibling(class$, styles) {
  return new $style.Combinator(" + ", class$, styles);
}

export function subsequent_sibling(class$, styles) {
  return new $style.Combinator(" ~ ", class$, styles);
}

export function important(style) {
  if (style instanceof $style.Property) {
    let key = style.key;
    let value = style.value;
    return new $style.Property(key, value, true);
  } else {
    let any = style;
    return any;
  }
}

export function compose(class$) {
  return new $style.ClassName(class$);
}

export function keyframes(name, frames) {
  return $style.classes_rule(
    "keyframes " + name,
    $list.map(frames, (keyframe) => { return $keyframe.class$(keyframe); }),
  );
}

export function font_face(font_faces) {
  let _pipe = font_faces;
  let _pipe$1 = $list.map(
    _pipe,
    (s) => {
      let _pipe$1 = $font_face.to_string(s);
      let _pipe$2 = ((_capture) => { return $string.append("  ", _capture); })(
        _pipe$1,
      );
      return $string.append(_pipe$2, ";");
    },
  );
  let _pipe$2 = $string.join(_pipe$1, "\n");
  let _pipe$3 = ((_capture) => { return $string.append("\n", _capture); })(
    _pipe$2,
  );
  let _pipe$4 = $string.append(_pipe$3, "\n");
  return ((_capture) => { return $style.content_rule("font-face", _capture); })(
    _pipe$4,
  );
}
