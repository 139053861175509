/// <reference types="./content_library.d.mts" />
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $content_library_effects from "../admin/effects/content_library.mjs";
import * as $ingestion_control from "../admin/effects/ingestion_control.mjs";
import * as $middleware from "../admin/middleware.mjs";
import { require_not_modifying_question } from "../admin/middleware.mjs";
import * as $content_library from "../data/content_library.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import * as $loading from "../data/ui/loading.mjs";
import { toList } from "../gleam.mjs";

export function update(data, msg) {
  if (msg instanceof $msg.ApiFetchedDataPoints) {
    let source_id = msg.source_id;
    let data_points = msg.data_points;
    let _pipe = $dict.insert(
      data.ingestion_control_sources,
      source_id,
      data_points,
    );
    let _pipe$1 = ((_capture) => {
      return $data.set_ingestion_control_sources(data, _capture);
    })(_pipe);
    return $pair.new$(_pipe$1, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedSource) {
    let source = msg.source;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.upsert_data_source(_pipe, source);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.ApiReturnedSources) {
    let sources = msg.sources;
    return require_not_modifying_question(
      data,
      () => {
        let _pipe = data.content_library;
        let _pipe$1 = $content_library.set_data_sources(_pipe, sources);
        let _pipe$2 = ((_capture) => {
          return $data.set_content_library(data, _capture);
        })(_pipe$1);
        let _pipe$3 = $data.mark_as_loaded(_pipe$2, $loading.content_library);
        return $pair.new$(_pipe$3, $effect.none());
      },
    );
  } else if (msg instanceof $msg.SwitchedVisbility) {
    let source_id = msg.source_id;
    let _pipe = $data.toggle_visibility(data, source_id);
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof $msg.UserAssignedStatus) {
    let status = msg.status;
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.attribute_status(_pipe, status);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(
      _pipe$2,
      $content_library_effects.update_data_sources_status(data, status),
    );
  } else if (msg instanceof $msg.UserClickedGlobalCheckbox) {
    let all_selected = msg.all_selected;
    let sources = msg.sources;
    let selected = $bool.guard(
      all_selected,
      $dict.new$(),
      () => {
        return $list.fold(
          sources,
          $dict.new$(),
          (selected, source) => {
            return $dict.insert(selected, source.id, true);
          },
        );
      },
    );
    let _pipe = (() => {
      let _record = data.content_library;
      return new $content_library.ContentLibrary(
        _record.non_qna_sources,
        selected,
        _record.change_owner_popup_opened,
        _record.change_status_popup_opened,
        _record.change_tags_popup_opened,
        _record.change_expiration_date_popup_opened,
        _record.change_expiration_date_input,
        _record.filter_selected,
        _record.search,
        _record.new_qna,
        _record.ingestion_loading,
      );
    })();
    let _pipe$1 = $content_library.close_popups(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return $pair.new$(_pipe$2, $effect.none());
  } else if (msg instanceof $msg.UserClickedOnFetchDataPoints) {
    let source_id = msg.source_id;
    let _pipe = $ingestion_control.fetch_data_points(data, source_id);
    return ((_capture) => { return $pair.new$(data, _capture); })(_pipe);
  } else if (msg instanceof $msg.UserClickedOnSourceInspect) {
    let source_id = msg.source_id;
    let _pipe = data;
    return $pair.new$(
      _pipe,
      $effect.batch(
        toList([
          $route.push(
            new $route.ContentLibrary(new $route.IngestionControl(source_id)),
          ),
          $ingestion_control.fetch_data_points(data, source_id),
        ]),
      ),
    );
  } else if (msg instanceof $msg.UserClickedOnSourcePreview) {
    let source_id = msg.source_id;
    let _pipe = $data.toggle_visibility(data, source_id);
    return $pair.new$(
      _pipe,
      $ingestion_control.fetch_data_points(data, source_id),
    );
  } else if (msg instanceof $msg.UserSelectedSource) {
    let id = msg.id;
    let checked = msg.checked;
    let handler = (() => {
      if (checked) {
        return $content_library.select;
      } else {
        return $content_library.deselect;
      }
    })();
    let _pipe = data.content_library;
    let _pipe$1 = handler(_pipe, id);
    let _pipe$2 = $content_library.close_popups(_pipe$1);
    let _pipe$3 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$2);
    return $pair.new$(_pipe$3, $effect.none());
  } else {
    let _pipe = data.content_library;
    let _pipe$1 = $content_library.toggle_change_status(_pipe);
    let _pipe$2 = ((_capture) => {
      return $data.set_content_library(data, _capture);
    })(_pipe$1);
    return ((data) => { return [data, $effect.none()]; })(_pipe$2);
  }
}
