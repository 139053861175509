/// <reference types="./ai_search.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";

export function card(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($common.card()), $css.flex("1")]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($common.card()),
      $css.flex("1"),
      $css.display("flex"),
      $css.gap(px(16)),
      $css.overflow("auto"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_title(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($display.xs()), $css.color($colors.light.onyx)]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card_subtitle(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.color($colors.light.paragraph),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function response_source(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.line_height("2"),
      $css.gap(px(12)),
      $css.color($colors.light.grey_800),
      $css.align_items("center"),
      $css.cursor("default"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
