/// <reference types="./utils.d.mts" />
import * as $file from "../ds/plinth/browser/file.mjs";
import * as $global from "../ds/plinth/javascript/global.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import * as $uri from "../gleam_stdlib/gleam/uri.mjs";
import * as $a from "../lustre/lustre/attribute.mjs";
import * as $event from "../lustre/lustre/event.mjs";
import {
  getNavigatorLanguage as get_navigator_language_str,
  escapeHTML as escape_html,
} from "./admin.ffi.mjs";
import * as $ffi from "./admin/ffi.mjs";
import { Ok, Error, toList, isEqual } from "./gleam.mjs";

export { escape_html, get_navigator_language_str };

export function to_path(segments) {
  let _pipe = segments;
  let _pipe$1 = $list.map(
    _pipe,
    (_capture) => { return $string.replace(_capture, "/", ""); },
  );
  let _pipe$2 = $list.map(_pipe$1, $uri.percent_encode);
  let _pipe$3 = $string.join(_pipe$2, "/");
  return ((_capture) => { return $string.append("/", _capture); })(_pipe$3);
}

export function stop_propagation() {
  return $event.on(
    "click",
    (event) => {
      $event.stop_propagation(event);
      return new Error(toList([]));
    },
  );
}

export function stop_propagation_(msg) {
  return $event.on(
    "click",
    (event) => {
      $event.stop_propagation(event);
      return new Ok(msg);
    },
  );
}

export function on_files_change(msg) {
  return $event.on(
    "change",
    (event) => {
      let _pipe = $ffi.read_file_event(event);
      let _pipe$1 = $result.replace_error(_pipe, toList([]));
      return $result.map(_pipe$1, msg);
    },
  );
}

export function find_list_element_index(types, value) {
  let _pipe = types;
  let _pipe$1 = $list.find(_pipe, (v) => { return isEqual(v[1], value); });
  return $result.map(_pipe$1, $pair.first);
}

export function find_nth(loop$l, loop$index, loop$start) {
  while (true) {
    let l = loop$l;
    let index = loop$index;
    let start = loop$start;
    if (l.atLeastLength(1) && (index === start)) {
      let first = l.head;
      return new Ok(first);
    } else if (l.atLeastLength(1)) {
      let rest = l.tail;
      loop$l = rest;
      loop$index = index;
      loop$start = start + 1;
    } else {
      return new Error(undefined);
    }
  }
}

export function set_nil_timeout(timeout, fun) {
  $global.set_timeout(timeout, fun);
  return undefined;
}
