/// <reference types="./utils.d.mts" />
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import { toJSON as to_json } from "../common.ffi.mjs";
import { Ok, Error, toList, isEqual } from "../gleam.mjs";

export { to_json };

export function find_list_element_index(types, value) {
  let _pipe = types;
  let _pipe$1 = $list.find(_pipe, (v) => { return isEqual(v[1], value); });
  return $result.map(_pipe$1, $pair.first);
}

export function find_nth(loop$l, loop$index, loop$start) {
  while (true) {
    let l = loop$l;
    let index = loop$index;
    let start = loop$start;
    if (l.atLeastLength(1) && (index === start)) {
      let first = l.head;
      return new Ok(first);
    } else if (l.atLeastLength(1)) {
      let rest = l.tail;
      loop$l = rest;
      loop$index = index;
      loop$start = start + 1;
    } else {
      return new Error(undefined);
    }
  }
}

export const default_tags = /* @__PURE__ */ toList([
  "Engineering",
  "Product",
  "Security",
  "Sales",
  "Legal",
  "Marketing",
  "Other",
]);

export const default_qm_rows = /* @__PURE__ */ toList([
  [
    "Executive Buyer / Decision Maker Relationship",
    "Have we had a conversation with the executive buyer? #(Please note: The executive buyer is the person with the decision-making power and owns the budget for this project.)",
    0,
  ],
  [
    "Friend / Champion Relationship",
    "Do we have a good relationship with someone who has influence with the executive buyer / decision maker? Will they help us prep for meetings? Will they provide us with insight on how to win the RFP?",
    1,
  ],
  [
    "Reasonable Timeline",
    "Is the response timeline more than three weeks? Do we have enough time to respond with a high-quality proposal that doesn't require late nights and weekends?\n    Reason for Change	What is the reason the customer is going out to RFP? What problems are they trying to solve? Are they moving on from their incumbent provider?",
    2,
  ],
  [
    "Confirmed Budget",
    "Does the customer have a confirmed budget for this project?",
    3,
  ],
  [
    "Solution fit",
    "Is the solution the customer is looking for something you can fulfill? Can you provide 80% of the features or services they're asking for?",
    4,
  ],
  [
    "Purchasing Process",
    "Do you know the customer's purchasing process, going from RFP to signature? Do your contacts within the company know what it is?",
    5,
  ],
  [
    "Legal Compatability",
    "Is there anything in their RFP T&Cs that you can't agree with? Can you confirm with your legal team they can overcome any potential hurdle with the customer?",
    6,
  ],
  [
    "Competition",
    "Do you know who the competitors are? Do you have a good value proposition against each competitor?",
    7,
  ],
]);
