/// <reference types="./input.d.mts" />
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import * as $display from "../../../ds/typography/display.mjs";
import * as $text from "../../../ds/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.align_items("start"),
      $css.gap(px(6)),
      $css.width(percent(100)),
    ]),
  );
  return $h.label(_pipe, attrs, children);
}

export function input_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.position("relative"), $css.width(percent(100))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_display_wrapper(capture_events, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.position("absolute"),
      $css.top(px(0)),
      $css.height(percent(100)),
      $css.right(px(10)),
      $css.color($colors.light.grey_400),
      $css.z_index(10_000),
      $css.pointer_events(
        (() => {
          if (capture_events) {
            return "default";
          } else {
            return "none";
          }
        })(),
      ),
      $css.hover(toList([$css.color($colors.light.paragraph)])),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_display(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.width(px(24)),
      $css.height(px(24)),
      $css.color($colors.light.grey_600),
      $css.transition("all .2s"),
      $css.hover(toList([$css.color($colors.light.paragraph)])),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

function input_base(with_icon) {
  return $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.display("block"),
      $css.appearance("none"),
      $css.color($colors.light.paragraph),
      $css.property("border-width", "1px"),
      $css.property("border-style", "solid"),
      $css.font_family("Roobert"),
      $css.padding_right(
        (() => {
          if (!with_icon) {
            return px(14);
          } else {
            return px(24 + 14);
          }
        })(),
      ),
      $css.border_radius(px(8)),
      $css.transition("all .3s"),
      $css.width(percent(100)),
      $css.outline("none"),
    ]),
  );
}

export function standard(with_icon, attrs) {
  let _pipe = $css.class$(
    toList([
      $css.compose(input_base(with_icon)),
      $css.property("border-color", $colors.light.grey_400),
      $css.padding_("10px 14px"),
      $css.placeholder(toList([$css.color($colors.light.grey_800)])),
      $css.focus(
        toList([
          $css.border("1px solid " + $colors.light.ai_400),
          $css.outline("none"),
          $css.box_shadow("0px 0px 0px 4px " + $colors.light.ai_100),
        ]),
      ),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function standard_multiline(with_icon, attrs) {
  let _pipe = $css.class$(
    toList([
      $css.compose(input_base(with_icon)),
      $css.property("border-color", $colors.light.grey_400),
      $css.property("resize", "none"),
      $css.padding_("10px 14px"),
      $css.placeholder(toList([$css.color($colors.light.grey_800)])),
      $css.focus(
        toList([
          $css.border("1px solid " + $colors.light.ai_400),
          $css.outline("none"),
          $css.box_shadow("0px 0px 0px 4px " + $colors.light.ai_100),
        ]),
      ),
    ]),
  );
  return $h.textarea(_pipe, attrs, toList([]));
}

export function disabled(with_icon, attrs) {
  let _pipe = $css.class$(
    toList([
      $css.compose(input_base(with_icon)),
      $css.property("border-color", $colors.light.grey_400),
      $css.padding_("10px 14px"),
      $css.placeholder(toList([$css.color($colors.light.grey_600)])),
      $css.disabled(
        toList([
          $css.border("1px solid " + $colors.light.grey_400),
          $css.color($colors.light.grey_600),
          $css.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function invisible(with_icon, attrs) {
  let _pipe = $css.class$(
    toList([
      $css.compose(input_base(with_icon)),
      $css.property("border-color", "transparent"),
      $css.padding(px(0)),
      $css.compose($display.sm()),
      $css.placeholder(toList([$css.color($colors.light.grey_600)])),
      $css.disabled(
        toList([
          $css.border("1px solid " + $colors.light.grey_400),
          $css.color($colors.light.grey_600),
          $css.cursor("not-allowed"),
        ]),
      ),
      $css.active(
        toList([$css.property("border-color", $colors.light.grey_600)]),
      ),
      $css.focus(
        toList([
          $css.property("border-color", $colors.light.grey_600),
          $css.padding_("10px 14px"),
        ]),
      ),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function disabled_multiline(with_icon, attrs) {
  let _pipe = $css.class$(
    toList([
      $css.compose(input_base(with_icon)),
      $css.property("resize", "none"),
      $css.property("border-color", $colors.light.grey_400),
      $css.padding_("10px 14px"),
      $css.placeholder(toList([$css.color($colors.light.grey_600)])),
      $css.disabled(
        toList([
          $css.border("1px solid " + $colors.light.grey_400),
          $css.color($colors.light.grey_600),
          $css.cursor("not-allowed"),
        ]),
      ),
    ]),
  );
  return $h.textarea(_pipe, attrs, toList([]));
}

export function error(with_icon, attrs) {
  let _pipe = $css.class$(
    toList([
      $css.compose(input_base(with_icon)),
      (() => {
        let _pipe = $css.color($colors.light.error_800);
        return $css.important(_pipe);
      })(),
      $css.property("border-color", $colors.light.error_400),
      $css.padding_("10px 14px"),
      $css.box_shadow("0px 0px 0px 4px " + $colors.light.error_100),
      $css.placeholder(toList([$css.color($colors.light.error_600)])),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function error_multiline(with_icon, attrs) {
  let _pipe = $css.class$(
    toList([
      $css.compose(input_base(with_icon)),
      $css.property("resize", "none"),
      (() => {
        let _pipe = $css.color($colors.light.error_800);
        return $css.important(_pipe);
      })(),
      $css.property("border-color", $colors.light.error_400),
      $css.padding_("10px 14px"),
      $css.box_shadow("0px 0px 0px 4px " + $colors.light.error_100),
      $css.placeholder(toList([$css.color($colors.light.error_600)])),
    ]),
  );
  return $h.textarea(_pipe, attrs, toList([]));
}

export function error_label(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.error_800),
      $css.compose($text.sm($text.regular)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
