/// <reference types="./copy_button.d.mts" />
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $experimental from "../../../sketch_lustre_experimental/sketch/lustre/experimental.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { clipboardCopy as clipboard_copy } from "../../ds.ffi.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $icons from "../../ds/icons.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";
import * as $global from "../../plinth/javascript/global.mjs";

class Model extends $CustomType {
  constructor(copied, timeout, content) {
    super();
    this.copied = copied;
    this.timeout = timeout;
    this.content = content;
  }
}

class ButtonEndedTimeout extends $CustomType {}

class ButtonStartedTimeout extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserChangedContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class UserClickedCopy extends $CustomType {}

function on_attribute_change() {
  let _pipe = $dict.new$();
  return $dict.insert(
    _pipe,
    "content",
    (dyn) => {
      let _pipe$1 = $dynamic.string(dyn);
      return $result.map(
        _pipe$1,
        (var0) => { return new UserChangedContent(var0); },
      );
    },
  );
}

function init(_) {
  let _pipe = new Model(false, new $option.None(), "");
  return $pair.new$(_pipe, $effect.none());
}

function button(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.background($colors.light.grey_50),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(6)),
      $css.padding(px(4)),
      $css.color("inherit"),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

function view(model, container) {
  return $experimental.render(
    toList([container]),
    () => {
      return button(
        toList([$event.on_click(new UserClickedCopy())]),
        toList([
          $icons.tiny(
            (() => {
              let $ = model.copied;
              if ($) {
                return $icons.check();
              } else {
                return $icons.copy();
              }
            })(),
          ),
        ]),
      );
    },
  );
}

function update(model, msg) {
  if (msg instanceof ButtonEndedTimeout) {
    let _pipe = (() => {
      let _record = model;
      return new Model(false, new None(), _record.content);
    })();
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof ButtonStartedTimeout) {
    let timeout = msg[0];
    let timeout$1 = new Some(timeout);
    let _pipe = (() => {
      let _record = model;
      return new Model(_record.copied, timeout$1, _record.content);
    })();
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof UserChangedContent) {
    let content = msg[0];
    let _pipe = (() => {
      let _record = model;
      return new Model(_record.copied, _record.timeout, content);
    })();
    return $pair.new$(_pipe, $effect.none());
  } else {
    let _pipe = (() => {
      let _record = model;
      return new Model(true, _record.timeout, _record.content);
    })();
    return $pair.new$(
      _pipe,
      $effect.from(
        (dispatch) => {
          $option.map(model.timeout, $global.clear_timeout);
          clipboard_copy(model.content);
          let _pipe$1 = $global.set_timeout(
            2000,
            () => { return dispatch(new ButtonEndedTimeout()); },
          );
          let _pipe$2 = new ButtonStartedTimeout(_pipe$1);
          return dispatch(_pipe$2);
        },
      ),
    );
  }
}

const tag_name = "steerlab-copy-button";

export function copy_button(content) {
  let content$1 = $attribute.attribute("content", content);
  return $element.element(tag_name, toList([content$1]), toList([]));
}

export function register(container) {
  let _pipe = (_capture) => { return view(_capture, container); };
  let _pipe$1 = ((_capture) => {
    return $lustre.component(init, update, _capture, on_attribute_change());
  })(_pipe);
  return $lustre.register(_pipe$1, tag_name);
}
