/// <reference types="./bright.d.mts" />
import * as $bool from "../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../gleam_stdlib/gleam/dynamic.mjs";
import * as $function from "../gleam_stdlib/gleam/function.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../lustre/lustre/effect.mjs";
import { areDependenciesEqual as are_dependencies_equal } from "./bright.ffi.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, makeError } from "./gleam.mjs";

class Bright extends $CustomType {
  constructor(data, computed, selections, past_selections) {
    super();
    this.data = data;
    this.computed = computed;
    this.selections = selections;
    this.past_selections = past_selections;
  }
}

export function init(data, computed) {
  return new Bright(data, computed, toList([]), toList([]));
}

export function update(bright, update_) {
  let bright$1 = bright[0];
  let effects = bright[1];
  let $ = update_(bright$1.data);
  let data$1 = $[0];
  let effect = $[1];
  return [
    (() => {
      let _record = bright$1;
      return new Bright(
        data$1,
        _record.computed,
        _record.selections,
        _record.past_selections,
      );
    })(),
    $effect.batch(toList([effects, effect])),
  ];
}

export function compute(bright, compute_) {
  return $pair.map_first(
    bright,
    (bright) => {
      let _pipe = compute_(bright.data, bright.computed);
      return ((computed) => {
        let _record = bright;
        return new Bright(
          _record.data,
          computed,
          _record.selections,
          _record.past_selections,
        );
      })(_pipe);
    },
  );
}

export function schedule(bright, schedule_) {
  let bright$1 = bright[0];
  let effects = bright[1];
  let effect = schedule_(bright$1.data, bright$1.computed);
  return [bright$1, $effect.batch(toList([effects, effect]))];
}

export function unwrap(bright) {
  return [bright.data, bright.computed];
}

export function data(bright) {
  return bright.data;
}

export function computed(bright) {
  return bright.computed;
}

export function step(bright, next) {
  let bright$1 = bright[0];
  let effs = bright[1];
  let $ = next(bright$1);
  let model = $[0];
  let effs_ = $[1];
  return [model, $effect.batch(toList([effs, effs_]))];
}

function panic_if_different_computations_count(old_computations, computations) {
  let count = $list.length(old_computations);
  return $bool.guard(
    count === 0,
    undefined,
    () => {
      let is_same_count = count === $list.length(computations);
      return $bool.guard(
        is_same_count,
        undefined,
        () => {
          throw makeError(
            "panic",
            "bright",
            289,
            "",
            "Memoized computed should be consistent over time, otherwise memo can not work.",
            {}
          )
        },
      );
    },
  );
}

export function start(bright, next) {
  let old_computations = bright.past_selections;
  return $pair.map_first(
    next([bright, $effect.none()]),
    (new_data) => {
      panic_if_different_computations_count(
        old_computations,
        new_data.selections,
      );
      let past_selections = $list.reverse(new_data.selections);
      let _record = new_data;
      return new Bright(
        _record.data,
        _record.computed,
        toList([]),
        past_selections,
      );
    },
  );
}

function lazy_wrap(bright, selector, setter, compute_) {
  let selected_data = selector(bright[0].data);
  let selections = listPrepend(
    $dynamic.from(selected_data),
    bright[0].selections,
  );
  let compute_$1 = (data, computed) => {
    return compute_(data, computed, selected_data);
  };
  let bright$1 = [
    (() => {
      let _record = bright[0];
      return new Bright(
        _record.data,
        _record.computed,
        selections,
        _record.past_selections,
      );
    })(),
    bright[1],
  ];
  let $ = bright$1[0].past_selections;
  if ($.hasLength(0)) {
    return setter(bright$1, compute_$1);
  } else {
    let value = $.head;
    let past_selections = $.tail;
    let _pipe = [
      (() => {
        let _record = bright$1[0];
        return new Bright(
          _record.data,
          _record.computed,
          _record.selections,
          past_selections,
        );
      })(),
      bright$1[1],
    ];
    return (() => {
      let $1 = are_dependencies_equal(value, selected_data);
      if ($1) {
        return $function.identity;
      } else {
        return (_capture) => { return setter(_capture, compute_$1); };
      }
    })()(_pipe);
  }
}

export function lazy_compute(bright, selector, compute_) {
  return lazy_wrap(bright, selector, compute, compute_);
}

export function lazy_schedule(bright, selector, schedule_) {
  return lazy_wrap(bright, selector, schedule, schedule_);
}
