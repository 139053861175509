import * as analytics from '@vercel/analytics'
import * as speed from '@vercel/speed-insights'

export const vercel = {
  /** Inject Vercel Analytics & Speed Insights, iif production. */
  setup() {
    if (import.meta.env.PROD) {
      analytics.inject({ mode: 'production' })
      speed.injectSpeedInsights()
    }
  },
}
