/// <reference types="./checkbox.d.mts" />
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import { toList } from "../../../gleam.mjs";

export function label(attrs, children) {
  return $h.label_(attrs, children);
}

export function input(attrs) {
  let _pipe = $css.class$(
    toList([
      $css.visibility("hidden"),
      $css.width(px(0)),
      $css.height(px(0)),
      $css.position("fixed"),
      $css.top(px(-100_000)),
    ]),
  );
  return $h.input(_pipe, attrs);
}

export function checkbox(checked, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.width(px(20)),
      $css.height(px(20)),
      $css.transition("all .3s"),
      $css.background(
        (() => {
          if (checked) {
            return $colors.light.onyx;
          } else {
            return $colors.light.white;
          }
        })(),
      ),
      $css.border(
        "1px solid " + (() => {
          if (checked) {
            return $colors.light.onyx;
          } else {
            return $colors.light.grey_400;
          }
        })(),
      ),
      $css.border_radius(px(6)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.cursor("pointer"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon(child) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.width(px(14)),
      $css.height(px(14)),
      $css.color($colors.light.white),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}
