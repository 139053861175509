/// <reference types="./setup.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import * as $sketch_lustre from "../../../sketch_lustre/sketch/lustre.mjs";
import { Ok, toList } from "../../gleam.mjs";
import * as $error from "../../grille_pain/error.mjs";
import * as $model from "../../grille_pain/internals/data/model.mjs";
import * as $element from "../../grille_pain/internals/element.mjs";
import * as $shadow from "../../grille_pain/internals/shadow.mjs";
import * as $unsafe from "../../grille_pain/internals/unsafe.mjs";
import * as $view from "../../grille_pain/internals/view.mjs";

function do_mount() {
  return $result.try$(
    $element.create("grille-pain"),
    (node) => {
      return $result.try$(
        $element.create("div"),
        (lustre_root_) => {
          let lustre_root = $unsafe.coerce(lustre_root_);
          return $result.try$(
            $shadow.attach(node),
            (shadow) => {
              return $result.try$(
                $shadow.append_child(shadow, lustre_root_),
                (shadow) => {
                  return $result.try$(
                    $shadow.add_keyframe(shadow),
                    (shadow) => {
                      return $result.try$(
                        $element.body(),
                        (body) => {
                          let $ = $element.append_child(body, node);
                          
                          return new Ok([lustre_root, shadow]);
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function mount() {
  let _pipe = do_mount();
  return $error.context(_pipe, "Impossible to mount grille_pain.");
}

function container(shadow) {
  return $sketch_lustre.shadow($dynamic.from(shadow));
}

export function view(shadow) {
  let container$1 = container(shadow);
  let _pipe = $sketch.stylesheet(new $sketch.Persistent());
  let _pipe$1 = $error.sketch(_pipe);
  return $result.map(
    _pipe$1,
    (stylesheet) => {
      return (model) => {
        return $sketch_lustre.render(
          stylesheet,
          toList([container$1]),
          () => { return $view.view(model); },
        );
      };
    },
  );
}
