/// <reference types="./decrypt.d.mts" />
import * as $json from "../gleam_json/gleam/json.mjs";
import * as $decode from "../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $float from "../gleam_stdlib/gleam/float.mjs";
import * as $int from "../gleam_stdlib/gleam/int.mjs";
import * as $option from "../gleam_stdlib/gleam/option.mjs";
import { None } from "../gleam_stdlib/gleam/option.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $string from "../gleam_stdlib/gleam/string.mjs";
import * as $calendar from "../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../gleam_time/gleam/time/timestamp.mjs";
import * as $uuid from "../youid/youid/uuid.mjs";
import { toList } from "./gleam.mjs";

export function month() {
  return $decode.then$(
    $decode.int,
    (month) => {
      if (month === 1) {
        return $decode.success(new $calendar.January());
      } else if (month === 2) {
        return $decode.success(new $calendar.February());
      } else if (month === 3) {
        return $decode.success(new $calendar.March());
      } else if (month === 4) {
        return $decode.success(new $calendar.April());
      } else if (month === 5) {
        return $decode.success(new $calendar.May());
      } else if (month === 6) {
        return $decode.success(new $calendar.June());
      } else if (month === 7) {
        return $decode.success(new $calendar.July());
      } else if (month === 8) {
        return $decode.success(new $calendar.August());
      } else if (month === 9) {
        return $decode.success(new $calendar.September());
      } else if (month === 10) {
        return $decode.success(new $calendar.October());
      } else if (month === 11) {
        return $decode.success(new $calendar.November());
      } else if (month === 12) {
        return $decode.success(new $calendar.December());
      } else {
        return $decode.failure(new $calendar.January(), "Month");
      }
    },
  );
}

export function json(decoder) {
  return $decode.one_of(
    decoder,
    toList([
      $decode.then$(
        $decode.string,
        (json) => {
          let $ = $json.parse(json, decoder);
          if ($.isOk()) {
            let content = $[0];
            return $decode.success(content);
          } else {
            return decoder;
          }
        },
      ),
    ]),
  );
}

export function uuid() {
  let _pipe = $decode.one_of(
    $decode.string,
    toList([
      $decode.then$(
        $decode.bit_array,
        (content) => {
          let $ = $uuid.from_bit_array(content);
          if ($.isOk()) {
            let uuid$1 = $[0];
            return $decode.success($uuid.to_string(uuid$1));
          } else {
            return $decode.failure("", "UUID");
          }
        },
      ),
    ]),
  );
  return $decode.map(_pipe, $string.lowercase);
}

export function type_(type_) {
  return $decode.then$(
    $decode.string,
    (content) => {
      if (content === type_) {
        let content$1 = content;
        return $decode.success(content$1);
      } else {
        return $decode.failure(type_, "Type");
      }
    },
  );
}

export function optional_type_(type_) {
  return $decode.then$(
    $decode.optional($decode.string),
    (content) => {
      if (content instanceof $option.Some && (content[0] === type_)) {
        let content$1 = content[0];
        return $decode.success(content$1);
      } else if (content instanceof $option.None) {
        return $decode.success(type_);
      } else {
        return $decode.failure(type_, "Type");
      }
    },
  );
}

export function optional_field(field, decoder, next) {
  let decoder$1 = $decode.optional(decoder);
  return $decode.optional_field(field, new None(), decoder$1, next);
}

export function preprocess(decoder_a, decoder_b) {
  return $decode.then$(
    decoder_a,
    (content) => {
      let decoder_b$1 = decoder_b();
      let _pipe = $decode.run(content, decoder_b$1);
      let _pipe$1 = $result.map(_pipe, $decode.success);
      return $result.unwrap(_pipe$1, decoder_b$1);
    },
  );
}

function tuple_timestamp() {
  return $decode.field(
    0,
    $decode.field(
      0,
      int(),
      (year) => {
        return $decode.field(
          1,
          month(),
          (month) => {
            return $decode.field(
              2,
              int(),
              (day) => {
                let _pipe = new $calendar.Date(year, month, day);
                return $decode.success(_pipe);
              },
            );
          },
        );
      },
    ),
    (date) => {
      return $decode.field(
        1,
        $decode.field(
          0,
          int(),
          (hours) => {
            return $decode.field(
              1,
              int(),
              (minutes) => {
                return $decode.field(
                  2,
                  int(),
                  (seconds) => {
                    let _pipe = new $calendar.TimeOfDay(
                      hours,
                      minutes,
                      seconds,
                      0,
                    );
                    return $decode.success(_pipe);
                  },
                );
              },
            );
          },
        ),
        (time) => {
          let _pipe = $timestamp.from_calendar(date, time, $calendar.utc_offset);
          return $decode.success(_pipe);
        },
      );
    },
  );
}

export function timestamp() {
  let tuple = tuple_timestamp();
  let rfc3339 = rfc3339_decoder();
  return $decode.one_of(tuple, toList([rfc3339]));
}

function rfc3339_decoder() {
  return $decode.then$(
    $decode.string,
    (content) => {
      let $ = $timestamp.parse_rfc3339(content);
      if ($.isOk()) {
        let time = $[0];
        return $decode.success(time);
      } else {
        return $decode.failure($timestamp.system_time(), "time");
      }
    },
  );
}

export function float() {
  let as_float = (() => {
    let _pipe = $decode.int;
    return $decode.map(_pipe, $int.to_float);
  })();
  let int_string$1 = (() => {
    let _pipe = int_string();
    return $decode.map(_pipe, $int.to_float);
  })();
  let float_string$1 = float_string();
  return $decode.one_of(
    $decode.float,
    toList([as_float, float_string$1, int_string$1]),
  );
}

export function int() {
  let as_int = (() => {
    let _pipe = $decode.float;
    return $decode.map(_pipe, $float.round);
  })();
  let int_string$1 = int_string();
  let float_string$1 = (() => {
    let _pipe = float_string();
    return $decode.map(_pipe, $float.round);
  })();
  return $decode.one_of(
    $decode.int,
    toList([as_int, int_string$1, float_string$1]),
  );
}

function int_string() {
  return $decode.then$(
    $decode.string,
    (string) => {
      let $ = $int.parse(string);
      if ($.isOk()) {
        let value = $[0];
        return $decode.success(value);
      } else {
        return $decode.failure(0, "Int");
      }
    },
  );
}

function float_string() {
  return $decode.then$(
    $decode.string,
    (string) => {
      let $ = $float.parse(string);
      if ($.isOk()) {
        let value = $[0];
        return $decode.success(value);
      } else {
        return $decode.failure(0.0, "Float");
      }
    },
  );
}
