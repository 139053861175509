import * as lustre from '../lustre/lustre.mjs'
import * as effect from '../lustre/lustre/effect.mjs'
import * as element from '../lustre/lustre/element.mjs'
import * as event from '../lustre/lustre/event.mjs'
import * as runtime from '../lustre/lustre/internals/runtime.mjs'
import * as gleam from './gleam.mjs'

export const timestamp = () => Date.now()

export function coerceEventDetails(event) {
  event.stopPropagation()
  return event.detail
}

export class LustrePortal extends HTMLElement {
  #mountNode
  #renderNode
  #content
  #send
  #model

  constructor() {
    super()
    this.#model = 0
    this.#mountNode = document.createElement('div')
    this.#mountNode.setAttribute('class', 'lustre-portal')
    this.#mountNode.addEventListener('portal-event', event => {
      this.dispatchEvent(
        new CustomEvent('portal-event', {
          bubbles: true,
          detail: event.detail,
          composed: true,
        })
      )
    })
    this.#renderNode = document.createElement('div')
    this.#mountNode.appendChild(this.#renderNode)
    this.#content = gleam.List.fromArray([element.none()])
    Object.defineProperty(this, 'content', {
      set(value) {
        this.#content = value
        this.#model += 1
        this.#send?.(new runtime.Debug(new runtime.ForceModel(this.#model)))
      },
    })
  }

  connectedCallback() {
    document.body.appendChild(this.#mountNode)
    const init = () => [this.#model, effect.none()]
    const update = (model, msg) => [model, event.emit('portal-event', msg)]
    const view = model => this.#content
    const app = lustre.application(init, update, view)
    const result = lustre.start(app, this.#renderNode, null)
    if (result instanceof gleam.Ok) {
      this.#send = result[0]
    }
  }

  disconnectedCallback() {
    document.body.removeChild(this.#mountNode)
    this.#send?.(new runtime.Shutdown())
  }
}

export function register() {
  const name = 'lustre-portal'
  if (window.customElements.get(name))
    return new gleam.Error(new lustre.ComponentAlreadyRegistered(name))
  customElements.define(name, LustrePortal)
  return new gleam.Ok()
}

export function subscribeDOMClick(dispatch) {
  // prettier-ignore
  const run = () => {
    try { dispatch() }
    catch (e) { console.log(e) }
  }
  setTimeout(() => document.addEventListener('click', run, { once: true }), 100)
  return () => document.removeEventListener('click', run)
}

export function blurTarget(event) {
  event.preventDefault()
  event.target?.blur()
}

export function toggleDomFreeze() {
  const elements = document.getElementsByTagName('*')
  if (document.body.style.getPropertyValue('overflow')) {
    document.body.style.removeProperty('overflow')
    for (const element of elements) {
      if (element.style.getPropertyValue('overflow'))
        element.style.removeProperty('overflow')
    }
  } else {
    document.body.style.setProperty('overflow', 'hidden')
    for (const element of elements) {
      const styles = window.getComputedStyle(element)
      if (styles.overflow === 'auto')
        element.style.setProperty('overflow', 'hidden')
    }
  }
}

export function getEventBoundingClientRect(event) {
  const containsClass = el =>
    el.getAttribute('class')?.includes?.('domrect-parent-wrapper') ?? false
  let target = event.target
  while (target && !containsClass(target)) target = target.parentElement
  if (!target) return new gleam.Error('No event target')
  if (!target.getBoundingClientRect)
    return new gleam.Error('No bounding rect function')
  const rect = target.getBoundingClientRect()
  if (!rect) return new gleam.Error('No bounding rect')
  return new gleam.Ok(rect)
}

export function platform() {
  if (typeof 'window' === undefined) return new gleam.Error()
  if (!('platform' in window.navigator)) return new gleam.Error()
  return new gleam.Ok(window.navigator.platform)
}

export function clipboardCopy(text) {
  return navigator.clipboard.writeText(text)
}
