/// <reference types="./extra.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $result from "../../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import * as $calendar from "../../../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../../../gleam_time/gleam/time/timestamp.mjs";
import { Error, toList } from "../../../gleam.mjs";
import * as $month from "../../../gleam/time/calendar/month.mjs";

export function to_qualified_day(time, separator) {
  let $ = $timestamp.to_calendar(time, $calendar.utc_offset);
  let date = $[0];
  let month = $month.to_int(date.month);
  let month$1 = $string.pad_start($int.to_string(month), 2, "0");
  let year = $string.pad_start($int.to_string(date.year), 4, "0");
  let day = $string.pad_start($int.to_string(date.day), 2, "0");
  return $string.join(toList([year, month$1, day]), separator);
}

export function from_time_input(value) {
  let $ = $string.split(value, "-");
  if ($.hasLength(3)) {
    let year = $.head;
    let month = $.tail.head;
    let day = $.tail.tail.head;
    return $result.try$(
      $int.parse(year),
      (year) => {
        return $result.try$(
          $month.from_input(month),
          (month) => {
            return $result.map(
              $int.parse(day),
              (day) => {
                let date = new $calendar.Date(year, month, day);
                let time = new $calendar.TimeOfDay(0, 0, 0, 0);
                return $timestamp.from_calendar(
                  date,
                  time,
                  $calendar.local_offset(),
                );
              },
            );
          },
        );
      },
    );
  } else {
    return new Error(undefined);
  }
}
