/// <reference types="./endpoints.d.mts" />
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $vitools from "../../vitools/vitools.mjs";

function api() {
  let _pipe = $vitools.get_env("API_ENDPOINT");
  return $result.unwrap(_pipe, "");
}

export function wss() {
  return $bool.guard(
    $vitools.is_dev(),
    "ws://localhost:3000/wss",
    () => {
      let _pipe = api();
      let _pipe$1 = $string.replace(_pipe, "http", "ws");
      let _pipe$2 = $string.replace(_pipe$1, "https", "wss");
      return $string.append(_pipe$2, "/wss");
    },
  );
}

export const localhost = "http://localhost:3000";

export function heimdall() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/heimdall";
  } else {
    return api() + "/heimdall";
  }
}

export function loki() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/loki";
  } else {
    return api() + "/loki";
  }
}

export function nabu() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/nabu";
  } else {
    return api() + "/nabu";
  }
}

export function hermes() {
  let $ = $vitools.is_dev();
  if ($) {
    return localhost + "/hermes/slack/redirect";
  } else {
    return api() + "/hermes";
  }
}
