/// <reference types="./colors.d.mts" />
import { CustomType as $CustomType } from "../gleam.mjs";

export class Colors extends $CustomType {
  constructor(onyx, paragraph, white, grey_50, grey_100, grey_200, grey_400, grey_600, grey_800, cream, foam, error_50, error_100, error_400, error_600, error_800, success_50, success_100, success_400, success_600, success_800, blue_50, blue_100, blue_400, blue_600, blue_800, purple_50, purple_100, purple_400, purple_600, purple_800, ai_100, ai_400, ai_800, orange_800, yellow_50, yellow_100, yellow_400, yellow_600, yellow_800, gradient, dark_gradient, transparent) {
    super();
    this.onyx = onyx;
    this.paragraph = paragraph;
    this.white = white;
    this.grey_50 = grey_50;
    this.grey_100 = grey_100;
    this.grey_200 = grey_200;
    this.grey_400 = grey_400;
    this.grey_600 = grey_600;
    this.grey_800 = grey_800;
    this.cream = cream;
    this.foam = foam;
    this.error_50 = error_50;
    this.error_100 = error_100;
    this.error_400 = error_400;
    this.error_600 = error_600;
    this.error_800 = error_800;
    this.success_50 = success_50;
    this.success_100 = success_100;
    this.success_400 = success_400;
    this.success_600 = success_600;
    this.success_800 = success_800;
    this.blue_50 = blue_50;
    this.blue_100 = blue_100;
    this.blue_400 = blue_400;
    this.blue_600 = blue_600;
    this.blue_800 = blue_800;
    this.purple_50 = purple_50;
    this.purple_100 = purple_100;
    this.purple_400 = purple_400;
    this.purple_600 = purple_600;
    this.purple_800 = purple_800;
    this.ai_100 = ai_100;
    this.ai_400 = ai_400;
    this.ai_800 = ai_800;
    this.orange_800 = orange_800;
    this.yellow_50 = yellow_50;
    this.yellow_100 = yellow_100;
    this.yellow_400 = yellow_400;
    this.yellow_600 = yellow_600;
    this.yellow_800 = yellow_800;
    this.gradient = gradient;
    this.dark_gradient = dark_gradient;
    this.transparent = transparent;
  }
}

export const light = /* @__PURE__ */ new Colors(
  "#27272C",
  "#454545",
  "#FFFFFF",
  "#F8F8F8",
  "#F5F6F6",
  "#EAEBEC",
  "#DADAE2",
  "#B8B8C3",
  "#7E7F88",
  "#F6F3EB",
  "#EBF3F3",
  "#FFF5F5",
  "#FFEEEE",
  "#FBC4C4",
  "#FB9D9D",
  "#F35555",
  "#F7FCEA",
  "#F6FFDF",
  "#E6FAB5",
  "#CBF26C",
  "#B1DB4B",
  "#F5F9FF",
  "#EEF4FE",
  "#E2EDFF",
  "#B7CFF2",
  "#3B75CA",
  "#F5F0FF",
  "#E8E2FE",
  "#C6B1F2",
  "#A88FE0",
  "#7E4DCF",
  "#F6F3FF",
  "#D3C4FB",
  "#8565DB",
  "#F29100",
  "#FFFDE7",
  "#FFF9C4",
  "#FFEE58",
  "#FDD835",
  "#FBC02D",
  "linear-gradient(to right, #E6FAB5, #D3C4FB)",
  "linear-gradient(to right, #B1DB4B, #8565DB)",
  "transparent",
);
