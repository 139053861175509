/// <reference types="./tab_bar.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $text from "../../ds/typography/text.mjs";
import { toList } from "../../gleam.mjs";

export function tab_bar(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.margin_top(px(-16))]),
  );
  return $h.nav(_pipe, attrs, children);
}

export function tab(active, attrs, children) {
  let border = (() => {
    if (active) {
      return $colors.light.onyx;
    } else {
      return "transparent";
    }
  })();
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.medium)),
      $css.padding_("10px 24px"),
      $css.border_bottom("2px solid " + border),
      $css.text_decoration("none"),
      $css.transition("all .3s"),
      $css.color(
        (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return $colors.light.grey_600;
          }
        })(),
      ),
      $css.cursor("pointer"),
      (() => {
        if (active) {
          return $css.none();
        } else {
          return $css.hover(
            toList([
              $css.color($colors.light.grey_800),
              $css.border_bottom_color($colors.light.grey_800),
            ]),
          );
        }
      })(),
      $css.selector(
        "[disabled='True']",
        toList([
          $css.color($colors.light.grey_600),
          $css.border_bottom("none"),
          $css.pointer_events("none"),
        ]),
      ),
    ]),
  );
  return $h.a(_pipe, attrs, children);
}
