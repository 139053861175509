/// <reference types="./grille_pain.d.mts" />
import * as $bool from "../gleam_stdlib/gleam/bool.mjs";
import * as $list from "../gleam_stdlib/gleam/list.mjs";
import * as $option from "../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../lustre/lustre.mjs";
import * as $effect from "../lustre/lustre/effect.mjs";
import { Ok, makeError } from "./gleam.mjs";
import * as $error from "./grille_pain/error.mjs";
import * as $model from "./grille_pain/internals/data/model.mjs";
import { Model } from "./grille_pain/internals/data/model.mjs";
import * as $msg from "./grille_pain/internals/data/msg.mjs";
import * as $toast from "./grille_pain/internals/data/toast.mjs";
import * as $effect_manager from "./grille_pain/internals/effect_manager.mjs";
import * as $schedule from "./grille_pain/internals/lustre/schedule.mjs";
import { schedule } from "./grille_pain/internals/lustre/schedule.mjs";
import * as $setup from "./grille_pain/internals/setup.mjs";
import * as $options from "./grille_pain/options.mjs";

function external_hide(model, uuid) {
  let toast = (() => {
    let _pipe = model.toasts;
    return $list.find(
      _pipe,
      (_capture) => { return $toast.by_uuid(_capture, uuid); },
    );
  })();
  return $result.map(
    toast,
    (toast) => {
      return [model, schedule(1000, new $msg.Hide(toast.id, toast.iteration))];
    },
  );
}

function hide(model, id, iteration) {
  let toast = (() => {
    let _pipe = model.toasts;
    return $list.find(
      _pipe,
      (_capture) => { return $toast.by_iteration(_capture, id, iteration); },
    );
  })();
  return $result.map(
    toast,
    (toast) => {
      let _pipe = model;
      let _pipe$1 = $model.hide(_pipe, toast.id);
      let _pipe$2 = $model.decrease_bottom(_pipe$1, toast.id);
      return $pair.new$(_pipe$2, schedule(1000, new $msg.Remove(id)));
    },
  );
}

function new$(model, msg) {
  if (!(msg instanceof $msg.New)) {
    throw makeError(
      "let_assert",
      "grille_pain",
      91,
      "new",
      "Pattern match failed, no pattern matched the value.",
      { value: msg }
    )
  }
  let uuid = msg.uuid;
  let message = msg.message;
  let level = msg.level;
  let timeout = msg.timeout;
  let sticky = msg.sticky;
  let old_id = model.id;
  let new_model = $model.add(model, uuid, message, level, sticky, timeout);
  return new Ok(
    [new_model, schedule(100, new $msg.Show(old_id, timeout, sticky))],
  );
}

function schedule_hide(sticky, timeout, id, iteration) {
  return $bool.lazy_guard(
    sticky,
    $effect.none,
    () => { return schedule(timeout, new $msg.Hide(id, iteration)); },
  );
}

function show(model, msg) {
  if (!(msg instanceof $msg.Show)) {
    throw makeError(
      "let_assert",
      "grille_pain",
      61,
      "show",
      "Pattern match failed, no pattern matched the value.",
      { value: msg }
    )
  }
  let id = msg.id;
  let timeout = msg.timeout;
  let sticky = msg.sticky;
  let time = $option.unwrap(timeout, model.timeout);
  let new_model = $model.show(model, id);
  let eff = schedule_hide(sticky, time, id, 0);
  return new Ok([new_model, eff]);
}

function resume(model, id) {
  let new_model = $model.resume(model, id);
  let toast = (() => {
    let _pipe = model.toasts;
    return $list.find(
      _pipe,
      (_capture) => { return $toast.by_id(_capture, id); },
    );
  })();
  return $result.map(
    toast,
    (_use0) => {
      let sticky = _use0.sticky;
      let remaining = _use0.remaining;
      let iteration = _use0.iteration;
      return [new_model, schedule_hide(sticky, remaining, id, iteration)];
    },
  );
}

function update(model, msg) {
  let _pipe = (() => {
    if (msg instanceof $msg.Remove) {
      let id = msg.id;
      return new Ok([$model.remove(model, id), $effect.none()]);
    } else if (msg instanceof $msg.Stop) {
      let id = msg.id;
      return new Ok([$model.stop(model, id), $effect.none()]);
    } else if (msg instanceof $msg.Show) {
      return show(model, msg);
    } else if (msg instanceof $msg.ExternalHide) {
      let uuid = msg.uuid;
      return external_hide(model, uuid);
    } else if (msg instanceof $msg.Hide) {
      let id = msg.id;
      let iteration = msg.iteration;
      return hide(model, id, iteration);
    } else if (msg instanceof $msg.Resume) {
      let id = msg.id;
      return resume(model, id);
    } else {
      return new$(model, msg);
    }
  })();
  return $result.unwrap(_pipe, [model, $effect.none()]);
}

export function setup(opts) {
  return $result.try$(
    $setup.mount(),
    (_use0) => {
      let lustre_root = _use0[0];
      let shadow = _use0[1];
      return $result.try$(
        $setup.view(shadow),
        (view) => {
          return $result.map(
            (() => {
              let _pipe = (_) => {
                return [$model.new$(shadow, opts.timeout), $effect.none()];
              };
              let _pipe$1 = $lustre.application(_pipe, update, view);
              let _pipe$2 = $lustre.start(_pipe$1, lustre_root, undefined);
              return $error.lustre(_pipe$2);
            })(),
            (dispatcher) => { return $effect_manager.register(dispatcher); },
          );
        },
      );
    },
  );
}

export function simple() {
  let _pipe = $options.default$();
  return setup(_pipe);
}
