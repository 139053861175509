/// <reference types="./experimental.d.mts" />
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $vdom from "../../../lustre/lustre/internals/vdom.mjs";
import * as $sketch from "../../../sketch/sketch.mjs";
import { toList, CustomType as $CustomType, makeError } from "../../gleam.mjs";
import * as $css_stylesheet from "../../sketch/lustre/experimental/internals/css_stylesheet.mjs";
import * as $global from "../../sketch/lustre/experimental/internals/global.mjs";

class Document extends $CustomType {
  constructor(css_stylesheet) {
    super();
    this.css_stylesheet = css_stylesheet;
  }
}

class Node extends $CustomType {}

class Shadow extends $CustomType {
  constructor(css_stylesheet) {
    super();
    this.css_stylesheet = css_stylesheet;
  }
}

export function setup(stylesheet) {
  return $global.set_stylesheet(stylesheet);
}

export function render(outputs, view) {
  let new_view = view();
  let $ = $global.get_stylesheet();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "sketch/lustre/experimental",
      68,
      "render",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let stylesheet = $[0];
  let content = $sketch.render(stylesheet);
  return $list.fold(
    outputs,
    new_view,
    (view, stylesheet) => {
      if (stylesheet instanceof Node) {
        return $el.fragment(toList([$h.style(toList([]), content), view]));
      } else if (stylesheet instanceof Document) {
        let css_stylesheet = stylesheet.css_stylesheet;
        return $function.tap(
          view,
          (_) => { return $css_stylesheet.replace(content, css_stylesheet); },
        );
      } else {
        let css_stylesheet = stylesheet.css_stylesheet;
        return $function.tap(
          view,
          (_) => { return $css_stylesheet.replace(content, css_stylesheet); },
        );
      }
    },
  );
}

export function document() {
  let css_stylesheet = $css_stylesheet.create(new $css_stylesheet.Document());
  return new Document(css_stylesheet);
}

export function shadow(root) {
  let css_stylesheet = $css_stylesheet.create(
    new $css_stylesheet.ShadowRoot(root),
  );
  return new Shadow(css_stylesheet);
}

export function node() {
  return new Node();
}

function contains_head(el) {
  if (el instanceof $vdom.Element && el.tag === "head") {
    return true;
  } else if (el instanceof $vdom.Element) {
    let children = el.children;
    return $list.fold(
      children,
      false,
      (acc, val) => { return acc || contains_head(val); },
    );
  } else {
    return false;
  }
}

function put_in_head(el, content) {
  if (el instanceof $vdom.Element && el.tag === "head") {
    let k = el.key;
    let n = el.namespace;
    let a = el.attrs;
    let children = el.children;
    let s = el.self_closing;
    let v = el.void;
    let _pipe = children;
    let _pipe$1 = $list.append(_pipe, toList([$h.style(toList([]), content)]));
    return ((_capture) => {
      return new $vdom.Element(k, n, "head", a, _capture, s, v);
    })(_pipe$1);
  } else if (el instanceof $vdom.Element && el.tag === "html") {
    let k = el.key;
    let n = el.namespace;
    let a = el.attrs;
    let children = el.children;
    let s = el.self_closing;
    let v = el.void;
    let _pipe = children;
    let _pipe$1 = $list.map(
      _pipe,
      (child) => { return put_in_head(child, content); },
    );
    return ((_capture) => {
      return new $vdom.Element(k, n, "html", a, _capture, s, v);
    })(_pipe$1);
  } else {
    let node$1 = el;
    return node$1;
  }
}

export function ssr(view) {
  let new_view = view();
  let $ = $global.get_stylesheet();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "sketch/lustre/experimental",
      122,
      "ssr",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let stylesheet = $[0];
  let content = $sketch.render(stylesheet);
  let $1 = contains_head(new_view);
  if ($1) {
    return put_in_head(new_view, content);
  } else {
    return $el.fragment(toList([$h.style(toList([]), content), new_view]));
  }
}
