/// <reference types="./cache.d.mts" />
import * as $bool from "../../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../../gleam_stdlib/gleam/pair.mjs";
import * as $string from "../../../../gleam_stdlib/gleam/string.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, makeError } from "../../../gleam.mjs";
import * as $sketch_string from "../../../sketch/internals/string.mjs";
import { xxHash32 as compute_hash } from "../../../xxhash.ffi.mjs";

export class Class extends $CustomType {
  constructor(as_string, content, name) {
    super();
    this.as_string = as_string;
    this.content = content;
    this.name = name;
  }
}

class Definitions extends $CustomType {
  constructor(medias, selectors, class$) {
    super();
    this.medias = medias;
    this.selectors = selectors;
    this.class = class$;
  }
}

class ComputedClass extends $CustomType {
  constructor(id, name, class_name, definitions) {
    super();
    this.id = id;
    this.name = name;
    this.class_name = class_name;
    this.definitions = definitions;
  }
}

class Cache extends $CustomType {
  constructor(cache, at_rules) {
    super();
    this.cache = cache;
    this.at_rules = at_rules;
  }
}

export class AtRule extends $CustomType {
  constructor(as_string, rule, content) {
    super();
    this.as_string = as_string;
    this.rule = rule;
    this.content = content;
  }
}

export class AtRuleClasses extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AtRuleContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ClassName extends $CustomType {
  constructor(class$) {
    super();
    this.class = class$;
  }
}

export class Media extends $CustomType {
  constructor(query, styles) {
    super();
    this.query = query;
    this.styles = styles;
  }
}

export class Selector extends $CustomType {
  constructor(selector, styles) {
    super();
    this.selector = selector;
    this.styles = styles;
  }
}

export class Combinator extends $CustomType {
  constructor(selector, class$, styles) {
    super();
    this.selector = selector;
    this.class = class$;
    this.styles = styles;
  }
}

export class Property extends $CustomType {
  constructor(key, value, important) {
    super();
    this.key = key;
    this.value = value;
    this.important = important;
  }
}

export class NoStyle extends $CustomType {}

class Properties extends $CustomType {
  constructor(properties, medias, selectors, indentation) {
    super();
    this.properties = properties;
    this.medias = medias;
    this.selectors = selectors;
    this.indentation = indentation;
  }
}

class MediaProperty extends $CustomType {
  constructor(query, properties, selectors) {
    super();
    this.query = query;
    this.properties = properties;
    this.selectors = selectors;
  }
}

class SelectorProperty extends $CustomType {
  constructor(selector, properties) {
    super();
    this.selector = selector;
    this.properties = properties;
  }
}

export function classes_rule(rule, content) {
  let as_string = $string.inspect(content);
  let content$1 = new AtRuleClasses(content);
  return new AtRule(as_string, rule, content$1);
}

export function content_rule(rule, content) {
  let as_string = content;
  let content$1 = new AtRuleContent(content);
  return new AtRule(as_string, rule, content$1);
}

export function new$() {
  return new Cache($dict.new$(), $dict.new$());
}

export function class$(content) {
  let as_string = $string.inspect(content);
  return new Class(as_string, content, new None());
}

export function named(name, content) {
  let as_string = $string.inspect(content);
  return new Class(as_string, content, new Some(name));
}

function empty_computed() {
  let definitions = new Definitions(toList([]), toList([]), "");
  return new ComputedClass(0, "", "", definitions);
}

function wrap_selectors(id, indentation, selectors) {
  return $list.map(
    selectors,
    (selector) => {
      let selector$1 = selector.selector;
      let properties = selector.properties;
      return $sketch_string.wrap_class(
        id,
        properties,
        indentation,
        new Some(selector$1),
      );
    },
  );
}

function compute_classes(id, name, properties) {
  let class_name$1 = $option.lazy_unwrap(
    name,
    () => { return "css-" + $int.to_string(id); },
  );
  let name$1 = $option.lazy_unwrap(
    name,
    () => { return ".css-" + $int.to_string(id); },
  );
  let properties$1 = properties.properties;
  let medias = properties.medias;
  let selectors = properties.selectors;
  let class$1 = $sketch_string.wrap_class(name$1, properties$1, 0, new None());
  let selectors$1 = wrap_selectors(name$1, 0, selectors);
  return new ComputedClass(
    id,
    name$1,
    class_name$1,
    new Definitions(
      $list.map(
        medias,
        (_use0) => {
          let query = _use0.query;
          let properties$2 = _use0.properties;
          let selectors$2 = _use0.selectors;
          let selectors$3 = wrap_selectors(name$1, 2, selectors$2);
          let _pipe = toList([
            query + " {",
            $sketch_string.wrap_class(name$1, properties$2, 2, new None()),
          ]);
          let _pipe$1 = ((_capture) => {
            return $list.prepend(toList([selectors$3, toList(["}"])]), _capture);
          })(_pipe);
          let _pipe$2 = $list.flatten(_pipe$1);
          return $string.join(_pipe$2, "\n");
        },
      ),
      selectors$1,
      class$1,
    ),
  );
}

function compute_property(indent, key, value, important) {
  let base_indent = $sketch_string.indent(indent);
  let important$1 = (() => {
    if (important) {
      return " !important";
    } else {
      return "";
    }
  })();
  return ((((base_indent + key) + ": ") + value) + important$1) + ";";
}

function handle_property(props, property) {
  if (!(property instanceof Property)) {
    throw makeError(
      "let_assert",
      "sketch/internals/cache/cache",
      243,
      "handle_property",
      "Pattern match failed, no pattern matched the value.",
      { value: property }
    )
  }
  let key = property.key;
  let value = property.value;
  let important = property.important;
  let css_property = compute_property(props.indentation, key, value, important);
  let properties = listPrepend(css_property, props.properties);
  let _record = props;
  return new Properties(
    properties,
    _record.medias,
    _record.selectors,
    _record.indentation,
  );
}

function merge_computed_properties(target, argument) {
  return new Properties(
    $list.append(argument.properties, target.properties),
    $list.append(argument.medias, target.medias),
    $list.append(argument.selectors, target.selectors),
    target.indentation,
  );
}

function get_definitions(class$) {
  let $ = class$.definitions;
  let medias = $.medias;
  let selectors = $.selectors;
  let class$1 = $.class;
  let _pipe = toList([toList([class$1]), selectors, medias]);
  return $list.flatten(_pipe);
}

export function render_sheet(cache) {
  let _pipe = $dict.values(cache.at_rules);
  let _pipe$1 = $list.append(
    _pipe,
    (() => {
      let _pipe$1 = $dict.values(cache.cache);
      return $list.flat_map(_pipe$1, (c) => { return get_definitions(c[0]); });
    })(),
  );
  return $string.join(_pipe$1, "\n\n");
}

function handle_combinator(cache, props, combinator, existing_selector) {
  if (!(combinator instanceof Combinator)) {
    throw makeError(
      "let_assert",
      "sketch/internals/cache/cache",
      288,
      "handle_combinator",
      "Pattern match failed, no pattern matched the value.",
      { value: combinator }
    )
  }
  let selector = combinator.selector;
  let class$1 = combinator.class;
  let styles = combinator.styles;
  let indentation = props.indentation + 2;
  let $ = computed_class(class$1, cache);
  let cache$1 = $[0];
  let class$2 = $[1];
  let selector$1 = (existing_selector + selector) + class$2.name;
  let $1 = compute_properties(cache$1, styles, indentation, selector$1);
  let cache$2 = $1[0];
  let properties = $1[1];
  let _pipe = new SelectorProperty(selector$1, properties.properties);
  let _pipe$1 = ((_capture) => {
    return $list.prepend(properties.selectors, _capture);
  })(_pipe);
  let _pipe$2 = $list.append(_pipe$1, props.selectors);
  let _pipe$3 = ((selectors) => {
    let _record = props;
    return new Properties(
      _record.properties,
      _record.medias,
      selectors,
      _record.indentation,
    );
  })(_pipe$2);
  return ((_capture) => { return $pair.new$(cache$2, _capture); })(_pipe$3);
}

function compute_properties(cache, properties, indentation, existing_selector) {
  let init = new Properties(toList([]), toList([]), toList([]), indentation);
  return $list.fold(
    $list.reverse(properties),
    [cache, init],
    (_use0, p) => {
      let cache$1 = _use0[0];
      let acc = _use0[1];
      if (p instanceof NoStyle) {
        return [cache$1, acc];
      } else if (p instanceof Property) {
        return [cache$1, handle_property(acc, p)];
      } else if (p instanceof Media) {
        return handle_media(cache$1, acc, p);
      } else if (p instanceof Selector) {
        return handle_selector(cache$1, acc, p, existing_selector);
      } else if (p instanceof Combinator) {
        return handle_combinator(cache$1, acc, p, existing_selector);
      } else {
        let class$1 = p.class;
        let $ = $dict.get(cache$1.cache, class$1.as_string);
        if ($.isOk()) {
          let props = $[0][1];
          return [cache$1, merge_computed_properties(acc, props)];
        } else {
          let _pipe = compute_properties(
            cache$1,
            class$1.content,
            indentation,
            "",
          );
          return $pair.map_second(
            _pipe,
            (_capture) => { return merge_computed_properties(acc, _capture); },
          );
        }
      }
    },
  );
}

export function at_rule(rule, cache) {
  let $ = $dict.get(cache.at_rules, rule.as_string);
  if ($.isOk()) {
    return cache;
  } else {
    let _pipe = (() => {
      let $1 = rule.content;
      if ($1 instanceof AtRuleContent) {
        let content = $1[0];
        return ((("@" + rule.rule) + " {") + content) + "}";
      } else {
        let classes = $1[0];
        let _pipe = $list.map(
          classes,
          (class$) => {
            let $2 = compute_properties(cache, class$.content, 2, "");
            let properties = $2[1];
            let class_ = (() => {
              let _pipe = class$.as_string;
              let _pipe$1 = compute_hash(_pipe);
              return compute_classes(_pipe$1, class$.name, properties);
            })();
            return class_.definitions.class;
          },
        );
        let _pipe$1 = $list.map(
          _pipe,
          (s) => {
            let _pipe$1 = $string.split(s, "\n");
            let _pipe$2 = $list.map(
              _pipe$1,
              (_capture) => { return $string.append("  ", _capture); },
            );
            return $string.join(_pipe$2, "\n");
          },
        );
        let _pipe$2 = $string.join(_pipe$1, "\n\n");
        let _pipe$3 = ((_capture) => {
          return $list.prepend(toList(["}"]), _capture);
        })(_pipe$2);
        let _pipe$4 = $list.prepend(_pipe$3, ("@" + rule.rule) + " {");
        return $string.join(_pipe$4, "\n");
      }
    })();
    let _pipe$1 = ((_capture) => {
      return $dict.insert(cache.at_rules, rule.as_string, _capture);
    })(_pipe);
    return ((at_rules) => {
      let _record = cache;
      return new Cache(_record.cache, at_rules);
    })(_pipe$1);
  }
}

function insert_class_in_cache(cache, class$) {
  let $ = compute_properties(cache, class$.content, 2, "");
  let cache$1 = $[0];
  let properties = $[1];
  let class_ = (() => {
    let _pipe = class$.as_string;
    let _pipe$1 = compute_hash(_pipe);
    return compute_classes(_pipe$1, class$.name, properties);
  })();
  let _pipe = class_;
  let _pipe$1 = $pair.new$(_pipe, properties);
  let _pipe$2 = ((_capture) => {
    return $dict.insert(cache$1.cache, class$.as_string, _capture);
  })(_pipe$1);
  let _pipe$3 = ((cache_) => {
    let _record = cache$1;
    return new Cache(cache_, _record.at_rules);
  })(_pipe$2);
  return $pair.new$(_pipe$3, class_);
}

function computed_class(class$, cache) {
  return $bool.lazy_guard(
    $list.is_empty(class$.content),
    () => { return [cache, empty_computed()]; },
    () => {
      let existing_class = $dict.get(cache.cache, class$.as_string);
      if (existing_class.isOk()) {
        let class$1 = existing_class[0][0];
        return [cache, class$1];
      } else {
        return insert_class_in_cache(cache, class$);
      }
    },
  );
}

export function class_name(class$, cache) {
  let _pipe = computed_class(class$, cache);
  return $pair.map_second(_pipe, (class$) => { return class$.class_name; });
}

function handle_media(cache, props, media) {
  if (!(media instanceof Media)) {
    throw makeError(
      "let_assert",
      "sketch/internals/cache/cache",
      254,
      "handle_media",
      "Pattern match failed, no pattern matched the value.",
      { value: media }
    )
  }
  let query = media.query;
  let styles = media.styles;
  let indentation = props.indentation + 2;
  let $ = compute_properties(cache, styles, indentation, "");
  let cache$1 = $[0];
  let properties = $[1];
  let properties$1 = properties.properties;
  let selectors = properties.selectors;
  let _pipe = new MediaProperty(query, properties$1, selectors);
  let _pipe$1 = ((_capture) => { return $list.prepend(props.medias, _capture); })(
    _pipe,
  );
  let _pipe$2 = ((medias) => {
    let _record = props;
    return new Properties(
      _record.properties,
      medias,
      _record.selectors,
      _record.indentation,
    );
  })(_pipe$1);
  return ((_capture) => { return $pair.new$(cache$1, _capture); })(_pipe$2);
}

function handle_selector(cache, props, selector, existing_selector) {
  if (!(selector instanceof Selector)) {
    throw makeError(
      "let_assert",
      "sketch/internals/cache/cache",
      270,
      "handle_selector",
      "Pattern match failed, no pattern matched the value.",
      { value: selector }
    )
  }
  let selector$1 = selector.selector;
  let styles = selector.styles;
  let indentation = props.indentation + 2;
  let selector$2 = existing_selector + selector$1;
  let $ = compute_properties(cache, styles, indentation, selector$2);
  let cache$1 = $[0];
  let properties = $[1];
  let _pipe = new SelectorProperty(selector$2, properties.properties);
  let _pipe$1 = ((_capture) => {
    return $list.prepend(properties.selectors, _capture);
  })(_pipe);
  let _pipe$2 = $list.append(_pipe$1, props.selectors);
  let _pipe$3 = ((selectors) => {
    let _record = props;
    return new Properties(
      _record.properties,
      _record.medias,
      selectors,
      _record.indentation,
    );
  })(_pipe$2);
  return ((_capture) => { return $pair.new$(cache$1, _capture); })(_pipe$3);
}
