/// <reference types="./mime_types.d.mts" />
import * as $decode from "../gleam_stdlib/gleam/dynamic/decode.mjs";
import { Ok, Error, CustomType as $CustomType } from "./gleam.mjs";

export class Folder extends $CustomType {}

export class Document extends $CustomType {}

export class Docx extends $CustomType {}

export class Slides extends $CustomType {}

export class Sheet extends $CustomType {}

export class Drawing extends $CustomType {}

export class Map extends $CustomType {}

export class Xlsx extends $CustomType {}

export class Pptx extends $CustomType {}

export class Mp4 extends $CustomType {}

export class Png extends $CustomType {}

export class Zip extends $CustomType {}

export class Pdf extends $CustomType {}

export class XGzip extends $CustomType {}

export class XCompressed extends $CustomType {}

export class Jpeg extends $CustomType {}

export class XZipCompressed extends $CustomType {}

export class Csv extends $CustomType {}

export class Text extends $CustomType {}

export class Qna extends $CustomType {}

export class Markdown extends $CustomType {}

export function from_string(str) {
  if (str === "application/vnd.google-apps.folder") {
    return new Ok(new Folder());
  } else if (str === "application/vnd.google-apps.document") {
    return new Ok(new Document());
  } else if (str === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
    return new Ok(new Docx());
  } else if (str === "application/vnd.google-apps.presentation") {
    return new Ok(new Slides());
  } else if (str === "application/vnd.google-apps.spreadsheet") {
    return new Ok(new Sheet());
  } else if (str === "application/vnd.google-apps.drawing") {
    return new Ok(new Drawing());
  } else if (str === "application/vnd.google-apps.map") {
    return new Ok(new Map());
  } else if (str === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    return new Ok(new Xlsx());
  } else if (str === "application/vnd.openxmlformats-officedocument.presentationml.presentation") {
    return new Ok(new Pptx());
  } else if (str === "video/mp4") {
    return new Ok(new Mp4());
  } else if (str === "image/png") {
    return new Ok(new Png());
  } else if (str === "application/zip") {
    return new Ok(new Zip());
  } else if (str === "application/pdf") {
    return new Ok(new Pdf());
  } else if (str === "application/x-gzip") {
    return new Ok(new XGzip());
  } else if (str === "application/x-compressed") {
    return new Ok(new XCompressed());
  } else if (str === "image/jpeg") {
    return new Ok(new Jpeg());
  } else if (str === "application/x-zip-compressed") {
    return new Ok(new XZipCompressed());
  } else if (str === "text/csv") {
    return new Ok(new Csv());
  } else if (str === "text/steerlab-qna") {
    return new Ok(new Qna());
  } else if (str === "text/plain") {
    return new Ok(new Text());
  } else if (str === "text/markdown") {
    return new Ok(new Markdown());
  } else if (str === "text/x-markdown") {
    return new Ok(new Markdown());
  } else {
    return new Error(undefined);
  }
}

export function to_string(mime_type) {
  if (mime_type instanceof Folder) {
    return "application/vnd.google-apps.folder";
  } else if (mime_type instanceof Document) {
    return "application/vnd.google-apps.document";
  } else if (mime_type instanceof Slides) {
    return "application/vnd.google-apps.presentation";
  } else if (mime_type instanceof Sheet) {
    return "application/vnd.google-apps.spreadsheet";
  } else if (mime_type instanceof Drawing) {
    return "application/vnd.google-apps.drawing";
  } else if (mime_type instanceof Map) {
    return "application/vnd.google-apps.map";
  } else if (mime_type instanceof Docx) {
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  } else if (mime_type instanceof Xlsx) {
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  } else if (mime_type instanceof Pptx) {
    return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  } else if (mime_type instanceof Pdf) {
    return "application/pdf";
  } else if (mime_type instanceof Mp4) {
    return "video/mp4";
  } else if (mime_type instanceof Png) {
    return "image/png";
  } else if (mime_type instanceof Zip) {
    return "application/zip";
  } else if (mime_type instanceof XGzip) {
    return "application/x-gzip";
  } else if (mime_type instanceof XCompressed) {
    return "application/x-compressed";
  } else if (mime_type instanceof Jpeg) {
    return "image/jpeg";
  } else if (mime_type instanceof XZipCompressed) {
    return "application/x-zip-compressed";
  } else if (mime_type instanceof Csv) {
    return "text/csv";
  } else if (mime_type instanceof Text) {
    return "text/plain";
  } else if (mime_type instanceof Qna) {
    return "text/steerlab-qna";
  } else {
    return "text/markdown";
  }
}

export function decoder() {
  return $decode.then$(
    $decode.string,
    (content) => {
      let $ = from_string(content);
      if ($.isOk()) {
        let content$1 = $[0];
        return $decode.success(content$1);
      } else {
        return $decode.failure(new Zip(), "");
      }
    },
  );
}

export function is_processable(mime_type) {
  if (mime_type instanceof Xlsx) {
    return true;
  } else if (mime_type instanceof Docx) {
    return true;
  } else if (mime_type instanceof Pdf) {
    return true;
  } else if (mime_type instanceof Pptx) {
    return true;
  } else if (mime_type instanceof Markdown) {
    return true;
  } else {
    return false;
  }
}
