/// <reference types="./ingestion_control.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $checkbox from "../../ds/ds/components/checkbox.mjs";
import * as $label from "../../ds/ds/components/label.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $icons from "../../ds/ds/icons.mjs";
import * as $dict from "../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import * as $route from "../data/route.mjs";
import { toList, makeError } from "../gleam.mjs";
import * as $utils from "../utils.mjs";
import { stop_propagation_ as on_click } from "../utils.mjs";
import * as $content_libray from "../view/ingestion_control/content_libray.mjs";
import * as $inspect_source from "../view/ingestion_control/inspect_source.mjs";
import * as $s from "../view/styles/ingestion_control.mjs";

function content_library_menu(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let selected = data.content_library.selected;
  let selected_count = $dict.size(selected);
  let hidden = selected_count === 0;
  let content_library_count = $list.length(
    data.content_library.non_qna_sources.all,
  );
  let all_selected = content_library_count === selected_count;
  let check_state = (() => {
    if (all_selected) {
      return new $checkbox.Checked();
    } else if (!hidden) {
      return new $checkbox.Indeterminate();
    } else {
      return new $checkbox.Unchecked();
    }
  })();
  return $s.menu_wrapper(
    hidden,
    toList([]),
    toList([
      $s.menu(
        toList([]),
        toList([
          $layout.row(
            toList([$layout.gap(16)]),
            toList([]),
            toList([
              $checkbox.checkbox(
                check_state,
                (_) => {
                  return new $msg.UserClickedGlobalCheckbox(
                    all_selected,
                    data.content_library.non_qna_sources.all,
                  );
                },
              ),
              $s.number_selected(
                toList([]),
                toList([$h.text($int.to_string(selected_count) + " selected")]),
              ),
            ]),
          ),
          $s.change_button(
            toList([on_click(new $msg.UserToggledChangeStatusPopup())]),
            toList([
              $s.change_icon(false, $icons.checkmark()),
              $h.text("Change status"),
              $s.change_icon(
                !data.content_library.change_status_popup_opened,
                $icons.arrow_dropdown(),
              ),
              (() => {
                let $1 = data.content_library.change_status_popup_opened;
                if (!$1) {
                  return $el.none();
                } else {
                  return $s.change_status_popup(
                    toList([]),
                    toList([
                      $label.label(
                        toList([
                          $label.success(),
                          $label.on_click(
                            new $msg.UserAssignedStatus(
                              new $data_source.Verified(),
                            ),
                          ),
                          $label.content("Verified"),
                        ]),
                      ),
                      $label.label(
                        toList([
                          $label.current(),
                          $label.on_click(
                            new $msg.UserAssignedStatus(
                              new $data_source.Pending(),
                            ),
                          ),
                          $label.content("Pending"),
                        ]),
                      ),
                      $label.label(
                        toList([
                          $label.neutral(),
                          $label.on_click(
                            new $msg.UserAssignedStatus(
                              new $data_source.NotVerified(),
                            ),
                          ),
                          $label.content("Not verified"),
                        ]),
                      ),
                      $label.label(
                        toList([
                          $label.error(),
                          $label.on_click(
                            new $msg.UserAssignedStatus(
                              new $data_source.Outdated(),
                            ),
                          ),
                          $label.content("Outdated"),
                        ]),
                      ),
                    ]),
                  );
                }
              })(),
            ]),
          ),
        ]),
      ),
    ]),
  );
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let $1 = data.route;
  if (!($1 instanceof $route.ContentLibrary)) {
    throw makeError(
      "let_assert",
      "view/ingestion_control",
      22,
      "view",
      "Pattern match failed, no pattern matched the value.",
      { value: $1 }
    )
  }
  let route = $1[0];
  return $el.fragment(
    toList([
      (() => {
        if (route instanceof $route.DataSources) {
          return $content_libray.view(model);
        } else {
          let source_id = route.source_id;
          return $inspect_source.view(model, source_id);
        }
      })(),
      (() => {
        let _pipe = content_library_menu(model);
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
    ]),
  );
}
