/// <reference types="./length.d.mts" />
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";

class Px extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cm extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Mm extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Q extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class In extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Pc extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Pt extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Vh extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Vw extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Em extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Rem extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Lh extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Rlh extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Ch extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Pct extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cap extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Ex extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Ic extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Rcap extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Rch extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Rex extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Ric extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Vmax extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Vmin extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Vb extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Vi extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cqw extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cqh extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cqi extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cqb extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cqmin extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Cqmax extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function px(value) {
  return new Px($int.to_float(value));
}

export function px_(value) {
  return new Px(value);
}

export function cm(value) {
  return new Cm($int.to_float(value));
}

export function cm_(value) {
  return new Cm(value);
}

export function mm(value) {
  return new Mm($int.to_float(value));
}

export function mm_(value) {
  return new Mm(value);
}

export function q(value) {
  return new Q($int.to_float(value));
}

export function q_(value) {
  return new Q(value);
}

export function in$(value) {
  return new In($int.to_float(value));
}

export function in_(value) {
  return new In(value);
}

export function pc(value) {
  return new Pc($int.to_float(value));
}

export function pc_(value) {
  return new Pc(value);
}

export function pt(value) {
  return new Pt($int.to_float(value));
}

export function pt_(value) {
  return new Pt(value);
}

export function cap(value) {
  return new Cap(value);
}

export function percent(value) {
  return new Pct($int.to_float(value));
}

export function percent_(value) {
  return new Pct(value);
}

export function vh(value) {
  return new Vh($int.to_float(value));
}

export function vh_(value) {
  return new Vh(value);
}

export function vw(value) {
  return new Vw($int.to_float(value));
}

export function vw_(value) {
  return new Vw(value);
}

export function vmax(value) {
  return new Vmax($int.to_float(value));
}

export function vmax_(value) {
  return new Vmax(value);
}

export function vmin(value) {
  return new Vmin($int.to_float(value));
}

export function vmin_(value) {
  return new Vmin(value);
}

export function vb(value) {
  return new Vb($int.to_float(value));
}

export function vb_(value) {
  return new Vb(value);
}

export function vi(value) {
  return new Vi($int.to_float(value));
}

export function vi_(value) {
  return new Vi(value);
}

export function cqh(value) {
  return new Cqh($int.to_float(value));
}

export function cqh_(value) {
  return new Cqh(value);
}

export function cqw(value) {
  return new Cqw($int.to_float(value));
}

export function cqw_(value) {
  return new Cqw(value);
}

export function cqmax(value) {
  return new Cqmax($int.to_float(value));
}

export function cqmax_(value) {
  return new Cqmax(value);
}

export function cqmin(value) {
  return new Cqmin($int.to_float(value));
}

export function cqmin_(value) {
  return new Cqmin(value);
}

export function cqb(value) {
  return new Cqb($int.to_float(value));
}

export function cqb_(value) {
  return new Cqb(value);
}

export function cqi(value) {
  return new Cqi($int.to_float(value));
}

export function cqi_(value) {
  return new Cqi(value);
}

export function em(value) {
  return new Em(value);
}

export function ex(value) {
  return new Ex(value);
}

export function rex(value) {
  return new Rex(value);
}

export function ic(value) {
  return new Ic(value);
}

export function ric(value) {
  return new Ric(value);
}

export function rem(value) {
  return new Rem(value);
}

export function rch(value) {
  return new Rch(value);
}

export function rcap(value) {
  return new Rcap(value);
}

export function lh(value) {
  return new Lh(value);
}

export function rlh(value) {
  return new Rlh(value);
}

export function ch(value) {
  return new Ch(value);
}

export function to_string(size) {
  if (size instanceof Px) {
    let value = size[0];
    return $string.append($float.to_string(value), "px");
  } else if (size instanceof Pt) {
    let value = size[0];
    return $string.append($float.to_string(value), "pt");
  } else if (size instanceof Pct) {
    let value = size[0];
    return $string.append($float.to_string(value), "%");
  } else if (size instanceof Vh) {
    let value = size[0];
    return $string.append($float.to_string(value), "vh");
  } else if (size instanceof Vw) {
    let value = size[0];
    return $string.append($float.to_string(value), "vw");
  } else if (size instanceof Em) {
    let value = size[0];
    return $string.append($float.to_string(value), "em");
  } else if (size instanceof Rem) {
    let value = size[0];
    return $string.append($float.to_string(value), "rem");
  } else if (size instanceof Lh) {
    let value = size[0];
    return $string.append($float.to_string(value), "lh");
  } else if (size instanceof Rlh) {
    let value = size[0];
    return $string.append($float.to_string(value), "rlh");
  } else if (size instanceof Ch) {
    let value = size[0];
    return $string.append($float.to_string(value), "ch");
  } else if (size instanceof Cap) {
    let value = size[0];
    return $string.append($float.to_string(value), "cap");
  } else if (size instanceof Cm) {
    let value = size[0];
    return $string.append($float.to_string(value), "cm");
  } else if (size instanceof Cqb) {
    let value = size[0];
    return $string.append($float.to_string(value), "cqb");
  } else if (size instanceof Cqh) {
    let value = size[0];
    return $string.append($float.to_string(value), "cqh");
  } else if (size instanceof Cqi) {
    let value = size[0];
    return $string.append($float.to_string(value), "cqi");
  } else if (size instanceof Cqmax) {
    let value = size[0];
    return $string.append($float.to_string(value), "cqmax");
  } else if (size instanceof Cqmin) {
    let value = size[0];
    return $string.append($float.to_string(value), "cqmin");
  } else if (size instanceof Cqw) {
    let value = size[0];
    return $string.append($float.to_string(value), "cqw");
  } else if (size instanceof Ex) {
    let value = size[0];
    return $string.append($float.to_string(value), "ex");
  } else if (size instanceof Ic) {
    let value = size[0];
    return $string.append($float.to_string(value), "ic");
  } else if (size instanceof In) {
    let value = size[0];
    return $string.append($float.to_string(value), "in");
  } else if (size instanceof Mm) {
    let value = size[0];
    return $string.append($float.to_string(value), "mm");
  } else if (size instanceof Pc) {
    let value = size[0];
    return $string.append($float.to_string(value), "pc");
  } else if (size instanceof Q) {
    let value = size[0];
    return $string.append($float.to_string(value), "q");
  } else if (size instanceof Rcap) {
    let value = size[0];
    return $string.append($float.to_string(value), "rcap");
  } else if (size instanceof Rch) {
    let value = size[0];
    return $string.append($float.to_string(value), "rch");
  } else if (size instanceof Rex) {
    let value = size[0];
    return $string.append($float.to_string(value), "rex");
  } else if (size instanceof Ric) {
    let value = size[0];
    return $string.append($float.to_string(value), "ric");
  } else if (size instanceof Vb) {
    let value = size[0];
    return $string.append($float.to_string(value), "vb");
  } else if (size instanceof Vi) {
    let value = size[0];
    return $string.append($float.to_string(value), "vi");
  } else if (size instanceof Vmax) {
    let value = size[0];
    return $string.append($float.to_string(value), "vmax");
  } else {
    let value = size[0];
    return $string.append($float.to_string(value), "vmin");
  }
}
