/// <reference types="./theme.d.mts" />
import * as $level from "../../../grille_pain/toast/level.mjs";
import { isDark as is_dark } from "../../../theme.ffi.mjs";

export { is_dark };

export const info = "#3498db";

export const success = "#07bc0c";

export const warning = "#f1c40f";

export const error = "#e74c3c";

export const color_transparent = "rgba(255, 255, 255, 0.7)";

export const light_transparent = "rgb(0, 0, 0, 0.7)";

export const dark_transparent = "rgb(255, 255, 255, 0.7)";

export const dark = "#121212";

export const light = "#fff";

function standard() {
  let $ = is_dark();
  if ($) {
    return [dark, light];
  } else {
    return [light, dark];
  }
}

export function color(level) {
  if (level instanceof $level.Standard) {
    return standard();
  } else if (level instanceof $level.Info) {
    return [info, light];
  } else if (level instanceof $level.Success) {
    return [success, light];
  } else if (level instanceof $level.Warning) {
    return [warning, light];
  } else {
    return [error, light];
  }
}
