/// <reference types="./dropdown.d.mts" />
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px, vh } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import * as $shadows from "../../../ds/shadows.mjs";
import * as $text from "../../../ds/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function full_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.position("relative"),
      $css.width(percent(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.align_items("start"),
      $css.gap(px(6)),
      $css.width(percent(100)),
    ]),
  );
  return $h.label(_pipe, attrs, children);
}

function dropdown_base() {
  return $css.class$(
    toList([
      $css.compose($text.md($text.medium)),
      $css.appearance("none"),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.background($colors.light.white),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
      $css.gap(px(8)),
      $css.padding_("10px 14px"),
      $css.cursor("pointer"),
      $css.position("relative"),
      $css.transition("all .3s"),
      $css.white_space("nowrap"),
    ]),
  );
}

export function standard(input_class, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(dropdown_base()),
      (() => {
        let _pipe = input_class;
        let _pipe$1 = $option.map(_pipe, $css.compose);
        return $option.lazy_unwrap(_pipe$1, $css.none);
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function disabled(input_class, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(dropdown_base()),
      (() => {
        let _pipe = input_class;
        let _pipe$1 = $option.map(_pipe, $css.compose);
        return $option.lazy_unwrap(_pipe$1, $css.none);
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function active(input_class, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.box_shadow("0px 0px 0px 4px " + $colors.light.grey_100),
      $css.compose(dropdown_base()),
      (() => {
        let _pipe = input_class;
        let _pipe$1 = $option.map(_pipe, $css.compose);
        return $option.lazy_unwrap(_pipe$1, $css.none);
      })(),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function text_icon_wrapper(active, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(8)),
      $css.color(
        ("var(--text-color, " + (() => {
          if (active) {
            return $colors.light.onyx;
          } else {
            return $colors.light.grey_800;
          }
        })()) + ")",
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function choice_icon_wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(8)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function full_icon_wrapper(child) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.width(px(20)),
      $css.height(px(20)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function children_wrapper(panel_class, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.background($colors.light.white),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.box_shadow($shadows.m()),
      $css.border_radius(px(8)),
      $css.max_height(vh(30)),
      $css.min_width_("fit-content"),
      $css.overflow("auto"),
      $css.z_index(100_000_000_000),
      $css.compose(panel_class),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function choice_wrapper(class$, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.color($colors.light.onyx),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("space-between"),
      $css.padding_("10px 14px"),
      $css.gap(px(10)),
      $css.cursor("pointer"),
      $css.hover(toList([$css.background($colors.light.grey_200)])),
      (() => {
        let _pipe = class$;
        let _pipe$1 = $option.map(_pipe, $css.compose);
        return $option.lazy_unwrap(_pipe$1, $css.none);
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function icon_wrapper(attrs, children) {
  return $h.div(
    $css.class$(toList([$css.width(px(20)), $css.height(px(20))])),
    attrs,
    children,
  );
}

export function section_wrapper(class$, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.color($colors.light.grey_800),
      $css.position("sticky"),
      $css.top(px(0)),
      $css.padding(px(10)),
      $css.background($colors.light.white),
      $css.border_bottom("1px solid " + $colors.light.grey_400),
      (() => {
        let _pipe = class$;
        let _pipe$1 = $option.map(_pipe, $css.compose);
        return $option.lazy_unwrap(_pipe$1, $css.none);
      })(),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
