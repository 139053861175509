/// <reference types="./content_library.d.mts" />
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ask from "../../admin/ask.mjs";
import * as $middleware from "../../admin/middleware.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList } from "../../gleam.mjs";

export function fetch_content_library(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["content-library"]),
              );
              let _pipe$1 = $ask.bearing(_pipe, access_token);
              let _pipe$2 = $ask.expect(
                _pipe$1,
                $decode.list($data_source.decoder()),
              );
              let _pipe$3 = $ask.notify(
                _pipe$2,
                (d) => {
                  return dispatch(
                    new $msg.ContentLibrary(new $msg.ApiReturnedSources(d)),
                  );
                },
              );
              $ask.run(_pipe$3)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function update_data_sources_status(data, status) {
  return $middleware.require_access_token(
    (access_token) => {
      let data_sources = $content_library.extract_selected_source_ids(
        data.content_library.selected,
      );
      return $effect.from(
        (dispatch) => {
          let at = toList(["content-library"]);
          let _pipe = $ask.to(new $ask.Heimdall(), at);
          let _pipe$1 = $ask.bearing(_pipe, access_token);
          let _pipe$2 = $ask.via(_pipe$1, new $http.Patch());
          let _pipe$3 = $ask.expect(_pipe$2, $data_source.decoder());
          let _pipe$4 = $ask.with$(
            _pipe$3,
            $json.object(
              toList([
                ["status", $json.string($data_source.status_to_string(status))],
                ["data_source_ids", $json.array(data_sources, $json.string)],
              ]),
            ),
          );
          let _pipe$5 = $ask.notify(
            _pipe$4,
            (s) => {
              return dispatch(
                new $msg.ContentLibrary(new $msg.ApiReturnedSource(s)),
              );
            },
          );
          $ask.run(_pipe$5)
          return undefined;
        },
      );
    },
  );
}
