/// <reference types="./label.d.mts" />
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../ds/colors.mjs";
import * as $text from "../../ds/typography/text.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

class StatusContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class ContentContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class IconContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnClickContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class SizeContent extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class Success extends $CustomType {}

export class Error extends $CustomType {}

export class Current extends $CustomType {}

export class Neutral extends $CustomType {}

export class Medium extends $CustomType {}

export class Large extends $CustomType {}

class Attr extends $CustomType {
  constructor(status, content, icon, on_click, size) {
    super();
    this.status = status;
    this.content = content;
    this.icon = icon;
    this.on_click = on_click;
    this.size = size;
  }
}

export function status(status) {
  return new StatusContent(status);
}

export function success() {
  return new StatusContent(new Success());
}

export function error() {
  return new StatusContent(new Error());
}

export function current() {
  return new StatusContent(new Current());
}

export function neutral() {
  return new StatusContent(new Neutral());
}

export function size(size) {
  return new SizeContent(size);
}

export function medium() {
  return new SizeContent(new Medium());
}

export function large() {
  return new SizeContent(new Large());
}

export function content(content) {
  return new ContentContent(content);
}

export function icon(icon) {
  return new IconContent(icon);
}

export function on_click(on_click) {
  return new OnClickContent(on_click);
}

function status_to_colors(status) {
  if (status instanceof Success) {
    return [$colors.light.success_100, $colors.light.success_800];
  } else if (status instanceof Error) {
    return [$colors.light.error_100, $colors.light.error_800];
  } else if (status instanceof Neutral) {
    return [$colors.light.grey_200, $colors.light.grey_800];
  } else {
    return [$colors.light.blue_100, $colors.light.blue_800];
  }
}

function label_class() {
  return $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.padding_("4px 12px"),
      $css.border_radius(px(4)),
      $css.text_align("center"),
      $css.white_space("nowrap"),
    ]),
  );
}

function large_class() {
  return $css.class$(
    toList([$css.compose($text.lg($text.medium)), $css.padding_("8px 24px")]),
  );
}

function clickable_class(color) {
  return $css.class$(
    toList([
      $css.cursor("pointer"),
      $css.border("1px solid transparent"),
      $css.transition("border-color .3s"),
      $css.hover(toList([$css.property("border-color", color)])),
    ]),
  );
}

function to_attrs(attributes) {
  let attrs = new Attr(
    new None(),
    new None(),
    new None(),
    new None(),
    new None(),
  );
  return $list.fold(
    attributes,
    attrs,
    (attrs, attr) => {
      if (attr instanceof ContentContent) {
        let c = attr[0];
        let _record = attrs;
        return new Attr(
          _record.status,
          new Some(c),
          _record.icon,
          _record.on_click,
          _record.size,
        );
      } else if (attr instanceof IconContent) {
        let i = attr[0];
        let _record = attrs;
        return new Attr(
          _record.status,
          _record.content,
          new Some(i),
          _record.on_click,
          _record.size,
        );
      } else if (attr instanceof OnClickContent) {
        let o = attr[0];
        let _record = attrs;
        return new Attr(
          _record.status,
          _record.content,
          _record.icon,
          new Some(o),
          _record.size,
        );
      } else if (attr instanceof StatusContent) {
        let s = attr[0];
        let _record = attrs;
        return new Attr(
          new Some(s),
          _record.content,
          _record.icon,
          _record.on_click,
          _record.size,
        );
      } else {
        let s = attr[0];
        let _record = attrs;
        return new Attr(
          _record.status,
          _record.content,
          _record.icon,
          _record.on_click,
          new Some(s),
        );
      }
    },
  );
}

export function label(attributes) {
  let attrs = to_attrs(attributes);
  let status$1 = $option.unwrap(attrs.status, new Neutral());
  let $ = status_to_colors(status$1);
  let background = $[0];
  let color = $[1];
  let icon$1 = $option.lazy_unwrap(attrs.icon, $el.none);
  let content$1 = $option.unwrap(attrs.content, $string.inspect(status$1));
  let size$1 = $option.unwrap(attrs.size, new Medium());
  let $1 = (() => {
    let $2 = attrs.on_click;
    if ($2 instanceof None) {
      return [$h.div, $css.none(), $a.none()];
    } else {
      let o = $2[0];
      return [$h.button, $css.compose(clickable_class(color)), $e.on_click(o)];
    }
  })();
  let node = $1[0];
  let click_class = $1[1];
  let handler = $1[2];
  let _pipe = $css.class$(
    toList([
      $css.compose(label_class()),
      $css.background(background),
      $css.color(color),
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.gap(px(10)),
      click_class,
      (() => {
        if (size$1 instanceof Medium) {
          return $css.none();
        } else {
          return $css.compose(large_class());
        }
      })(),
      $css.text_transform("capitalize"),
    ]),
  );
  return node(_pipe, toList([handler]), toList([icon$1, $h.text(content$1)]));
}
