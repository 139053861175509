/// <reference types="./msg.d.mts" />
import * as $auth0 from "../../auth0_client/auth0/client.mjs";
import * as $auth0_user from "../../auth0_client/auth0/client/user.mjs";
import * as $ai_config from "../../common/data/ai_config.mjs";
import * as $data_point from "../../common/data/data_point.mjs";
import * as $data_source from "../../common/data/data_source.mjs";
import * as $qualification_matrix from "../../common/data/qualification_matrix.mjs";
import * as $tag from "../../common/data/tag.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $uri from "../../gleam_stdlib/gleam/uri.mjs";
import * as $level from "../../grille_pain/grille_pain/toast/level.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class ApplicationStoredAccessToken extends $CustomType {}

export class ApplicationDisplayedToast extends $CustomType {
  constructor(level, message) {
    super();
    this.level = level;
    this.message = message;
  }
}

export class ApplicationChangedRoute extends $CustomType {
  constructor(route) {
    super();
    this.route = route;
  }
}

export class Auth0 extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AuthStateChanged extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ContentLibrary extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class AiConfig extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class ApiReturnedAiSearchResults extends $CustomType {
  constructor(ai_search_results) {
    super();
    this.ai_search_results = ai_search_results;
  }
}

export class OrganizationSetup extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class UserClosedModal extends $CustomType {}

export class UserDisplayedReIngestDataModal extends $CustomType {}

export class UserDisplayedReRunInsightsModal extends $CustomType {}

export class UserReIngestedData extends $CustomType {}

export class UserReRanInsights extends $CustomType {}

export class UserUpdatedSearchInput extends $CustomType {
  constructor(content) {
    super();
    this.content = content;
  }
}

export class UserSubmittedAiSearch extends $CustomType {}

export class ApiFetchedDataPoints extends $CustomType {
  constructor(source_id, data_points) {
    super();
    this.source_id = source_id;
    this.data_points = data_points;
  }
}

export class ApiReturnedSource extends $CustomType {
  constructor(source) {
    super();
    this.source = source;
  }
}

export class ApiReturnedSources extends $CustomType {
  constructor(sources) {
    super();
    this.sources = sources;
  }
}

export class SwitchedVisbility extends $CustomType {
  constructor(source_id) {
    super();
    this.source_id = source_id;
  }
}

export class UserAssignedStatus extends $CustomType {
  constructor(status) {
    super();
    this.status = status;
  }
}

export class UserClickedGlobalCheckbox extends $CustomType {
  constructor(all_selected, sources) {
    super();
    this.all_selected = all_selected;
    this.sources = sources;
  }
}

export class UserClickedOnFetchDataPoints extends $CustomType {
  constructor(source_id) {
    super();
    this.source_id = source_id;
  }
}

export class UserClickedOnSourceInspect extends $CustomType {
  constructor(source_id) {
    super();
    this.source_id = source_id;
  }
}

export class UserClickedOnSourcePreview extends $CustomType {
  constructor(source_id) {
    super();
    this.source_id = source_id;
  }
}

export class UserSelectedSource extends $CustomType {
  constructor(id, checked) {
    super();
    this.id = id;
    this.checked = checked;
  }
}

export class UserToggledChangeStatusPopup extends $CustomType {}

export class UserResetStyleGuidelines extends $CustomType {}

export class ApiReturnedGeneratedStyleGuidelines extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApiReturnedAiConfig extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApiSavedAiConfig extends $CustomType {
  constructor(ai_config) {
    super();
    this.ai_config = ai_config;
  }
}

export class UserClickedAddPiiVocabulary extends $CustomType {}

export class UserRemovedPiiVocabulary extends $CustomType {
  constructor(index) {
    super();
    this.index = index;
  }
}

export class UserSubmittedAiConfig extends $CustomType {}

export class UserSubmittedQuestionsAnswersExamples extends $CustomType {}

export class UserSubmittedStyleGuidelines extends $CustomType {}

export class UserWroteQuestionsAnswersExamples extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserWroteStyleGuidelines extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedAiSystem extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedLlmLimit extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedPiiVocabulary extends $CustomType {
  constructor(index, value) {
    super();
    this.index = index;
    this.value = value;
  }
}

export class UserUpdatedQueryLimit extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedQueryAlpha extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedTranslateOn extends $CustomType {
  constructor(bool) {
    super();
    this.bool = bool;
  }
}

export class UserUpdatedModel extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class UserUpdatedRetrieverOption extends $CustomType {
  constructor(value) {
    super();
    this.value = value;
  }
}

export class ApiReturnedTags extends $CustomType {
  constructor(tags) {
    super();
    this.tags = tags;
  }
}

export class ApiReturnedQualificationMatrix extends $CustomType {
  constructor(qm_rows) {
    super();
    this.qm_rows = qm_rows;
  }
}

export class ApiSavedDefaults extends $CustomType {
  constructor(ai_config, tags, qm_rows) {
    super();
    this.ai_config = ai_config;
    this.tags = tags;
    this.qm_rows = qm_rows;
  }
}

export class UserClickedSetup extends $CustomType {}

export class UserRemovedTag extends $CustomType {
  constructor(tag) {
    super();
    this.tag = tag;
  }
}

export class UserSubmittedTag extends $CustomType {}

export class UserUpdatedTagInput extends $CustomType {
  constructor(tag_input) {
    super();
    this.tag_input = tag_input;
  }
}
