/// <reference types="./tag.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Tag extends $CustomType {
  constructor(id, name, org_id) {
    super();
    this.id = id;
    this.name = name;
    this.org_id = org_id;
  }
}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "name",
        $decode.string,
        (name) => {
          return $decode.field(
            "org_id",
            $decode.string,
            (org_id) => { return $decode.success(new Tag(id, name, org_id)); },
          );
        },
      );
    },
  );
}

export function encode(tag) {
  return $json.object(
    toList([
      ["id", $json.string(tag.id)],
      ["name", $json.string(tag.name)],
      ["org_id", $json.string(tag.org_id)],
    ]),
  );
}
