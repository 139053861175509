/// <reference types="./input.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dynamic from "../../../gleam_stdlib/gleam/dynamic.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import { blurTarget as blur_target } from "../../ds.ffi.mjs";
import * as $s from "../../ds/internals/styles/input.mjs";
import * as $gleam from "../../gleam.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";

class Model extends $CustomType {
  constructor(label, placeholder, type_, disabled, error, value, icon, on_input, on_submit, on_blur, multiline, id, invisible, enter_submit) {
    super();
    this.label = label;
    this.placeholder = placeholder;
    this.type_ = type_;
    this.disabled = disabled;
    this.error = error;
    this.value = value;
    this.icon = icon;
    this.on_input = on_input;
    this.on_submit = on_submit;
    this.on_blur = on_blur;
    this.multiline = multiline;
    this.id = id;
    this.invisible = invisible;
    this.enter_submit = enter_submit;
  }
}

class Label extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Placeholder extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Type extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Disabled extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Error extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Value extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Icon extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnInput extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnBlur extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnSubmit extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Multiline extends $CustomType {}

class Invisible extends $CustomType {}

class Id extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class EnterSubmit extends $CustomType {}

function attributes_to_model(attrs) {
  return $list.fold(
    attrs,
    new Model(
      new $option.None(),
      new $option.None(),
      "text",
      new $option.None(),
      new $option.None(),
      "",
      new $option.None(),
      new $option.None(),
      new $option.None(),
      new $option.None(),
      false,
      new $option.None(),
      false,
      false,
    ),
    (all, attr) => {
      if (attr instanceof Label) {
        let content = attr[0];
        let _record = all;
        return new Model(
          new $option.Some(content),
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Placeholder) {
        let content = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          new $option.Some(content),
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Type) {
        let content = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          content,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Disabled) {
        let content = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          new $option.Some(content),
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Error) {
        let content = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          new $option.Some(content),
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Value) {
        let content = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          content,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Icon) {
        let content = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          new $option.Some(content),
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof OnInput) {
        let handler = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          new $option.Some(handler),
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof OnSubmit) {
        let handler = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          new $option.Some(handler),
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Multiline) {
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          true,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      } else if (attr instanceof Invisible) {
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          true,
          _record.enter_submit,
        );
      } else if (attr instanceof EnterSubmit) {
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          _record.id,
          _record.invisible,
          true,
        );
      } else if (attr instanceof Id) {
        let id$1 = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          _record.on_blur,
          _record.multiline,
          new $option.Some(id$1),
          _record.invisible,
          _record.enter_submit,
        );
      } else {
        let handler = attr[0];
        let _record = all;
        return new Model(
          _record.label,
          _record.placeholder,
          _record.type_,
          _record.disabled,
          _record.error,
          _record.value,
          _record.icon,
          _record.on_input,
          _record.on_submit,
          new $option.Some(handler),
          _record.multiline,
          _record.id,
          _record.invisible,
          _record.enter_submit,
        );
      }
    },
  );
}

export function label(text) {
  return new Label(text);
}

export function placeholder(text) {
  return new Placeholder(text);
}

export function type_(text) {
  return new Type(text);
}

export function disabled(value) {
  return new Disabled(value);
}

export function error(value) {
  return new Error(value);
}

export function value(value) {
  return new Value(value);
}

export function icon(value) {
  return new Icon(value);
}

export function on_input(handler) {
  return new OnInput(handler);
}

export function on_submit(handler) {
  return new OnSubmit(handler);
}

export function on_blur(handler) {
  return new OnBlur(handler);
}

export function multiline() {
  return new Multiline();
}

export function invisible() {
  return new Invisible();
}

export function enter_submit() {
  return new EnterSubmit();
}

export function id(id) {
  return new Id(id);
}

function select_input_class(model) {
  return $bool.guard(
    model.invisible,
    $s.invisible,
    () => {
      let $ = model.multiline;
      let $1 = model.disabled;
      let $2 = model.error;
      if (!$ && $1 instanceof $option.Some && $1[0]) {
        return $s.disabled;
      } else if ($ && $1 instanceof $option.Some && $1[0]) {
        return $s.disabled_multiline;
      } else if (!$ && $2 instanceof $option.Some) {
        return $s.error;
      } else if ($ && $2 instanceof $option.Some) {
        return $s.error_multiline;
      } else if (!$) {
        return $s.standard;
      } else {
        return $s.standard_multiline;
      }
    },
  );
}

function on_input_submit(model) {
  let capture_events = model.value !== "";
  let $ = model.on_submit;
  if ($ instanceof $option.Some && (capture_events)) {
    let handler = $[0];
    return $e.on_click(handler);
  } else {
    return $a.none();
  }
}

function input_icon(model) {
  let $ = model.icon;
  if ($ instanceof $option.None) {
    return $el.none();
  } else {
    let icon$1 = $[0];
    let capture_events = model.value !== "";
    return $s.icon_display_wrapper(
      capture_events,
      toList([on_input_submit(model)]),
      toList([$s.icon_display(toList([]), toList([icon$1]))]),
    );
  }
}

function on_keydown_decoder(model) {
  return $decode.field(
    "metaKey",
    $decode.bool,
    (meta) => {
      return $decode.field(
        "key",
        $decode.string,
        (key) => {
          let $ = model.enter_submit;
          if (key === "Enter" && $) {
            return $decode.success(true);
          } else if (key === "Enter" && !$ && meta) {
            return $decode.success(true);
          } else {
            return $decode.success(false);
          }
        },
      );
    },
  );
}

function on_keydown(model) {
  let $ = model.on_submit;
  if ($ instanceof $option.None) {
    return $a.none();
  } else {
    let msg = $[0];
    return $e.on(
      "keydown",
      (event) => {
        let decoded = $decode.run(event, on_keydown_decoder(model));
        return $result.then$(
          $result.replace_error(decoded, toList([])),
          (success) => {
            return $bool.guard(
              !success,
              new $gleam.Error(toList([])),
              () => {
                blur_target(event);
                return new $gleam.Ok(msg);
              },
            );
          },
        );
      },
    );
  }
}

function view_error(model) {
  let $ = model.error;
  if ($ instanceof $option.None) {
    return $el.none();
  } else {
    let error$1 = $[0];
    return $s.error_label(toList([]), toList([$el.text(error$1)]));
  }
}

function unwrap_attribute(attribute, mapper) {
  let _pipe = attribute;
  let _pipe$1 = $option.map(_pipe, mapper);
  return $option.lazy_unwrap(_pipe$1, $a.none);
}

function view_content(model) {
  let input$1 = select_input_class(model);
  let display_icon = $option.is_some(model.icon);
  return $s.input_wrapper(
    toList([]),
    toList([
      input$1(
        display_icon,
        toList([
          $a.type_(model.type_),
          $a.value(model.value),
          on_keydown(model),
          unwrap_attribute(model.disabled, $a.disabled),
          unwrap_attribute(model.id, $a.id),
          unwrap_attribute(model.on_input, $e.on_input),
          unwrap_attribute(model.placeholder, $a.placeholder),
          unwrap_attribute(model.on_blur, $e.on_blur),
          (() => {
            let $ = model.multiline;
            if ($) {
              return $a.rows(5);
            } else {
              return $a.none();
            }
          })(),
        ]),
      ),
      input_icon(model),
    ]),
  );
}

function view(model) {
  return $s.wrapper(
    toList([]),
    (() => {
      let $ = model.label;
      if ($ instanceof $option.None) {
        return toList([view_content(model), view_error(model)]);
      } else {
        let label$1 = $[0];
        return toList([
          $el.text(label$1),
          view_content(model),
          view_error(model),
        ]);
      }
    })(),
  );
}

export function input(attrs) {
  let model = attributes_to_model(attrs);
  return view(model);
}
