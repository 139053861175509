/// <reference types="./transform.d.mts" />
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $float from "../../../gleam_stdlib/gleam/float.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import { toList, CustomType as $CustomType } from "../../gleam.mjs";
import * as $angle from "../../sketch/css/angle.mjs";
import * as $length from "../../sketch/css/length.mjs";

class Matrix extends $CustomType {
  constructor(x0, x1, x2, x3, x4, x5) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
    this[3] = x3;
    this[4] = x4;
    this[5] = x5;
  }
}

class Matrix3D extends $CustomType {
  constructor(x0, x1, x2, x3, x4, x5, x6, x7, x8, x9, x10, x11, x12, x13, x14, x15) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
    this[3] = x3;
    this[4] = x4;
    this[5] = x5;
    this[6] = x6;
    this[7] = x7;
    this[8] = x8;
    this[9] = x9;
    this[10] = x10;
    this[11] = x11;
    this[12] = x12;
    this[13] = x13;
    this[14] = x14;
    this[15] = x15;
  }
}

class Translate extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

class Translate3D extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

class TranslateX extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class TranslateY extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class TranslateZ extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Scale extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

class Scale3D extends $CustomType {
  constructor(x0, x1, x2) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
  }
}

class ScaleX extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class ScaleY extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class ScaleZ extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Rotate extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Rotate3D extends $CustomType {
  constructor(x0, x1, x2, x3) {
    super();
    this[0] = x0;
    this[1] = x1;
    this[2] = x2;
    this[3] = x3;
  }
}

class RotateX extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class RotateY extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class RotateZ extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Skew extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

class SkewX extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class SkewY extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Perspective extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function matrix(a, b, c, d, tx, ty) {
  return new Matrix(a, b, c, d, tx, ty);
}

export function matrix_3d(values) {
  let a1 = values[0][0];
  let b1 = values[0][1];
  let c1 = values[0][2];
  let d1 = values[0][3];
  let a2 = values[1][0];
  let b2 = values[1][1];
  let c2 = values[1][2];
  let d2 = values[1][3];
  let a3 = values[2][0];
  let b3 = values[2][1];
  let c3 = values[2][2];
  let d3 = values[2][3];
  let a4 = values[3][0];
  let b4 = values[3][1];
  let c4 = values[3][2];
  let d4 = values[3][3];
  return new Matrix3D(
    a1,
    b1,
    c1,
    d1,
    a2,
    b2,
    c2,
    d2,
    a3,
    b3,
    c3,
    d3,
    a4,
    b4,
    c4,
    d4,
  );
}

export function translate(x, y) {
  return new Translate(x, y);
}

export function translate_3d(x, y, z) {
  return new Translate3D(x, y, z);
}

export function translate_x(x) {
  return new TranslateX(x);
}

export function translate_y(y) {
  return new TranslateY(y);
}

export function translate_z(y) {
  return new TranslateZ(y);
}

export function scale(x, y) {
  return new Scale(x, y);
}

export function scale_3d(x, y, z) {
  return new Scale3D(x, y, z);
}

export function scale_x(x) {
  return new ScaleX(x);
}

export function scale_y(y) {
  return new ScaleY(y);
}

export function scale_z(y) {
  return new ScaleZ(y);
}

export function rotate(value) {
  return new Rotate(value);
}

export function rotate_3d(x, y, z, value) {
  return new Rotate3D(x, y, z, value);
}

export function rotate_x(x) {
  return new RotateX(x);
}

export function rotate_y(y) {
  return new RotateY(y);
}

export function rotate_z(y) {
  return new RotateZ(y);
}

export function skew(x, y) {
  return new Skew(x, y);
}

export function skew_x(x) {
  return new SkewX(x);
}

export function skew_y(x) {
  return new SkewY(x);
}

export function perspective(x) {
  return new Perspective(x);
}

function transform_to_string(value) {
  if (value instanceof Matrix) {
    let a = value[0];
    let b = value[1];
    let c = value[2];
    let d = value[3];
    let tx = value[4];
    let ty = value[5];
    let _pipe = toList([a, b, c, d, tx, ty]);
    let _pipe$1 = $list.map(_pipe, $float.to_string);
    let _pipe$2 = $string.join(_pipe$1, ", ");
    return ((matrix) => { return ("matrix(" + matrix) + ")"; })(_pipe$2);
  } else if (value instanceof Matrix3D) {
    let a = value[0];
    let b = value[1];
    let c = value[2];
    let d = value[3];
    let e = value[4];
    let f = value[5];
    let g = value[6];
    let h = value[7];
    let i = value[8];
    let j = value[9];
    let k = value[10];
    let l = value[11];
    let m = value[12];
    let n = value[13];
    let o = value[14];
    let p = value[15];
    let _pipe = toList([a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p]);
    let _pipe$1 = $list.map(_pipe, $float.to_string);
    let _pipe$2 = $string.join(_pipe$1, ", ");
    return ((matrix) => { return ("matrix3d(" + matrix) + ")"; })(_pipe$2);
  } else if (value instanceof Translate) {
    let x = value[0];
    let y = value[1];
    let x$1 = $length.to_string(x);
    let y$1 = $length.to_string(y);
    return ("translate(" + $string.join(toList([x$1, y$1]), ", ")) + ")";
  } else if (value instanceof Translate3D) {
    let x = value[0];
    let y = value[1];
    let z = value[2];
    let x$1 = $length.to_string(x);
    let y$1 = $length.to_string(y);
    let z$1 = $length.to_string(z);
    return ("translate3d(" + $string.join(toList([x$1, y$1, z$1]), ", ")) + ")";
  } else if (value instanceof TranslateX) {
    let x = value[0];
    return ("translateX(" + $length.to_string(x)) + ")";
  } else if (value instanceof TranslateY) {
    let y = value[0];
    return ("translateY(" + $length.to_string(y)) + ")";
  } else if (value instanceof TranslateZ) {
    let z = value[0];
    return ("translateZ(" + $length.to_string(z)) + ")";
  } else if (value instanceof Scale) {
    let x = value[0];
    let y = value[1];
    let x$1 = $float.to_string(x);
    let y$1 = $float.to_string(y);
    return ("scale(" + $string.join(toList([x$1, y$1]), ", ")) + ")";
  } else if (value instanceof Scale3D) {
    let x = value[0];
    let y = value[1];
    let z = value[2];
    let x$1 = $float.to_string(x);
    let y$1 = $float.to_string(y);
    let z$1 = $float.to_string(z);
    return ("scale3d(" + $string.join(toList([x$1, y$1, z$1]), ", ")) + ")";
  } else if (value instanceof ScaleX) {
    let x = value[0];
    return ("scaleX(" + $float.to_string(x)) + ")";
  } else if (value instanceof ScaleY) {
    let y = value[0];
    return ("scaleY(" + $float.to_string(y)) + ")";
  } else if (value instanceof ScaleZ) {
    let z = value[0];
    return ("scaleZ(" + $float.to_string(z)) + ")";
  } else if (value instanceof Rotate) {
    let ang = value[0];
    return ("rotate(" + $angle.to_string(ang)) + ")";
  } else if (value instanceof Rotate3D) {
    let x = value[0];
    let y = value[1];
    let z = value[2];
    let ang = value[3];
    let x$1 = $float.to_string(x);
    let y$1 = $float.to_string(y);
    let z$1 = $float.to_string(z);
    let angle = $angle.to_string(ang);
    return ("rotate3d(" + $string.join(toList([x$1, y$1, z$1, angle]), ", ")) + ")";
  } else if (value instanceof RotateX) {
    let x = value[0];
    return ("rotateX(" + $angle.to_string(x)) + ")";
  } else if (value instanceof RotateY) {
    let y = value[0];
    return ("rotateY(" + $angle.to_string(y)) + ")";
  } else if (value instanceof RotateZ) {
    let z = value[0];
    return ("rotateZ(" + $angle.to_string(z)) + ")";
  } else if (value instanceof Skew) {
    let x = value[0];
    let y = value[1];
    let x$1 = $angle.to_string(x);
    let y$1 = $angle.to_string(y);
    return ("skew(" + $string.join(toList([x$1, y$1]), ", ")) + ")";
  } else if (value instanceof SkewX) {
    let x = value[0];
    return ("skewX(" + $angle.to_string(x)) + ")";
  } else if (value instanceof SkewY) {
    let y = value[0];
    return ("skewY(" + $angle.to_string(y)) + ")";
  } else {
    let x = value[0];
    return ("perspective(" + $length.to_string(x)) + ")";
  }
}

export function to_string(value) {
  return $bool.guard(
    $list.is_empty(value),
    "none",
    () => {
      let _pipe = $list.map(value, transform_to_string);
      return $string.join(_pipe, " ");
    },
  );
}
