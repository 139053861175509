/// <reference types="./automations.d.mts" />
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ask from "../../admin/ask.mjs";
import * as $middleware from "../../admin/middleware.mjs";
import * as $data from "../../data/model/data.mjs";
import { toList } from "../../gleam.mjs";

export function rerun_insights(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (_) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["rerun-insights"]),
              );
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              $ask.run(_pipe$2)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function reingest_data(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (_) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["reingest-data"]),
              );
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              $ask.run(_pipe$2)
              return undefined;
            },
          );
        },
      );
    },
  );
}
