/// <reference types="./utils.d.mts" />
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $event from "../../lustre/lustre/event.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import * as $el from "../../sketch_lustre_experimental/sketch/lustre/experimental/element.mjs";
import { platform } from "../ds.ffi.mjs";
import * as $brands from "../ds/brands.mjs";
import * as $logo from "../ds/logo.mjs";
import * as $outline from "../ds/outline.mjs";
import { Error, toList } from "../gleam.mjs";

export { platform };

export function stop_propagation() {
  return $event.on(
    "click",
    (event) => {
      $event.stop_propagation(event);
      return new Error(toList([]));
    },
  );
}

export function is_mac() {
  let _pipe = platform();
  let _pipe$1 = $result.unwrap(_pipe, "");
  let _pipe$2 = $string.lowercase(_pipe$1);
  return $string.contains(_pipe$2, "mac");
}

export function select_icon(mime_type) {
  if (mime_type instanceof $mime_types.Document) {
    return $brands.drive_icons.docs();
  } else if (mime_type instanceof $mime_types.Docx) {
    return $brands.office.word();
  } else if (mime_type instanceof $mime_types.Pdf) {
    return $brands.pdf();
  } else if (mime_type instanceof $mime_types.Sheet) {
    return $brands.drive_icons.sheets();
  } else if (mime_type instanceof $mime_types.Xlsx) {
    return $brands.office.excel();
  } else if (mime_type instanceof $mime_types.Pptx) {
    return $brands.office.powerpoint();
  } else if (mime_type instanceof $mime_types.Csv) {
    return $outline.file_list();
  } else if (mime_type instanceof $mime_types.Qna) {
    return $logo.round();
  } else if (mime_type instanceof $mime_types.Markdown) {
    return $brands.markdown();
  } else {
    return $el.none();
  }
}
