/// <reference types="./login.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px, vh } from "../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function wrapper(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.height(vh(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function background(attrs) {
  let _pipe = $css.class$(
    toList([
      $css.position("fixed"),
      $css.z_index(-1),
      $css.bottom(px(0)),
      $css.right(px(0)),
    ]),
  );
  return $h.img(_pipe, attrs);
}

export function topbar(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(12)),
      $css.padding(px(24)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function topbar_logo(attrs, children) {
  let _pipe = $css.class$(toList([$css.width(px(35))]));
  return $h.div(_pipe, attrs, children);
}

export function topbar_name(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.md())]));
  return $h.span(_pipe, attrs, children);
}

export function body(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.md($text.regular)),
      $css.flex("1"),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(36)),
      $css.justify_content("center"),
      $css.align_items("center"),
      $css.max_width(px(500)),
      $css.margin_("0 auto"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function body_glass(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.background("#fff7"),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(48)),
      $css.padding(px(48)),
      $css.border_radius(px(20)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.property("backdrop_filter", "blur(16px)"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function body_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.lg())]));
  return $h.div(_pipe, attrs, children);
}

export function thanks(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.compose($display.sm()), $css.text_align("right")]),
  );
  return $h.div(_pipe, attrs, children);
}
