/// <reference types="./ai_config.d.mts" />
import * as $ai_config from "../../../common/data/ai_config.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ask from "../../admin/ask.mjs";
import * as $middleware from "../../admin/middleware.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList } from "../../gleam.mjs";

export function fetch_ai_config(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["ai-config"]));
              let _pipe$1 = $ask.via(_pipe, new $http.Get());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.expect(
                _pipe$2,
                $decode.at(
                  toList(["ai_config"]),
                  $decode.optional($ai_config.decoder()),
                ),
              );
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (c) => {
                  return dispatch(
                    new $msg.AiConfig(new $msg.ApiReturnedAiConfig(c)),
                  );
                },
              );
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function set_ai_config(data, ai_config) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["ai-config"]));
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(_pipe$2, $ai_config.encode(ai_config));
              let _pipe$4 = $ask.expect(
                _pipe$3,
                $decode.optional($ai_config.decoder()),
              );
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (value) => {
                  return dispatch(
                    new $msg.AiConfig(new $msg.ApiSavedAiConfig(value)),
                  );
                },
              );
              $ask.run(_pipe$5)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function send_style_guidelines_generation_request(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $middleware.require_org_id(
            data,
            (org_id) => {
              return $middleware.require_user_id(
                data,
                (user_id) => {
                  return $effect.from(
                    (dispatch) => {
                      let _pipe = $ask.to(
                        new $ask.Nabu(),
                        toList(["style-guidelines"]),
                      );
                      let _pipe$1 = $ask.via(_pipe, new $http.Post());
                      let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                      let _pipe$3 = $ask.with$(
                        _pipe$2,
                        $json.object(
                          toList([
                            ["org_id", $json.string(org_id)],
                            ["user_id", $json.string(user_id)],
                            [
                              "content",
                              $json.string(data.questions_answers_examples),
                            ],
                          ]),
                        ),
                      );
                      let _pipe$4 = $ask.expect(_pipe$3, $decode.string);
                      let _pipe$5 = $ask.notify(
                        _pipe$4,
                        (sg) => {
                          return dispatch(
                            new $msg.AiConfig(
                              new $msg.ApiReturnedGeneratedStyleGuidelines(sg),
                            ),
                          );
                        },
                      );
                      $ask.run(_pipe$5)
                      return undefined;
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}
