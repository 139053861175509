/// <reference types="./keyframe.d.mts" />
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";
import * as $style from "../../sketch/internals/cache/cache.mjs";

class Keyframe extends $CustomType {
  constructor(class$) {
    super();
    this.class = class$;
  }
}

export function from(styles) {
  return new Keyframe($style.named("from", styles));
}

export function to(styles) {
  return new Keyframe($style.named("to", styles));
}

export function at(percentage, styles) {
  return new Keyframe($style.named($int.to_string(percentage) + "%", styles));
}

export function class$(keyframe) {
  return keyframe.class;
}
