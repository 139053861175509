/// <reference types="./automations.d.mts" />
import * as $button from "../../ds/ds/components/button.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $h from "../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import { toList } from "../gleam.mjs";

function re_run_insights_view(_) {
  return $layout.row(
    toList([$layout.gap(8), $layout.align("center")]),
    toList([]),
    toList([
      $h.text(
        "Be careful! Re-running the insights for this organization will squash the existing insights.",
      ),
      $button.primary(
        toList([$e.on_click(new $msg.UserDisplayedReRunInsightsModal())]),
        toList([$h.text("Re-run insights")]),
      ),
    ]),
  );
}

function re_ingest_view(_) {
  return $layout.row(
    toList([$layout.gap(8), $layout.align("center")]),
    toList([]),
    toList([
      $h.text(
        "This will re-ingest and upgrade the version of all data both in PG and weaviate.",
      ),
      $button.primary(
        toList([$e.on_click(new $msg.UserDisplayedReIngestDataModal())]),
        toList([$h.text("Re ingest data")]),
      ),
    ]),
  );
}

export function view(model) {
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(32)]),
        toList([]),
        toList([re_run_insights_view(model), re_ingest_view(model)]),
      ),
    ]),
  );
}
