/// <reference types="./brands.d.mts" />
import * as $chrome from "../ds/brands/chrome.mjs";
import * as $confluence from "../ds/brands/confluence.mjs";
import * as $drive from "../ds/brands/drive.mjs";
import * as $drive_icons from "../ds/brands/drive_icons.mjs";
import * as $markdown from "../ds/brands/markdown.mjs";
import * as $notion from "../ds/brands/notion.mjs";
import * as $office from "../ds/brands/office.mjs";
import * as $onedrive from "../ds/brands/onedrive.mjs";
import * as $pdf from "../ds/brands/pdf.mjs";
import * as $salesforce from "../ds/brands/salesforce.mjs";
import * as $slack from "../ds/brands/slack.mjs";
import * as $teams from "../ds/brands/teams.mjs";

export const chrome = $chrome.chrome;

export const confluence = $confluence.confluence;

export const notion = $notion.notion;

export const drive = $drive.drive;

export const drive_icons = /* @__PURE__ */ new $drive_icons.DriveIcons(
  $drive_icons.docs,
  $drive_icons.sheets,
);

export const office = /* @__PURE__ */ new $office.Office(
  $office.word,
  $office.excel,
  $office.onenote,
  $office.powerpoint,
  $office.teams,
  $office.yammer,
  $office.sharepoint,
  $office.outlook,
);

export const onedrive = $onedrive.onedrive;

export const pdf = $pdf.pdf;

export const salesforce = $salesforce.salesforce;

export const slack = $slack.slack;

export const teams = $teams.teams;

export const markdown = $markdown.markdown;
