/// <reference types="./month.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $calendar from "../../../../gleam_time/gleam/time/calendar.mjs";
import { Ok, Error } from "../../../gleam.mjs";

export function to_string(month) {
  if (month instanceof $calendar.January) {
    return "January";
  } else if (month instanceof $calendar.February) {
    return "February";
  } else if (month instanceof $calendar.March) {
    return "March";
  } else if (month instanceof $calendar.April) {
    return "April";
  } else if (month instanceof $calendar.May) {
    return "May";
  } else if (month instanceof $calendar.June) {
    return "June";
  } else if (month instanceof $calendar.July) {
    return "July";
  } else if (month instanceof $calendar.August) {
    return "August";
  } else if (month instanceof $calendar.September) {
    return "September";
  } else if (month instanceof $calendar.October) {
    return "October";
  } else if (month instanceof $calendar.November) {
    return "November";
  } else {
    return "December";
  }
}

export function to_short_string(month) {
  if (month instanceof $calendar.January) {
    return "Jan";
  } else if (month instanceof $calendar.February) {
    return "Feb";
  } else if (month instanceof $calendar.March) {
    return "Mar";
  } else if (month instanceof $calendar.April) {
    return "Apr";
  } else if (month instanceof $calendar.May) {
    return "May";
  } else if (month instanceof $calendar.June) {
    return "Jun";
  } else if (month instanceof $calendar.July) {
    return "Jul";
  } else if (month instanceof $calendar.August) {
    return "Aug";
  } else if (month instanceof $calendar.September) {
    return "Sep";
  } else if (month instanceof $calendar.October) {
    return "Oct";
  } else if (month instanceof $calendar.November) {
    return "Nov";
  } else {
    return "Dec";
  }
}

export function to_int(month) {
  if (month instanceof $calendar.January) {
    return 1;
  } else if (month instanceof $calendar.February) {
    return 2;
  } else if (month instanceof $calendar.March) {
    return 3;
  } else if (month instanceof $calendar.April) {
    return 4;
  } else if (month instanceof $calendar.May) {
    return 5;
  } else if (month instanceof $calendar.June) {
    return 6;
  } else if (month instanceof $calendar.July) {
    return 7;
  } else if (month instanceof $calendar.August) {
    return 8;
  } else if (month instanceof $calendar.September) {
    return 9;
  } else if (month instanceof $calendar.October) {
    return 10;
  } else if (month instanceof $calendar.November) {
    return 11;
  } else {
    return 12;
  }
}

export function from_input(month) {
  let $ = $int.parse(month);
  if ($.isOk() && $[0] === 1) {
    return new Ok(new $calendar.January());
  } else if ($.isOk() && $[0] === 2) {
    return new Ok(new $calendar.February());
  } else if ($.isOk() && $[0] === 3) {
    return new Ok(new $calendar.March());
  } else if ($.isOk() && $[0] === 4) {
    return new Ok(new $calendar.April());
  } else if ($.isOk() && $[0] === 5) {
    return new Ok(new $calendar.May());
  } else if ($.isOk() && $[0] === 6) {
    return new Ok(new $calendar.June());
  } else if ($.isOk() && $[0] === 7) {
    return new Ok(new $calendar.July());
  } else if ($.isOk() && $[0] === 8) {
    return new Ok(new $calendar.August());
  } else if ($.isOk() && $[0] === 9) {
    return new Ok(new $calendar.September());
  } else if ($.isOk() && $[0] === 10) {
    return new Ok(new $calendar.October());
  } else if ($.isOk() && $[0] === 11) {
    return new Ok(new $calendar.November());
  } else if ($.isOk() && $[0] === 12) {
    return new Ok(new $calendar.December());
  } else {
    return new Error(undefined);
  }
}
