/// <reference types="./error.d.mts" />
import * as $fetch from "../../gleam_fetch/gleam/fetch.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";

export class FetchError extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class DecodeError extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export class NilError extends $CustomType {
  constructor(message) {
    super();
    this.message = message;
  }
}
