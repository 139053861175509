/// <reference types="./organization_setup.d.mts" />
import * as $tag from "../../common/data/tag.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $organization_setup from "../admin/effects/organization_setup.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";

function set_tags(data, tags) {
  let _record = data;
  return new $data.Data(
    _record.access_token,
    _record.client,
    _record.content_library,
    _record.ingestion_control_sources,
    _record.ingestion_control_sources_visibility,
    _record.ai_search_results,
    _record.ai_search_input,
    _record.display_modal,
    _record.loading,
    _record.permissions,
    _record.route,
    _record.user,
    _record.users,
    _record.style_guidelines_input,
    _record.questions_answers_examples,
    _record.ai_config,
    tags,
    _record.tag_input,
    _record.qm_rows,
  );
}

function set_tag_input(data, tag_input) {
  let _record = data;
  return new $data.Data(
    _record.access_token,
    _record.client,
    _record.content_library,
    _record.ingestion_control_sources,
    _record.ingestion_control_sources_visibility,
    _record.ai_search_results,
    _record.ai_search_input,
    _record.display_modal,
    _record.loading,
    _record.permissions,
    _record.route,
    _record.user,
    _record.users,
    _record.style_guidelines_input,
    _record.questions_answers_examples,
    _record.ai_config,
    _record.tags,
    tag_input,
    _record.qm_rows,
  );
}

export function update(data, msg) {
  if (msg instanceof $msg.ApiSavedDefaults) {
    let ai_config = msg.ai_config;
    let tags = msg.tags;
    let qm_rows = msg.qm_rows;
    return [
      (() => {
        let _record = data;
        return new $data.Data(
          _record.access_token,
          _record.client,
          _record.content_library,
          _record.ingestion_control_sources,
          _record.ingestion_control_sources_visibility,
          _record.ai_search_results,
          _record.ai_search_input,
          _record.display_modal,
          _record.loading,
          _record.permissions,
          _record.route,
          _record.user,
          _record.users,
          _record.style_guidelines_input,
          _record.questions_answers_examples,
          ai_config,
          tags,
          _record.tag_input,
          qm_rows,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof $msg.ApiReturnedTags) {
    let tags = msg.tags;
    return [
      (() => {
        let _record = data;
        return new $data.Data(
          _record.access_token,
          _record.client,
          _record.content_library,
          _record.ingestion_control_sources,
          _record.ingestion_control_sources_visibility,
          _record.ai_search_results,
          _record.ai_search_input,
          _record.display_modal,
          _record.loading,
          _record.permissions,
          _record.route,
          _record.user,
          _record.users,
          _record.style_guidelines_input,
          _record.questions_answers_examples,
          _record.ai_config,
          tags,
          _record.tag_input,
          _record.qm_rows,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof $msg.ApiReturnedQualificationMatrix) {
    let qm_rows = msg.qm_rows;
    return [
      (() => {
        let _record = data;
        return new $data.Data(
          _record.access_token,
          _record.client,
          _record.content_library,
          _record.ingestion_control_sources,
          _record.ingestion_control_sources_visibility,
          _record.ai_search_results,
          _record.ai_search_input,
          _record.display_modal,
          _record.loading,
          _record.permissions,
          _record.route,
          _record.user,
          _record.users,
          _record.style_guidelines_input,
          _record.questions_answers_examples,
          _record.ai_config,
          _record.tags,
          _record.tag_input,
          qm_rows,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof $msg.UserClickedSetup) {
    return [data, $organization_setup.set_defaults(data)];
  } else if (msg instanceof $msg.UserRemovedTag) {
    let tag = msg.tag;
    let _pipe = data.tags;
    let _pipe$1 = $list.filter(_pipe, (t) => { return t.name !== tag; });
    let _pipe$2 = ((_capture) => { return set_tags(data, _capture); })(_pipe$1);
    return $pair.new$(_pipe$2, $organization_setup.remove_tag(data, tag));
  } else if (msg instanceof $msg.UserSubmittedTag) {
    return [set_tag_input(data, ""), $organization_setup.add_tag(data)];
  } else {
    let tag_input = msg.tag_input;
    return [set_tag_input(data, tag_input), $effect.none()];
  }
}
