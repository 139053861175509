/// <reference types="./style_guidelines.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $button from "../../ds/ds/components/button.mjs";
import * as $input from "../../ds/ds/components/input.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $msg from "../data/msg.mjs";
import { toList } from "../gleam.mjs";
import * as $s from "../view/styles/style_guidelines.mjs";

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let style_guidelines = $option.then$(
    data.ai_config,
    (a) => { return a.config.style_guidelines; },
  );
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(24)]),
        toList([]),
        toList([
          $s.section_title(
            toList([]),
            toList([$h.text("Set style guidelines")]),
          ),
          $h.div(
            toList([]),
            toList([
              $h.text("You can directly input the styling guidelines here:"),
            ]),
          ),
          $input.input(
            toList([
              $input.value(data.style_guidelines_input),
              $input.on_input(
                (var0) => { return new $msg.UserWroteStyleGuidelines(var0); },
              ),
              $input.on_submit(new $msg.UserSubmittedStyleGuidelines()),
              $input.multiline(),
            ]),
          ),
          $h.div(
            toList([]),
            toList([
              $h.text("You can also copy/paste a list of questions/answers:"),
            ]),
          ),
          $input.input(
            toList([
              $input.value(data.questions_answers_examples),
              $input.on_input(
                (var0) => {
                  return new $msg.UserWroteQuestionsAnswersExamples(var0);
                },
              ),
              $input.on_submit(new $msg.UserSubmittedQuestionsAnswersExamples()),
              $input.multiline(),
            ]),
          ),
          $h.div(
            toList([]),
            toList([
              $button.primary(
                toList([$e.on_click(new $msg.UserResetStyleGuidelines())]),
                toList([$h.text("Reset style guidelines")]),
              ),
            ]),
          ),
          $s.section_title(
            toList([]),
            toList([$h.text("Current style guidelines")]),
          ),
          $h.div(
            toList([$a.style(toList([["white-space", "pre-line"]]))]),
            toList([
              $h.text(
                (() => {
                  if (style_guidelines instanceof $option.Some) {
                    let text = style_guidelines[0];
                    return text;
                  } else {
                    return "No guidelines were found.";
                  }
                })(),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
