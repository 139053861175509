/// <reference types="./common.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $data_point from "../../../common/data/data_point.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $model from "../../data/model.mjs";
import { toList } from "../../gleam.mjs";
import * as $ic_s from "../../view/styles/ingestion_control.mjs";

function construct_data_point_string(data_point) {
  let $ = data_point.display;
  if ($ instanceof $data_point.DisplayQa) {
    let question = $.question;
    let answer = $.answer;
    let sheet_name = $.sheet_name;
    let header = $.header;
    let section = $.section;
    let sub_section = $.sub_section;
    let context_question = $.context_question;
    let comments = $.comments;
    let _pipe = "Sheet name: ";
    let _pipe$1 = $string.append(_pipe, sheet_name);
    let _pipe$2 = $string.append(_pipe$1, "\n Header: ");
    let _pipe$3 = $string.append(_pipe$2, $option.unwrap(header, ""));
    let _pipe$4 = $string.append(_pipe$3, "\n Section: ");
    let _pipe$5 = $string.append(_pipe$4, $option.unwrap(section, ""));
    let _pipe$6 = $string.append(_pipe$5, "\n Sub Section: ");
    let _pipe$7 = $string.append(_pipe$6, $option.unwrap(sub_section, ""));
    let _pipe$8 = $string.append(_pipe$7, "\n Context Question: ");
    let _pipe$9 = $string.append(_pipe$8, $option.unwrap(context_question, ""));
    let _pipe$10 = $string.append(_pipe$9, "\n Comments: ");
    let _pipe$11 = $string.append(_pipe$10, $option.unwrap(comments, ""));
    let _pipe$12 = $string.append(_pipe$11, "\n Question: ");
    let _pipe$13 = $string.append(_pipe$12, question);
    let _pipe$14 = $string.append(_pipe$13, "\n Answer: ");
    return $string.append(_pipe$14, answer);
  } else {
    let as_html = $.as_html;
    return $option.unwrap(as_html, "");
  }
}

export function preview_source(model, source_id, limit) {
  let $ = $bright.unwrap(model);
  let model$1 = $[0];
  let data_points = (() => {
    let _pipe = model$1.ingestion_control_sources;
    return $dict.get(_pipe, source_id);
  })();
  if (data_points.isOk()) {
    let data_points$1 = data_points[0];
    let visible_data_points = (() => {
      if (limit instanceof Some) {
        let limit$1 = limit[0];
        let _pipe = data_points$1;
        return $list.take(_pipe, limit$1);
      } else {
        return data_points$1;
      }
    })();
    let elements = (() => {
      let _pipe = visible_data_points;
      return $list.map(
        _pipe,
        (dp) => {
          return $ic_s.data_point(
            $layout.column(
              toList([$layout.gap(4)]),
              toList([]),
              toList([
                $h.div(
                  toList([]),
                  toList([$h.text(construct_data_point_string(dp))]),
                ),
                (() => {
                  let $1 = dp.display;
                  if ($1 instanceof $data_point.DisplayText) {
                    let page_number = $1.page_number;
                    return $ic_s.page_number(
                      $h.text("p." + $int.to_string(page_number)),
                    );
                  } else {
                    return $el.none();
                  }
                })(),
              ]),
            ),
          );
        },
      );
    })();
    return $el.fragment(elements);
  } else {
    return $el.none();
  }
}
