/// <reference types="./data_point.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class DataPoint extends $CustomType {
  constructor(id, source_id, display, kind, text, status) {
    super();
    this.id = id;
    this.source_id = source_id;
    this.display = display;
    this.kind = kind;
    this.text = text;
    this.status = status;
  }
}

export class Text extends $CustomType {}

export class Qa extends $CustomType {}

export class Valid extends $CustomType {}

export class Outdated extends $CustomType {}

export class DisplayText extends $CustomType {
  constructor(as_html, page_number) {
    super();
    this.as_html = as_html;
    this.page_number = page_number;
  }
}

export class DisplayQa extends $CustomType {
  constructor(as_html, question, answer, row_number, sheet_name, header, section, sub_section, context_question, comments) {
    super();
    this.as_html = as_html;
    this.question = question;
    this.answer = answer;
    this.row_number = row_number;
    this.sheet_name = sheet_name;
    this.header = header;
    this.section = section;
    this.sub_section = sub_section;
    this.context_question = context_question;
    this.comments = comments;
  }
}

export function id_decoder() {
  return $decode.field(
    "data_point_id",
    $decode.optional($decode.string),
    (a) => {
      return $decode.field(
        "score",
        $decode.optional($decrypt.float()),
        (b) => {
          if (a instanceof $option.Some && b instanceof $option.Some) {
            let a$1 = a[0];
            let b$1 = b[0];
            return $decode.success(new $option.Some([a$1, b$1]));
          } else {
            return $decode.success(new $option.None());
          }
        },
      );
    },
  );
}

export function ids_decoder() {
  let _pipe = $decode.list(id_decoder());
  let _pipe$1 = $decode.map(_pipe, $option.values);
  return $decode.map(
    _pipe$1,
    (_capture) => {
      return $list.sort(
        _capture,
        (a, b) => { return $float.compare(b[1], a[1]); },
      );
    },
  );
}

function display_text_decoder() {
  return $decode.field(
    "as_html",
    $decode.optional($decode.string),
    (as_html) => {
      return $decode.field(
        "page_number",
        $decode.int,
        (page_number) => {
          return $decode.success(new DisplayText(as_html, page_number));
        },
      );
    },
  );
}

function display_qa_decoder() {
  return $decode.field(
    "as_html",
    $decode.optional($decode.string),
    (as_html) => {
      return $decode.field(
        "question",
        $decode.string,
        (question) => {
          return $decode.field(
            "answer",
            $decode.string,
            (answer) => {
              return $decode.field(
                "row_number",
                $decode.int,
                (row_number) => {
                  return $decode.field(
                    "sheet_name",
                    $decode.string,
                    (sheet_name) => {
                      return $decrypt.optional_field(
                        "header",
                        $decode.string,
                        (header) => {
                          return $decrypt.optional_field(
                            "section",
                            $decode.string,
                            (section) => {
                              return $decrypt.optional_field(
                                "sub_section",
                                $decode.string,
                                (sub_section) => {
                                  return $decrypt.optional_field(
                                    "context_question",
                                    $decode.string,
                                    (context_question) => {
                                      return $decrypt.optional_field(
                                        "comments",
                                        $decode.string,
                                        (comments) => {
                                          let _pipe = new DisplayQa(
                                            as_html,
                                            question,
                                            answer,
                                            row_number,
                                            sheet_name,
                                            header,
                                            section,
                                            sub_section,
                                            context_question,
                                            comments,
                                          );
                                          return $decode.success(_pipe);
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function display_decoder() {
  let text = display_text_decoder();
  let qa = display_qa_decoder();
  return $decode.one_of(text, toList([qa]));
}

function kind_decoder() {
  return $decode.then$(
    $decode.string,
    (kind) => {
      let expected = "text | qa";
      if (kind === "text") {
        return $decode.success(new Text());
      } else if (kind === "qa") {
        return $decode.success(new Qa());
      } else {
        return $decode.failure(new Text(), expected);
      }
    },
  );
}

function status_decoder() {
  return $decode.then$(
    $decode.string,
    (status) => {
      if (status === "valid") {
        return $decode.success(new Valid());
      } else if (status === "outdated") {
        return $decode.success(new Outdated());
      } else {
        return $decode.failure(new Valid(), "Status");
      }
    },
  );
}

export function decoder() {
  return $decode.field(
    "id",
    $decode.string,
    (id) => {
      return $decode.field(
        "source_id",
        $decode.string,
        (source_id) => {
          return $decode.field(
            "display",
            $decrypt.json(display_decoder()),
            (display) => {
              return $decode.field(
                "kind",
                kind_decoder(),
                (kind) => {
                  return $decode.field(
                    "text",
                    $decode.string,
                    (text) => {
                      return $decode.field(
                        "status",
                        status_decoder(),
                        (status) => {
                          return $decode.success(
                            new DataPoint(
                              id,
                              source_id,
                              display,
                              kind,
                              text,
                              status,
                            ),
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function encode_display(display) {
  if (display instanceof DisplayText) {
    let as_html = display.as_html;
    let page_number = display.page_number;
    return $json.object(
      toList([
        ["as_html", $json.nullable(as_html, $json.string)],
        ["page_number", $json.int(page_number)],
      ]),
    );
  } else {
    let as_html = display.as_html;
    let question = display.question;
    let answer = display.answer;
    let row_number = display.row_number;
    let sheet_name = display.sheet_name;
    let header = display.header;
    let section = display.section;
    let sub_section = display.sub_section;
    let context_question = display.context_question;
    let comments = display.comments;
    return $json.object(
      toList([
        ["as_html", $json.nullable(as_html, $json.string)],
        ["question", $json.string(question)],
        ["answer", $json.string(answer)],
        ["row_number", $json.int(row_number)],
        ["sheet_name", $json.string(sheet_name)],
        ["header", $json.nullable(header, $json.string)],
        ["section", $json.nullable(section, $json.string)],
        ["sub_section", $json.nullable(sub_section, $json.string)],
        ["context_question", $json.nullable(context_question, $json.string)],
        ["comments", $json.nullable(comments, $json.string)],
      ]),
    );
  }
}

function encode_kind(kind) {
  if (kind instanceof Text) {
    return $json.string("text");
  } else {
    return $json.string("qa");
  }
}

function encode_status(status) {
  if (status instanceof Valid) {
    return $json.string("valid");
  } else {
    return $json.string("outdated");
  }
}

export function encode(data_point) {
  return $json.object(
    toList([
      ["id", $json.string(data_point.id)],
      ["source_id", $json.string(data_point.source_id)],
      ["display", encode_display(data_point.display)],
      ["kind", encode_kind(data_point.kind)],
      ["text", $json.string(data_point.text)],
      ["status", encode_status(data_point.status)],
    ]),
  );
}
