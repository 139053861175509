/// <reference types="./toast.d.mts" />
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $lustre from "../../lustre/lustre.mjs";
import { CustomType as $CustomType } from "../gleam.mjs";
import * as $msg from "../grille_pain/internals/data/msg.mjs";
import * as $effect_manager from "../grille_pain/internals/effect_manager.mjs";
import * as $level from "../grille_pain/toast/level.mjs";
import { uuid } from "../toast.ffi.mjs";

class Options extends $CustomType {
  constructor(timeout, level, sticky) {
    super();
    this.timeout = timeout;
    this.level = level;
    this.sticky = sticky;
  }
}

export function options() {
  return new Options(new None(), new None(), false);
}

export function timeout(options, timeout) {
  let _record = options;
  return new Options(new Some(timeout), _record.level, _record.sticky);
}

export function sticky(options) {
  let _record = options;
  return new Options(_record.timeout, _record.level, true);
}

export function level(options, level) {
  let _record = options;
  return new Options(_record.timeout, new Some(level), _record.sticky);
}

export function hide(id) {
  return $effect_manager.call(
    (dispatch) => {
      let _pipe = new $msg.ExternalHide(id);
      let _pipe$1 = $lustre.dispatch(_pipe);
      return dispatch(_pipe$1);
    },
  );
}

function dispatch_toast(options, message) {
  return $function.tap(
    uuid(),
    (uuid) => {
      return $effect_manager.call(
        (dispatch) => {
          let timeout$1 = options.timeout;
          let level$1 = options.level;
          let sticky$1 = options.sticky;
          let level$2 = $option.unwrap(level$1, new $level.Standard());
          let _pipe = new $msg.New(uuid, message, level$2, timeout$1, sticky$1);
          let _pipe$1 = $lustre.dispatch(_pipe);
          return dispatch(_pipe$1);
        },
      );
    },
  );
}

export function info(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Info());
  return dispatch_toast(_pipe$1, content);
}

export function success(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Success());
  return dispatch_toast(_pipe$1, content);
}

export function error(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Error());
  return dispatch_toast(_pipe$1, content);
}

export function toast(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Standard());
  return dispatch_toast(_pipe$1, content);
}

export function warning(content) {
  let _pipe = options();
  let _pipe$1 = level(_pipe, new $level.Warning());
  return dispatch_toast(_pipe$1, content);
}

export function custom(options, content) {
  return dispatch_toast(options, content);
}
