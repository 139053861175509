/// <reference types="./model.d.mts" />
import * as $birl from "../../../../birl/birl.mjs";
import * as $duration from "../../../../birl/birl/duration.mjs";
import { Duration } from "../../../../birl/birl/duration.mjs";
import * as $list from "../../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../../gleam_stdlib/gleam/option.mjs";
import { toList, prepend as listPrepend, CustomType as $CustomType, divideInt } from "../../../gleam.mjs";
import * as $toast from "../../../grille_pain/internals/data/toast.mjs";
import { Toast } from "../../../grille_pain/internals/data/toast.mjs";
import * as $shadow from "../../../grille_pain/internals/shadow.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

export class Model extends $CustomType {
  constructor(toasts, id, timeout, root) {
    super();
    this.toasts = toasts;
    this.id = id;
    this.timeout = timeout;
    this.root = root;
  }
}

export function new$(root, timeout) {
  let toasts = toList([]);
  let id = 0;
  return new Model(toasts, id, timeout, root);
}

export function add(model, external_id, message, level, sticky, timeout) {
  let timeout$1 = $option.unwrap(timeout, model.timeout);
  let new_toast = $toast.new$(
    external_id,
    model.id,
    message,
    level,
    timeout$1,
    sticky,
    model.root,
  );
  let new_toasts = listPrepend(new_toast, model.toasts);
  let new_id = model.id + 1;
  let _record = model;
  return new Model(new_toasts, new_id, _record.timeout, _record.root);
}

function update_toast(model, id, updater) {
  let toasts = $list.map(
    model.toasts,
    (toast) => {
      let $ = id === toast.id;
      if ($) {
        return updater(toast);
      } else {
        return toast;
      }
    },
  );
  let _record = model;
  return new Model(toasts, _record.id, _record.timeout, _record.root);
}

export function show(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $birl.utc_now();
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        true,
        true,
        _record.remaining,
        now,
        _record.iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function hide(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        false,
        _record.running,
        _record.remaining,
        _record.last_schedule,
        _record.iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function decrease_bottom(model, id) {
  let new_toasts = $list.map(
    model.toasts,
    (toast) => {
      let $ = toast.displayed;
      let $1 = toast.id > id;
      if ($ && $1) {
        let bottom = $toast.compute_size(id, model.root);
        let bottom$1 = toast.bottom - bottom;
        let _record = toast;
        return new Toast(
          _record.external_id,
          _record.id,
          _record.sticky,
          _record.message,
          _record.displayed,
          _record.running,
          _record.remaining,
          _record.last_schedule,
          _record.iteration,
          bottom$1,
          _record.level,
          _record.animation_duration,
        );
      } else {
        return toast;
      }
    },
  );
  let _record = model;
  return new Model(new_toasts, _record.id, _record.timeout, _record.root);
}

export function stop(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $birl.utc_now();
      let $ = $birl.difference(now, toast.last_schedule);
      let elapsed_time = $[0];
      let remaining = toast.remaining - (divideInt(elapsed_time, 1000));
      let iteration = toast.iteration + 1;
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        _record.displayed,
        false,
        remaining,
        _record.last_schedule,
        iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function resume(model, id) {
  return update_toast(
    model,
    id,
    (toast) => {
      let now = $birl.utc_now();
      let _record = toast;
      return new Toast(
        _record.external_id,
        _record.id,
        _record.sticky,
        _record.message,
        _record.displayed,
        true,
        _record.remaining,
        now,
        _record.iteration,
        _record.bottom,
        _record.level,
        _record.animation_duration,
      );
    },
  );
}

export function remove(model, id) {
  let new_toasts = $list.filter(
    model.toasts,
    (toast) => { return toast.id !== id; },
  );
  let _record = model;
  return new Model(new_toasts, _record.id, _record.timeout, _record.root);
}
