/// <reference types="./ask.d.mts" />
import * as $endpoints from "../../app_config/app_config/endpoints.mjs";
import * as $sentry from "../../common/sentry.mjs";
import * as $fetch from "../../gleam_fetch/gleam/fetch.mjs";
import * as $form_data from "../../gleam_fetch/gleam/fetch/form_data.mjs";
import * as $http from "../../gleam_http/gleam/http.mjs";
import * as $request from "../../gleam_http/gleam/http/request.mjs";
import * as $promise from "../../gleam_javascript/gleam/javascript/promise.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $function from "../../gleam_stdlib/gleam/function.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $error from "../admin/error.mjs";
import { toList, CustomType as $CustomType, makeError } from "../gleam.mjs";
import * as $utils from "../utils.mjs";

export class Loki extends $CustomType {}

export class Heimdall extends $CustomType {}

export class Nabu extends $CustomType {}

export class Hermes extends $CustomType {}

class Json extends $CustomType {
  constructor(content) {
    super();
    this.content = content;
  }
}

class Blob extends $CustomType {
  constructor(content) {
    super();
    this.content = content;
  }
}

class FormData extends $CustomType {
  constructor(content) {
    super();
    this.content = content;
  }
}

class Ask extends $CustomType {
  constructor(service, url, method, access_token, body, queries, decoder, notify, notify_error, finally$, before) {
    super();
    this.service = service;
    this.url = url;
    this.method = method;
    this.access_token = access_token;
    this.body = body;
    this.queries = queries;
    this.decoder = decoder;
    this.notify = notify;
    this.notify_error = notify_error;
    this.finally = finally$;
    this.before = before;
  }
}

function prepend_slash(url) {
  return $bool.guard(
    $string.starts_with(url, "/"),
    url,
    () => { return "/" + url; },
  );
}

export function to(service, url) {
  let access_token = new $option.None();
  let body = new $option.None();
  let queries = toList([]);
  let decoder = $decode.dynamic;
  let notify$1 = new $option.None();
  let notify_error$1 = new $option.None();
  let finally$1 = new $option.None();
  let before$1 = new $option.None();
  return new Ask(
    service,
    prepend_slash(
      (() => {
        let _pipe = url;
        return $utils.to_path(_pipe);
      })(),
    ),
    new $http.Get(),
    access_token,
    body,
    queries,
    decoder,
    notify$1,
    notify_error$1,
    finally$1,
    before$1,
  );
}

export function bearing(ask, access_token) {
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    new $option.Some(access_token),
    _record.body,
    _record.queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    _record.finally,
    _record.before,
  );
}

export function expect(ask, decoder) {
  return new Ask(
    ask.service,
    ask.url,
    ask.method,
    ask.access_token,
    ask.body,
    ask.queries,
    decoder,
    new $option.None(),
    ask.notify_error,
    ask.finally,
    ask.before,
  );
}

export function with$(ask, content) {
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    _record.access_token,
    new $option.Some(new Json(content)),
    _record.queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    _record.finally,
    _record.before,
  );
}

export function gift(ask, content) {
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    _record.access_token,
    new $option.Some(new Blob(content)),
    _record.queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    _record.finally,
    _record.before,
  );
}

export function data(ask, form_data) {
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    _record.access_token,
    new $option.Some(new FormData(form_data)),
    _record.queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    _record.finally,
    _record.before,
  );
}

export function query(ask, key, value) {
  let queries = $list.key_set(ask.queries, key, value);
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    _record.access_token,
    _record.body,
    queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    _record.finally,
    _record.before,
  );
}

export function via(ask, method) {
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    method,
    _record.access_token,
    _record.body,
    _record.queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    _record.finally,
    _record.before,
  );
}

export function notify(ask, msg) {
  let notify$1 = new $option.Some((a) => { return msg(a); });
  return new Ask(
    ask.service,
    ask.url,
    ask.method,
    ask.access_token,
    ask.body,
    ask.queries,
    ask.decoder,
    notify$1,
    ask.notify_error,
    ask.finally,
    ask.before,
  );
}

export function error(ask, notify_error) {
  let notify_error$1 = new $option.Some(notify_error);
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    _record.access_token,
    _record.body,
    _record.queries,
    _record.decoder,
    _record.notify,
    notify_error$1,
    _record.finally,
    _record.before,
  );
}

export function before(ask, before) {
  let before$1 = new $option.Some(before);
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    _record.access_token,
    _record.body,
    _record.queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    _record.finally,
    before$1,
  );
}

export function finally$(ask, finally$) {
  let finally$1 = new $option.Some(finally$);
  let _record = ask;
  return new Ask(
    _record.service,
    _record.url,
    _record.method,
    _record.access_token,
    _record.body,
    _record.queries,
    _record.decoder,
    _record.notify,
    _record.notify_error,
    finally$1,
    _record.before,
  );
}

function notify_error(ask, error) {
  let $ = $sentry.capture(error);
  
  $option.map(ask.notify_error, (notify) => { return notify(error); });
  return undefined;
}

function service_to_string(service) {
  if (service instanceof Loki) {
    return $endpoints.loki();
  } else if (service instanceof Heimdall) {
    return $endpoints.heimdall();
  } else if (service instanceof Nabu) {
    return $endpoints.nabu();
  } else {
    return $endpoints.hermes();
  }
}

function apply_access_token(request, ask) {
  let _pipe = ask.access_token;
  let _pipe$1 = $option.map(
    _pipe,
    (_capture) => { return $string.append("Bearer ", _capture); },
  );
  let _pipe$2 = $option.map(
    _pipe$1,
    (_capture) => {
      return $request.set_header(request, "authorization", _capture);
    },
  );
  return $option.unwrap(_pipe$2, request);
}

function apply_body_and_send(request, ask) {
  let $ = ask.body;
  if ($ instanceof $option.None) {
    return $fetch.send(request);
  } else if ($ instanceof $option.Some && $[0] instanceof Json) {
    let content = $[0].content;
    let _pipe = content;
    let _pipe$1 = $json.to_string(_pipe);
    let _pipe$2 = ((_capture) => { return $request.set_body(request, _capture); })(
      _pipe$1,
    );
    return $fetch.send(_pipe$2);
  } else if ($ instanceof $option.Some && $[0] instanceof Blob) {
    let content = $[0].content;
    let _pipe = content;
    let _pipe$1 = ((_capture) => { return $request.set_body(request, _capture); })(
      _pipe,
    );
    return $fetch.send_bits(_pipe$1);
  } else {
    let content = $[0].content;
    let _pipe = content;
    let _pipe$1 = ((_capture) => { return $request.set_body(request, _capture); })(
      _pipe,
    );
    return $fetch.send_form_data(_pipe$1);
  }
}

export function run(ask) {
  let endpoint = service_to_string(ask.service);
  let url = endpoint + ask.url;
  let $ = $request.to(url);
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "admin/ask",
      234,
      "run",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let request = $[0];
  let content_type = (() => {
    let $1 = ask.body;
    if ($1 instanceof $option.Some && $1[0] instanceof Json) {
      return new $option.Some("application/json");
    } else if ($1 instanceof $option.Some && $1[0] instanceof Blob) {
      return new $option.Some("application/octet-stream");
    } else {
      return new $option.None();
    }
  })();
  let _pipe = request;
  let _pipe$1 = apply_access_token(_pipe, ask);
  let _pipe$2 = $request.set_method(_pipe$1, ask.method);
  let _pipe$3 = $request.set_header(_pipe$2, "accept", "application/json");
  let _pipe$4 = (() => {
    if (content_type instanceof $option.None) {
      return $function.identity;
    } else {
      let ct = content_type[0];
      return (_capture) => {
        return $request.set_header(_capture, "content-type", ct);
      };
    }
  })()(_pipe$3);
  let _pipe$5 = $request.set_query(_pipe$4, ask.queries);
  let _pipe$6 = apply_body_and_send(_pipe$5, ask);
  let _pipe$7 = $promise.try_await(_pipe$6, $fetch.read_json_body);
  let _pipe$8 = $promise.map(
    _pipe$7,
    (_capture) => {
      return $result.map_error(
        _capture,
        (var0) => { return new $error.FetchError(var0); },
      );
    },
  );
  let _pipe$9 = $promise.map_try(
    _pipe$8,
    (response) => {
      let _pipe$9 = response.body;
      let _pipe$10 = $decode.run(_pipe$9, ask.decoder);
      return $result.map_error(
        _pipe$10,
        (var0) => { return new $error.DecodeError(var0); },
      );
    },
  );
  return $promise.tap(
    _pipe$9,
    (response) => {
      let notify$1 = $option.unwrap(ask.notify, (_) => { return undefined; });
      let finally$1 = $option.unwrap(ask.finally, () => { return undefined; });
      let before$1 = $option.unwrap(ask.before, () => { return undefined; });
      before$1();
      let value = (() => {
        let _pipe$10 = response;
        let _pipe$11 = $result.map(_pipe$10, notify$1);
        return $result.map_error(
          _pipe$11,
          (_capture) => { return notify_error(ask, _capture); },
        );
      })();
      finally$1();
      return value;
    },
  );
}
