/// <reference types="./icons.d.mts" />
import * as $css from "../../sketch/sketch/css.mjs";
import * as $length from "../../sketch/sketch/css/length.mjs";
import { px } from "../../sketch/sketch/css/length.mjs";
import * as $h from "../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $add from "../ds/icons/add.mjs";
import * as $add_user from "../ds/icons/add_user.mjs";
import * as $arrow_dropdown from "../ds/icons/arrow_dropdown.mjs";
import * as $arrow_left_line from "../ds/icons/arrow_left_line.mjs";
import * as $arrow_right_line from "../ds/icons/arrow_right_line.mjs";
import * as $arrow_up_line from "../ds/icons/arrow_up_line.mjs";
import * as $barchart from "../ds/icons/barchart.mjs";
import * as $bell from "../ds/icons/bell.mjs";
import * as $bold from "../ds/icons/bold.mjs";
import * as $book from "../ds/icons/book.mjs";
import * as $building from "../ds/icons/building.mjs";
import * as $calendar from "../ds/icons/calendar.mjs";
import * as $check from "../ds/icons/check.mjs";
import * as $checkmark from "../ds/icons/checkmark.mjs";
import * as $cmd from "../ds/icons/cmd.mjs";
import * as $collage from "../ds/icons/collage.mjs";
import * as $component from "../ds/icons/component.mjs";
import * as $copy from "../ds/icons/copy.mjs";
import * as $cross from "../ds/icons/cross.mjs";
import * as $documentation from "../ds/icons/documentation.mjs";
import * as $dot_list from "../ds/icons/dot_list.mjs";
import * as $double_fill from "../ds/icons/double_fill.mjs";
import * as $editor_menu from "../ds/icons/editor_menu.mjs";
import * as $export from "../ds/icons/export.mjs";
import * as $external_link from "../ds/icons/external_link.mjs";
import * as $file_edit from "../ds/icons/file_edit.mjs";
import * as $filters from "../ds/icons/filters.mjs";
import * as $form from "../ds/icons/form.mjs";
import * as $highlight from "../ds/icons/highlight.mjs";
import * as $index_list from "../ds/icons/index_list.mjs";
import * as $info from "../ds/icons/info.mjs";
import * as $italic from "../ds/icons/italic.mjs";
import * as $left_arrow from "../ds/icons/left_arrow.mjs";
import * as $lightbulb_flash from "../ds/icons/lightbulb_flash.mjs";
import * as $longer from "../ds/icons/longer.mjs";
import * as $magnifying_glass from "../ds/icons/magnifying_glass.mjs";
import * as $map from "../ds/icons/map.mjs";
import * as $menu from "../ds/icons/menu.mjs";
import * as $minus from "../ds/icons/minus.mjs";
import * as $more from "../ds/icons/more.mjs";
import * as $paper_plane from "../ds/icons/paper_plane.mjs";
import * as $picture_add from "../ds/icons/picture_add.mjs";
import * as $price_tag from "../ds/icons/price_tag.mjs";
import * as $question_mark from "../ds/icons/question_mark.mjs";
import * as $quote from "../ds/icons/quote.mjs";
import * as $refresh_line from "../ds/icons/refresh_line.mjs";
import * as $remove_format from "../ds/icons/remove_format.mjs";
import * as $right_arrow from "../ds/icons/right_arrow.mjs";
import * as $shining from "../ds/icons/shining.mjs";
import * as $shorter from "../ds/icons/shorter.mjs";
import * as $table_line from "../ds/icons/table_line.mjs";
import * as $translate from "../ds/icons/translate.mjs";
import * as $trash from "../ds/icons/trash.mjs";
import * as $underline from "../ds/icons/underline.mjs";
import * as $upload from "../ds/icons/upload.mjs";
import * as $user from "../ds/icons/user.mjs";
import * as $wrench from "../ds/icons/wrench.mjs";
import { toList } from "../gleam.mjs";

function square(size) {
  return $css.class$(toList([$css.width(px(size)), $css.height(px(size))]));
}

function flex() {
  return $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
    ]),
  );
}

export function medium(icon) {
  let _pipe = $css.class$(
    toList([$css.compose(square(24)), $css.compose(flex())]),
  );
  return $h.div(_pipe, toList([]), toList([icon]));
}

export function small(icon) {
  let _pipe = $css.class$(
    toList([$css.compose(square(20)), $css.compose(flex())]),
  );
  return $h.div(_pipe, toList([]), toList([icon]));
}

export function tiny(icon) {
  let _pipe = $css.class$(
    toList([
      $css.compose(square(16)),
      $css.compose(flex()),
      $css.selector(
        " > svg",
        toList([$css.width(px(100)), $css.height(px(100))]),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([icon]));
}

export const add = $add.add;

export const add_user = $add_user.add_user;

export const arrow_dropdown = $arrow_dropdown.arrow_dropdown;

export const arrow_left_line = $arrow_left_line.arrow_left_line;

export const arrow_right_line = $arrow_right_line.arrow_right_line;

export const arrow_up_line = $arrow_up_line.arrow_up_line;

export const barchart = $barchart.barchart;

export const bell = $bell.bell;

export const bold = $bold.bold;

export const book = $book.book;

export const building = $building.building;

export const calendar = $calendar.calendar;

export const check = $check.check;

export const checkmark = $checkmark.checkmark;

export const cmd = $cmd.cmd;

export const collage = $collage.collage;

export const component = $component.component;

export const copy = $copy.copy;

export const cross = $cross.cross;

export const documentation = $documentation.documentation;

export const dot_list = $dot_list.dot_list;

export const double_fill = $double_fill.double_fill;

export const editor_menu = $editor_menu.editor_menu;

export const export$ = $export.export$;

export const external_link = $external_link.external_link;

export const file_edit = $file_edit.file_edit;

export const filters = $filters.filters;

export const form = $form.form;

export const highlight = $highlight.highlight;

export const index_list = $index_list.index_list;

export const info = $info.info;

export const italic = $italic.italic;

export const left_arrow = $left_arrow.left_arrow;

export const lightbulb_flash = $lightbulb_flash.lightbulb_flash;

export const longer = $longer.longer;

export const magnifying_glass = $magnifying_glass.magnifying_glass;

export const map = $map.map;

export const menu = $menu.menu;

export const minus = $minus.minus;

export const more = $more.more;

export const paper_plane = $paper_plane.paper_plane;

export const picture_add = $picture_add.picture_add;

export const price_tag = $price_tag.price_tag;

export const question_mark = $question_mark.question_mark;

export const quote = $quote.quote;

export const remove_format = $remove_format.remove_format;

export const right_arrow = $right_arrow.right_arrow;

export const refresh_line = $refresh_line.refresh_line;

export const shining = $shining.shining;

export const shorter = $shorter.shorter;

export const table_line = $table_line.table_line;

export const translate = $translate.translate;

export const trash = $trash.trash;

export const underline = $underline.underline;

export const upload = $upload.upload;

export const user = $user.user;

export const wrench = $wrench.wrench;
