/// <reference types="./info.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function info() {
  let content = "<path d=\"M12 22.1147C6.477 22.1147 2 17.6377 2 12.1147C2 6.59175 6.477 2.11475 12 2.11475C17.523 2.11475 22 6.59175 22 12.1147C22 17.6377 17.523 22.1147 12 22.1147ZM12 20.1147C14.1217 20.1147 16.1566 19.2719 17.6569 17.7716C19.1571 16.2713 20 14.2365 20 12.1147C20 9.99301 19.1571 7.95818 17.6569 6.45789C16.1566 4.9576 14.1217 4.11475 12 4.11475C9.87827 4.11475 7.84344 4.9576 6.34315 6.45789C4.84285 7.95818 4 9.99301 4 12.1147C4 14.2365 4.84285 16.2713 6.34315 17.7716C7.84344 19.2719 9.87827 20.1147 12 20.1147ZM11 7.11475H13V9.11475H11V7.11475ZM11 11.1147H13V17.1147H11V11.1147Z\" fill=\"currentColor\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 25"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
