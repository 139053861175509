/// <reference types="./toast.d.mts" />
import * as $function from "../../../gleam_stdlib/gleam/function.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import { CustomType as $CustomType } from "../../gleam.mjs";
import * as $toast from "../../grille_pain/toast.mjs";
import * as $level from "../../grille_pain/toast/level.mjs";

export class Options extends $CustomType {
  constructor(timeout, level, sticky, msg) {
    super();
    this.timeout = timeout;
    this.level = level;
    this.sticky = sticky;
    this.msg = msg;
  }
}

export function options() {
  return new Options(
    new $option.None(),
    new $option.None(),
    false,
    new $option.None(),
  );
}

export function timeout(options, timeout) {
  let _record = options;
  return new Options(
    new $option.Some(timeout),
    _record.level,
    _record.sticky,
    _record.msg,
  );
}

export function sticky(options) {
  let _record = options;
  return new Options(_record.timeout, _record.level, true, _record.msg);
}

export function level(options, level) {
  let _record = options;
  return new Options(
    _record.timeout,
    new $option.Some(level),
    _record.sticky,
    _record.msg,
  );
}

export function notify(options, msg) {
  return new Options(
    options.timeout,
    options.level,
    options.sticky,
    new $option.Some(msg),
  );
}

function maybe(value, map) {
  if (value instanceof $option.None) {
    return $function.identity;
  } else {
    let value$1 = value[0];
    return (_capture) => { return map(_capture, value$1); };
  }
}

function dispatch(content, msg, toaster) {
  return $effect.from(
    (dispatch) => {
      let id = toaster(content);
      if (msg instanceof $option.None) {
        return undefined;
      } else {
        let msg$1 = msg[0];
        return dispatch(msg$1(id));
      }
    },
  );
}

export function toast(content) {
  return dispatch(content, new $option.None(), $toast.toast);
}

function to_options(options) {
  let _pipe = $toast.options();
  let _pipe$1 = (() => {
    let $ = options.sticky;
    if ($) {
      return $toast.sticky;
    } else {
      return $function.identity;
    }
  })()(_pipe);
  let _pipe$2 = maybe(options.timeout, $toast.timeout)(_pipe$1);
  return maybe(options.level, $toast.level)(_pipe$2);
}

export function info(content) {
  return dispatch(content, new $option.None(), $toast.info);
}

export function success(content) {
  return dispatch(content, new $option.None(), $toast.success);
}

export function error(content) {
  return dispatch(content, new $option.None(), $toast.error);
}

export function warning(content) {
  return dispatch(content, new $option.None(), $toast.warning);
}

export function custom(options, content) {
  return $effect.from(
    (dispatch) => {
      let options_ = to_options(options);
      let id = $toast.custom(options_, content);
      let $ = options.msg;
      if ($ instanceof $option.None) {
        return undefined;
      } else {
        let msg = $[0];
        return dispatch(msg(id));
      }
    },
  );
}

export function hide(id) {
  return $effect.from((_) => { return $toast.hide(id); });
}
