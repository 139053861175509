/// <reference types="./tooltip.d.mts" />
import * as $unsafe_ from "../../../common_common/common/unsafe.mjs";
import * as $bool from "../../../gleam_stdlib/gleam/bool.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $lustre from "../../../lustre/lustre.mjs";
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $element from "../../../lustre/lustre/element.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { px } from "../../../sketch/sketch/css/length.mjs";
import * as $experimental from "../../../sketch_lustre_experimental/sketch/lustre/experimental.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $portal from "../../ds/components/portal.mjs";
import * as $position from "../../ds/internals/position.mjs";
import * as $unsafe from "../../ds/internals/unsafe.mjs";
import { Ok, toList, prepend as listPrepend, CustomType as $CustomType } from "../../gleam.mjs";

class Model extends $CustomType {
  constructor(opened, parent, content, tooltip, id, position) {
    super();
    this.opened = opened;
    this.parent = parent;
    this.content = content;
    this.tooltip = tooltip;
    this.id = id;
    this.position = position;
  }
}

class OnContentMessage extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnContentUpdate extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnMouseEnter extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnMouseLeave extends $CustomType {}

class OnPositionUpdate extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnTooltipMessage extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class OnTooltipUpdate extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

export function position(position) {
  return $attribute.property("position", position);
}

function update(model, msg) {
  if (msg instanceof OnContentMessage) {
    let msg$1 = msg[0];
    return [model, $event.emit("msg", $unsafe_.coerce(msg$1))];
  } else if (msg instanceof OnContentUpdate) {
    let content = msg[0];
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.opened,
          _record.parent,
          content,
          _record.tooltip,
          _record.id,
          _record.position,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof OnMouseEnter) {
    let parent = msg[0];
    let parent$1 = new $option.Some(parent);
    let _pipe = (() => {
      let _record = model;
      return new Model(
        true,
        parent$1,
        _record.content,
        _record.tooltip,
        _record.id,
        _record.position,
      );
    })();
    return $pair.new$(_pipe, $effect.none());
  } else if (msg instanceof OnPositionUpdate) {
    let position$1 = msg[0];
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.opened,
          _record.parent,
          _record.content,
          _record.tooltip,
          _record.id,
          position$1,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof OnMouseLeave) {
    return [
      (() => {
        let _record = model;
        return new Model(
          false,
          _record.parent,
          _record.content,
          _record.tooltip,
          _record.id,
          _record.position,
        );
      })(),
      $effect.none(),
    ];
  } else if (msg instanceof OnTooltipMessage) {
    let msg$1 = msg[0];
    return [model, $event.emit("msg", $unsafe_.coerce(msg$1))];
  } else {
    let tooltip$1 = msg[0];
    return [
      (() => {
        let _record = model;
        return new Model(
          _record.opened,
          _record.parent,
          _record.content,
          tooltip$1,
          _record.id,
          _record.position,
        );
      })(),
      $effect.none(),
    ];
  }
}

function on_attribute_change() {
  let update_content = (dyn) => {
    return new Ok(new OnContentUpdate($unsafe_.coerce(dyn)));
  };
  let update_tooltip = (dyn) => {
    return new Ok(new OnTooltipUpdate($unsafe_.coerce(dyn)));
  };
  let update_position = (dyn) => {
    return new Ok(new OnPositionUpdate($unsafe_.coerce(dyn)));
  };
  return $dict.from_list(
    toList([
      ["content", update_content],
      ["tooltip", update_tooltip],
      ["position", update_position],
    ]),
  );
}

function init(_) {
  let empty = $element.none();
  let id = $unsafe_.unique_id();
  let _pipe = new Model(
    false,
    new $option.None(),
    empty,
    empty,
    id,
    new $position.Left(),
  );
  return $pair.new$(_pipe, $effect.none());
}

function on_mouse_enter() {
  return $event.on(
    "mouseenter",
    (event) => {
      let _pipe = $position.get_event_bounding_client_rect(event);
      let _pipe$1 = $result.map(
        _pipe,
        (var0) => { return new OnMouseEnter(var0); },
      );
      return $result.replace_error(_pipe$1, toList([]));
    },
  );
}

function tooltip_container(dom_rect, position, children) {
  let _pipe = $css.class$(
    toList([
      $css.position("fixed"),
      $css.max_width(px(450)),
      $css.z_index(10_000_000),
      $position.compute_vertical_position(dom_rect),
      $position.compute_horizontal_position(dom_rect, position),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

function view_tooltip(model) {
  return $bool.lazy_guard(
    !model.opened,
    $element.none,
    () => {
      return $position.require_dom_rect(
        model.parent,
        (dom_rect) => {
          return $portal.portal(
            model.id,
            tooltip_container(
              dom_rect,
              model.position,
              toList([
                (() => {
                  let _pipe = model.tooltip;
                  return $element.map(
                    _pipe,
                    (var0) => { return new OnTooltipMessage(var0); },
                  );
                })(),
              ]),
            ),
          );
        },
      );
    },
  );
}

function view(model, container) {
  return $experimental.render(
    toList([container, $experimental.node()]),
    () => {
      let class$ = $attribute.class$("domrect-parent-wrapper");
      let style = $css.class$(toList([$css.position("relative")]));
      let on_mouse_leave = $event.on_mouse_leave(new OnMouseLeave());
      return $h.div(
        style,
        toList([class$, on_mouse_leave, on_mouse_enter()]),
        toList([
          (() => {
            let _pipe = model.content;
            return $element.map(
              _pipe,
              (var0) => { return new OnContentMessage(var0); },
            );
          })(),
          view_tooltip(model),
        ]),
      );
    },
  );
}

const tag_name = "steerlab-tooltip";

export function tooltip(attributes, content, tooltip) {
  let content$1 = $attribute.property("content", content);
  let tooltip$1 = $attribute.property("tooltip", tooltip);
  let on = $event.on(
    "msg",
    (value) => { return new Ok($unsafe.coerce_event_details(value)); },
  );
  return $element.element(
    tag_name,
    listPrepend(content$1, listPrepend(tooltip$1, listPrepend(on, attributes))),
    toList([]),
  );
}

export function register(container) {
  let _pipe = on_attribute_change();
  let _pipe$1 = ((_capture) => {
    return $lustre.component(
      init,
      update,
      (_capture) => { return view(_capture, container); },
      _capture,
    );
  })(_pipe);
  return $lustre.register(_pipe$1, tag_name);
}

export const left = /* @__PURE__ */ new $position.Left();

export const right = /* @__PURE__ */ new $position.Right();
