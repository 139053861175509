/// <reference types="./config.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $ai_config from "../../common/data/ai_config.mjs";
import * as $button from "../../ds/ds/components/button.mjs";
import * as $input from "../../ds/ds/components/input.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $icons from "../../ds/ds/icons.mjs";
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $float from "../../gleam_stdlib/gleam/float.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $computed from "../data/model/computed.mjs";
import * as $msg from "../data/msg.mjs";
import { toList, isEqual } from "../gleam.mjs";
import * as $s from "../view/styles/config.mjs";

function pii_vocabulary(model) {
  let computed = $bright.computed(model);
  let pii_elements = (() => {
    let _pipe = computed.indexed_pii_vocabulary;
    return $list.map(
      _pipe,
      (pii_element) => {
        return $layout.column(
          toList([$layout.align("center")]),
          toList([]),
          toList([
            $input.input(
              toList([
                $input.value(pii_element[1]),
                $input.on_input(
                  (name) => {
                    return new $msg.UserUpdatedPiiVocabulary(
                      pii_element[0],
                      name,
                    );
                  },
                ),
              ]),
            ),
            $s.pii_tag_icon_delete(
              toList([
                $e.on_click(new $msg.UserRemovedPiiVocabulary(pii_element[0])),
              ]),
              $icons.cross(),
            ),
          ]),
        );
      },
    );
  })();
  return $layout.row(
    toList([$layout.gap(4)]),
    toList([]),
    toList([
      $layout.row(toList([$layout.gap(4)]), toList([]), pii_elements),
      $button.secondary(
        toList([$e.on_click(new $msg.UserClickedAddPiiVocabulary())]),
        toList([$button.icon($icons.add())]),
      ),
    ]),
  );
}

function ai_system_button(ai_config, content, title) {
  if (ai_config instanceof $option.Some) {
    let ai_config$1 = ai_config[0];
    let node = (() => {
      let $ = isEqual(ai_config$1.config.ai_system, new $option.Some(content));
      if ($) {
        return $button.primary;
      } else {
        return $button.secondary;
      }
    })();
    let on_click = $e.on_click(new $msg.UserUpdatedAiSystem(content));
    return node(toList([on_click]), toList([$h.text(title)]));
  } else {
    return $button.secondary(
      toList([$a.disabled(true)]),
      toList([$h.text(title)]),
    );
  }
}

function llm_limit_input(ai_config) {
  let llm_config = $option.then$(
    ai_config,
    (c) => { return c.config.llm_config; },
  );
  let limit = $option.map(llm_config, (c) => { return c.limit; });
  if (limit instanceof $option.Some) {
    let limit$1 = limit[0];
    let on_input = $e.on_input(
      (var0) => { return new $msg.UserUpdatedLlmLimit(var0); },
    );
    return $h.input(
      toList([
        $a.type_("number"),
        $a.value(
          (() => {
            let _pipe = limit$1;
            return $int.to_string(_pipe);
          })(),
        ),
        on_input,
      ]),
    );
  } else {
    return $h.input(toList([$a.type_("number"), $a.disabled(true)]));
  }
}

function query_limit_input(ai_config) {
  let query_config = $option.then$(
    ai_config,
    (c) => { return c.config.query_config; },
  );
  let limit = $option.map(query_config, (c) => { return c.limit; });
  if (limit instanceof $option.Some) {
    let limit$1 = limit[0];
    let on_input = $e.on_input(
      (var0) => { return new $msg.UserUpdatedQueryLimit(var0); },
    );
    return $h.input(
      toList([
        $a.type_("number"),
        $a.value(
          (() => {
            let _pipe = limit$1;
            return $int.to_string(_pipe);
          })(),
        ),
        on_input,
      ]),
    );
  } else {
    return $h.input(toList([$a.type_("number"), $a.disabled(true)]));
  }
}

function alpha_input(ai_config) {
  let query_config = $option.then$(
    ai_config,
    (c) => { return c.config.query_config; },
  );
  let alpha = $option.map(query_config, (c) => { return c.alpha; });
  if (alpha instanceof $option.Some) {
    let alpha$1 = alpha[0];
    let on_input = $e.on_input(
      (var0) => { return new $msg.UserUpdatedQueryAlpha(var0); },
    );
    return $h.input(
      toList([
        $a.type_("number"),
        $a.step("0.1"),
        $a.max("1"),
        $a.min("0"),
        $a.value(
          (() => {
            let _pipe = alpha$1;
            return $float.to_string(_pipe);
          })(),
        ),
        on_input,
      ]),
    );
  } else {
    return $h.input(toList([$a.type_("number"), $a.disabled(true)]));
  }
}

function translate(ai_config, bool) {
  let title = (() => {
    if (bool) {
      return "Yes";
    } else {
      return "No";
    }
  })();
  let disabled_button = $button.secondary(
    toList([$a.disabled(true)]),
    toList([$h.text(title)]),
  );
  let on_click = $e.on_click(new $msg.UserUpdatedTranslateOn(bool));
  if (ai_config instanceof $option.Some) {
    let ai_config$1 = ai_config[0];
    let $ = ai_config$1.config.query_config;
    if ($ instanceof $option.Some) {
      let query_config = $[0];
      let translate_on = (() => {
        let _pipe = query_config.translate_on;
        return $option.unwrap(_pipe, false);
      })();
      let $1 = translate_on === bool;
      if ($1) {
        return $button.primary(toList([on_click]), toList([$h.text(title)]));
      } else {
        return $button.secondary(toList([on_click]), toList([$h.text(title)]));
      }
    } else {
      return disabled_button;
    }
  } else {
    return disabled_button;
  }
}

function default_model(ai_config, model) {
  let title = (() => {
    let _pipe = $ai_config.encode_model(model);
    return $json.to_string(_pipe);
  })();
  let on_click = $e.on_click(new $msg.UserUpdatedModel(model));
  let models_config = $option.then$(
    ai_config,
    (c) => { return c.config.models_config; },
  );
  let default_model$1 = $option.then$(
    models_config,
    (c) => { return c.default_model; },
  );
  if (default_model$1 instanceof $option.Some) {
    let default_model$2 = default_model$1[0];
    let $ = isEqual(default_model$2, model);
    if ($) {
      return $button.primary(toList([on_click]), toList([$h.text(title)]));
    } else {
      return $button.secondary(toList([on_click]), toList([$h.text(title)]));
    }
  } else {
    return $button.secondary(
      toList([$a.disabled(true)]),
      toList([$h.text(title)]),
    );
  }
}

function retriever_option(ai_config, content, title) {
  let retriever_config = $option.then$(
    ai_config,
    (c) => { return c.config.retriever_config; },
  );
  if (retriever_config instanceof $option.Some) {
    let retriever_config$1 = retriever_config[0];
    let node = (() => {
      let $ = isEqual(retriever_config$1.option, content);
      if ($) {
        return $button.primary;
      } else {
        return $button.secondary;
      }
    })();
    let on_click = $e.on_click(new $msg.UserUpdatedRetrieverOption(content));
    return node(toList([on_click]), toList([$h.text(title)]));
  } else {
    return $button.secondary(
      toList([$a.disabled(true)]),
      toList([$h.text(title)]),
    );
  }
}

export function view(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  let ai_config = data.ai_config;
  return $el.fragment(
    toList([
      $layout.column(
        toList([$layout.gap(24)]),
        toList([]),
        toList([
          $button.primary(
            toList([
              $a.style(toList([["width", "250px"]])),
              $e.on_click(new $msg.UserSubmittedAiConfig()),
            ]),
            toList([$h.text("Save AI config")]),
          ),
          $s.section_title(toList([]), toList([$h.text("PII Vocabulary")])),
          $h.div(toList([]), toList([pii_vocabulary(model)])),
          $s.section_title(toList([]), toList([$h.text("AI system")])),
          $layout.row(
            toList([$layout.gap(36)]),
            toList([]),
            toList([
              $h.div(
                toList([]),
                toList([
                  ai_system_button(
                    ai_config,
                    new $ai_config.AdvancedRag(),
                    "Advanced RAG",
                  ),
                ]),
              ),
              $h.div(
                toList([]),
                toList([
                  ai_system_button(
                    ai_config,
                    new $ai_config.LlmEnhancedVectorSearch(),
                    "LLM-enhanced vector search",
                  ),
                ]),
              ),
            ]),
          ),
          $s.section_title(toList([]), toList([$h.text("LLM config")])),
          $s.subsection_title(
            toList([]),
            toList([$h.text("Limit of nodes given to the LLM")]),
          ),
          $h.div(toList([]), toList([llm_limit_input(ai_config)])),
          $s.section_title(toList([]), toList([$h.text("Query config")])),
          $s.subsection_title(
            toList([]),
            toList([$h.text("Limit of queried nodes")]),
          ),
          $h.div(toList([]), toList([query_limit_input(ai_config)])),
          $s.subsection_title(toList([]), toList([$h.text("Alpha")])),
          $h.div(toList([]), toList([alpha_input(ai_config)])),
          $s.subsection_title(
            toList([]),
            toList([$h.text("Translate answers")]),
          ),
          $layout.row(
            toList([$layout.gap(32)]),
            toList([]),
            toList([
              $h.div(toList([]), toList([translate(ai_config, true)])),
              $h.div(toList([]), toList([translate(ai_config, false)])),
            ]),
          ),
          $s.section_title(toList([]), toList([$h.text("Models config")])),
          $s.subsection_title(toList([]), toList([$h.text("Default model")])),
          $h.div(
            toList([]),
            toList([default_model(ai_config, new $ai_config.AzureGpt4o())]),
          ),
          $s.section_title(toList([]), toList([$h.text("Retriever config")])),
          $s.subsection_title(toList([]), toList([$h.text("Option")])),
          $layout.row(
            toList([$layout.gap(36)]),
            toList([]),
            toList([
              $h.div(
                toList([]),
                toList([
                  retriever_option(ai_config, new $ai_config.Rerank(), "Rerank"),
                ]),
              ),
              $h.div(
                toList([]),
                toList([
                  retriever_option(ai_config, new $ai_config.Hybrid(), "Hybrid"),
                ]),
              ),
              $h.div(
                toList([]),
                toList([
                  retriever_option(ai_config, new $ai_config.Fusion(), "Fusion"),
                ]),
              ),
            ]),
          ),
        ]),
      ),
    ]),
  );
}
