/// <reference types="./markdown.d.mts" />
import * as $attribute from "../../../lustre/lustre/attribute.mjs";
import * as $html from "../../../lustre/lustre/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function markdown() {
  let content = "<path d=\"M3 3.7168H21C21.2652 3.7168 21.5196 3.82215 21.7071 4.00969C21.8946 4.19723 22 4.45158 22 4.7168V20.7168C22 20.982 21.8946 21.2364 21.7071 21.4239C21.5196 21.6114 21.2652 21.7168 21 21.7168H3C2.73478 21.7168 2.48043 21.6114 2.29289 21.4239C2.10536 21.2364 2 20.982 2 20.7168V4.7168C2 4.45158 2.10536 4.19723 2.29289 4.00969C2.48043 3.82215 2.73478 3.7168 3 3.7168ZM7 16.2168V12.2168L9 14.2168L11 12.2168V16.2168H13V9.2168H11L9 11.2168L7 9.2168H5V16.2168H7ZM18 13.2168V9.2168H16V13.2168H14L17 16.2168L20 13.2168H18Z\" fill=\"#27272C\"/>";
  return $html.svg(
    toList([
      $attribute.attribute("dangerous-unescaped-html", content),
      $attribute.attribute("viewBox", "0 0 24 24"),
      $attribute.attribute("fill", "none"),
      $attribute.attribute("xmlns", "http://www.w3.org/2000/svg"),
    ]),
    toList([]),
  );
}
