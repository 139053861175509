/// <reference types="./ingestion_control.d.mts" />
import * as $colors from "../../../ds/ds/colors.mjs";
import * as $shadows from "../../../ds/ds/shadows.mjs";
import * as $text from "../../../ds/ds/typography/text.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $angle from "../../../sketch/sketch/css/angle.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import { percent, px, vw } from "../../../sketch/sketch/css/length.mjs";
import * as $transform from "../../../sketch/sketch/css/transform.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function data_point(child) {
  let _pipe = $css.class$(
    toList([
      $css.white_space("pre-line"),
      $css.border("1px solid " + $colors.light.grey_800),
      $css.border_radius(px(8)),
      $css.padding(px(10)),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function page_number(child) {
  let _pipe = $css.class$(toList([$css.color($colors.light.grey_600)]));
  return $h.div(_pipe, toList([]), toList([child]));
}

export function empty_cell(child) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.padding_("30px 0"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function empty_text_content(child) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xs($text.medium)),
      $css.max_width(px(230)),
      $css.white_space("wrap"),
      $css.text_align("center"),
      $css.color($colors.light.grey_800),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

export function menu_wrapper(hidden, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.position("fixed"),
      $css.display("flex"),
      $css.justify_content("center"),
      $css.width(vw(100)),
      $css.transition("bottom .3s"),
      $css.left(px(0)),
      $css.z_index(90_020),
      $css.bottom(
        (() => {
          if (hidden) {
            return px(-200);
          } else {
            return px(40);
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function menu(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.color($colors.light.onyx),
      $css.display("flex"),
      $css.gap(px(32)),
      $css.align_items("center"),
      $css.justify_content("center"),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
      $css.background($colors.light.white),
      $css.padding_("14px 16px"),
      $css.box_shadow($shadows.m()),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function number_selected(attrs, children) {
  return $h.div_(attrs, children);
}

export function change_button(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.gap(px(8)),
      $css.align_items("center"),
      $css.cursor("pointer"),
      $css.hover(toList([$css.background($colors.light.grey_50)])),
      $css.padding(px(6)),
      $css.border_radius(px(4)),
      $css.position("relative"),
    ]),
  );
  return $h.button(_pipe, attrs, children);
}

export function change_icon(reversed, icon) {
  let _pipe = $css.class$(
    toList([
      $css.width(px(20)),
      $css.height(px(20)),
      $css.transition("transform .3s"),
      $css.transform(
        (() => {
          if (reversed) {
            return toList([$transform.rotate($angle.deg(180.0))]);
          } else {
            return toList([$transform.rotate($angle.deg(0.0))]);
          }
        })(),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([icon]));
}

export function change_popup() {
  return $css.class$(
    toList([
      $css.position("absolute"),
      $css.bottom(px(55)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.background($colors.light.white),
      $css.cursor("default"),
      $css.overflow("auto"),
      $css.max_height(px(500)),
      $css.box_shadow($shadows.m()),
    ]),
  );
}

export function change_status_popup(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose(change_popup()),
      $css.right(px(0)),
      $css.gap(px(10)),
      $css.padding(px(10)),
      $css.width(percent(100)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}
