/// <reference types="./css_stylesheet.d.mts" />
import * as $dynamic from "../../../../gleam_stdlib/gleam/dynamic.mjs";
import {
  createDocument as create_document_stylesheet,
  createRoot as create_shadow_root_stylesheet,
  replaceSync as replace,
} from "../../../css-stylesheet.ffi.mjs";
import { CustomType as $CustomType } from "../../../gleam.mjs";

export { replace };

export class Document extends $CustomType {}

export class ShadowRoot extends $CustomType {
  constructor(root) {
    super();
    this.root = root;
  }
}

export function create(kind) {
  if (kind instanceof Document) {
    return create_document_stylesheet();
  } else {
    let root = kind.root;
    return create_shadow_root_stylesheet(root);
  }
}
