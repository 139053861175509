import * as $gleam from '../../gleam.mjs'

export const clear = () => localStorage.clear()
export const removeItem = key => localStorage.removeItem(key)

export function setItem(key, value) {
  try {
    localStorage.setItem(key, value)
    return new $gleam.Ok(value)
  } catch (error) {
    return new $gleam.Error()
  }
}

export function getItem(key) {
  const item = localStorage.getItem(key)
  if (item) return new $gleam.Ok(item)
  return new $gleam.Error()
}
