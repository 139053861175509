/// <reference types="./ds.d.mts" />
import * as $result from "../gleam_stdlib/gleam/result.mjs";
import * as $sketch from "../sketch/sketch.mjs";
import * as $experimental from "../sketch_lustre_experimental/sketch/lustre/experimental.mjs";
import * as $copy_button from "./ds/components/copy_button.mjs";
import * as $dropdown from "./ds/components/dropdown.mjs";
import * as $portal from "./ds/components/portal.mjs";
import * as $tooltip from "./ds/components/tooltip.mjs";
import * as $transition_group from "./ds/components/transition_group.mjs";
import * as $keyframes from "./ds/keyframes.mjs";
import { Ok, makeError } from "./gleam.mjs";

function sketch_setup() {
  let _pipe = $sketch.stylesheet(new $sketch.Persistent());
  let _pipe$1 = $result.replace_error(_pipe, undefined);
  let _pipe$2 = $result.map(
    _pipe$1,
    (stylesheet) => {
      let _pipe$2 = stylesheet;
      let _pipe$3 = ((_capture) => {
        return $sketch.at_rule($keyframes.wave(), _capture);
      })(_pipe$2);
      let _pipe$4 = ((_capture) => {
        return $sketch.at_rule($keyframes.pulse(), _capture);
      })(_pipe$3);
      return ((_capture) => {
        return $sketch.at_rule($keyframes.fading_text(), _capture);
      })(_pipe$4);
    },
  );
  return $result.then$(_pipe$2, $experimental.setup);
}

export function setup() {
  let $ = sketch_setup();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "ds",
      12,
      "setup",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let container = $experimental.document();
  return $result.try$(
    $dropdown.register(container),
    (_) => {
      return $result.try$(
        $tooltip.register(container),
        (_) => {
          return $result.try$(
            $copy_button.register(container),
            (_) => {
              return $result.try$(
                $portal.register(),
                (_) => {
                  return $result.try$(
                    $transition_group.register(),
                    (_) => { return new Ok(undefined); },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}
