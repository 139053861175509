/// <reference types="./data_source.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../gleam_stdlib/gleam/pair.mjs";
import * as $string from "../../gleam_stdlib/gleam/string.mjs";
import * as $calendar from "../../gleam_time/gleam/time/calendar.mjs";
import * as $timestamp from "../../gleam_time/gleam/time/timestamp.mjs";
import * as $mime_types from "../../mime_types/mime_types.mjs";
import * as $connector from "../data/connector.mjs";
import * as $question from "../data/proposal_element/question.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Pending extends $CustomType {}

export class Verified extends $CustomType {}

export class NotVerified extends $CustomType {}

export class Archived extends $CustomType {}

export class Outdated extends $CustomType {}

export class StatusStats extends $CustomType {
  constructor(verified, pending, not_verified, outdated) {
    super();
    this.verified = verified;
    this.pending = pending;
    this.not_verified = not_verified;
    this.outdated = outdated;
  }
}

export class SheetLabels extends $CustomType {
  constructor(sheet_index, sheet_title, kind, question_column_index, answer_column_index, rows_to_process) {
    super();
    this.sheet_index = sheet_index;
    this.sheet_title = sheet_title;
    this.kind = kind;
    this.question_column_index = question_column_index;
    this.answer_column_index = answer_column_index;
    this.rows_to_process = rows_to_process;
  }
}

export class Qna extends $CustomType {
  constructor(question, answer, tag) {
    super();
    this.question = question;
    this.answer = answer;
    this.tag = tag;
  }
}

export class Display extends $CustomType {
  constructor(name, language, mime_type, origin, qna) {
    super();
    this.name = name;
    this.language = language;
    this.mime_type = mime_type;
    this.origin = origin;
    this.qna = qna;
  }
}

export class DataSource extends $CustomType {
  constructor(id, status, display, kind, authors, owner, document_id, blob_link, content_link, hash, version, tags, org_id, expiration_date) {
    super();
    this.id = id;
    this.status = status;
    this.display = display;
    this.kind = kind;
    this.authors = authors;
    this.owner = owner;
    this.document_id = document_id;
    this.blob_link = blob_link;
    this.content_link = content_link;
    this.hash = hash;
    this.version = version;
    this.tags = tags;
    this.org_id = org_id;
    this.expiration_date = expiration_date;
  }
}

export function status_to_string(status) {
  if (status instanceof Pending) {
    return "pending";
  } else if (status instanceof Verified) {
    return "verified";
  } else if (status instanceof NotVerified) {
    return "not-verified";
  } else if (status instanceof Archived) {
    return "archived";
  } else {
    return "outdated";
  }
}

function encode_display(display) {
  return $json.object(
    toList([
      ["name", $json.string(display.name)],
      ["language", $json.nullable(display.language, $json.string)],
      ["mime_type", $json.string($mime_types.to_string(display.mime_type))],
      ["origin", $json.nullable(display.origin, $json.string)],
      [
        "qna",
        $json.nullable(
          display.qna,
          (qna) => {
            return $json.object(
              toList([
                ["question", $json.string(qna.question)],
                ["answer", $json.string(qna.answer)],
                ["tag", $json.nullable(qna.tag, $json.string)],
              ]),
            );
          },
        ),
      ],
    ]),
  );
}

export function encode(data_source) {
  return $json.object(
    toList([
      ["id", $json.string(data_source.id)],
      ["status", $json.string(status_to_string(data_source.status))],
      ["display", encode_display(data_source.display)],
      ["kind", $connector.encode(data_source.kind)],
      ["authors", $json.array(data_source.authors, $json.string)],
      ["owner", $json.nullable(data_source.owner, $json.string)],
      ["document_id", $json.string(data_source.document_id)],
      ["blob_link", $json.string(data_source.blob_link)],
      ["content_link", $json.string(data_source.content_link)],
      ["hash", $json.nullable(data_source.hash, $json.string)],
      ["version", $json.int(data_source.version)],
      ["tags", $json.array(data_source.tags, $json.string)],
      ["org_id", $json.string(data_source.org_id)],
      [
        "expiration_date",
        $json.nullable(
          data_source.expiration_date,
          (date) => {
            return $json.string(
              $timestamp.to_rfc3339(date, $calendar.utc_offset),
            );
          },
        ),
      ],
    ]),
  );
}

function display_decoder() {
  return $decode.field(
    "name",
    $decode.string,
    (name) => {
      return $decrypt.optional_field(
        "language",
        $decode.string,
        (language) => {
          return $decode.field(
            "mime_type",
            $mime_types.decoder(),
            (mime_type) => {
              return $decrypt.optional_field(
                "origin",
                $decode.string,
                (origin) => {
                  return $decrypt.optional_field(
                    "qna",
                    $decode.field(
                      "question",
                      $decode.string,
                      (question) => {
                        return $decode.field(
                          "answer",
                          $decode.string,
                          (answer) => {
                            return $decrypt.optional_field(
                              "tag",
                              $decode.string,
                              (tag) => {
                                return $decode.success(
                                  new Qna(question, answer, tag),
                                );
                              },
                            );
                          },
                        );
                      },
                    ),
                    (qna) => {
                      return $decode.success(
                        new Display(name, language, mime_type, origin, qna),
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function status_decoder() {
  return $decode.then$(
    $decode.string,
    (status) => {
      if (status === "archived") {
        return $decode.success(new Archived());
      } else if (status === "not-verified") {
        return $decode.success(new NotVerified());
      } else if (status === "pending") {
        return $decode.success(new Pending());
      } else if (status === "verified") {
        return $decode.success(new Verified());
      } else if (status === "outdated") {
        return $decode.success(new Outdated());
      } else {
        return $decode.failure(new Archived(), "Status");
      }
    },
  );
}

export function decoder() {
  return $decode.field(
    "id",
    (() => {
      let _pipe = $decode.string;
      return $decode.map(_pipe, $string.lowercase);
    })(),
    (id) => {
      return $decode.field(
        "status",
        status_decoder(),
        (status) => {
          return $decode.field(
            "display",
            $decrypt.json(display_decoder()),
            (display) => {
              return $decode.field(
                "kind",
                $connector.decoder(),
                (kind) => {
                  return $decode.field(
                    "authors",
                    (() => {
                      let _pipe = $decode.list($decode.optional($decode.string));
                      let _pipe$1 = $decode.map(_pipe, $option.all);
                      let _pipe$2 = $decode.optional(_pipe$1);
                      let _pipe$3 = $decode.map(_pipe$2, $option.flatten);
                      return $decode.map(
                        _pipe$3,
                        (_capture) => {
                          return $option.unwrap(_capture, toList([]));
                        },
                      );
                    })(),
                    (authors) => {
                      return $decode.field(
                        "owner",
                        $decode.optional($decode.string),
                        (owner) => {
                          return $decode.field(
                            "document_id",
                            $decode.string,
                            (document_id) => {
                              return $decode.field(
                                "blob_link",
                                $decode.string,
                                (blob_link) => {
                                  return $decode.field(
                                    "content_link",
                                    $decode.string,
                                    (content_link) => {
                                      return $decode.field(
                                        "hash",
                                        $decode.optional($decode.string),
                                        (hash) => {
                                          return $decode.field(
                                            "version",
                                            $decode.int,
                                            (version) => {
                                              return $decode.field(
                                                "tags",
                                                (() => {
                                                  let _pipe = $decode.optional(
                                                    $decode.list($decode.string),
                                                  );
                                                  return $decode.map(
                                                    _pipe,
                                                    (_capture) => {
                                                      return $option.unwrap(
                                                        _capture,
                                                        toList([]),
                                                      );
                                                    },
                                                  );
                                                })(),
                                                (tags) => {
                                                  return $decode.field(
                                                    "org_id",
                                                    $decode.string,
                                                    (org_id) => {
                                                      let expiration_date = $decode.optional(
                                                        $decrypt.timestamp(),
                                                      );
                                                      return $decode.field(
                                                        "expiration_date",
                                                        expiration_date,
                                                        (expiration_date) => {
                                                          return $decode.success(
                                                            new DataSource(
                                                              id,
                                                              status,
                                                              display,
                                                              kind,
                                                              authors,
                                                              owner,
                                                              document_id,
                                                              blob_link,
                                                              content_link,
                                                              hash,
                                                              version,
                                                              tags,
                                                              org_id,
                                                              expiration_date,
                                                            ),
                                                          );
                                                        },
                                                      );
                                                    },
                                                  );
                                                },
                                              );
                                            },
                                          );
                                        },
                                      );
                                    },
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function encode_content_library_stats(stats) {
  let _pipe = toList([
    [new Verified(), stats.verified],
    [new Pending(), stats.pending],
    [new NotVerified(), stats.not_verified],
    [new Outdated(), stats.outdated],
  ]);
  let _pipe$1 = $list.map(
    _pipe,
    (_capture) => { return $pair.map_first(_capture, status_to_string); },
  );
  let _pipe$2 = $list.map(
    _pipe$1,
    (_capture) => { return $pair.map_second(_capture, $json.int); },
  );
  return $json.object(_pipe$2);
}

function decode_optional_field(field, default$, decoder, next) {
  let decoder$1 = $decode.optional(decoder);
  return $decode.then$(
    $decode.optionally_at(toList([field]), new None(), decoder$1),
    (content) => {
      if (content instanceof None) {
        return next(default$);
      } else {
        let content$1 = content[0];
        return next(content$1);
      }
    },
  );
}

export function content_library_stats_decoder() {
  return decode_optional_field(
    status_to_string(new Verified()),
    0,
    $decode.int,
    (a) => {
      return decode_optional_field(
        status_to_string(new Pending()),
        0,
        $decode.int,
        (b) => {
          return decode_optional_field(
            status_to_string(new NotVerified()),
            0,
            $decode.int,
            (c) => {
              return decode_optional_field(
                status_to_string(new Outdated()),
                0,
                $decode.int,
                (d) => { return $decode.success(new StatusStats(a, b, c, d)); },
              );
            },
          );
        },
      );
    },
  );
}

export function decrease_status_stats(stats, data_source) {
  let $ = data_source.status;
  if ($ instanceof Pending) {
    let _record = stats;
    return new StatusStats(
      _record.verified,
      stats.pending - 1,
      _record.not_verified,
      _record.outdated,
    );
  } else if ($ instanceof Verified) {
    let _record = stats;
    return new StatusStats(
      stats.verified - 1,
      _record.pending,
      _record.not_verified,
      _record.outdated,
    );
  } else if ($ instanceof NotVerified) {
    let _record = stats;
    return new StatusStats(
      _record.verified,
      _record.pending,
      stats.not_verified - 1,
      _record.outdated,
    );
  } else if ($ instanceof Outdated) {
    let _record = stats;
    return new StatusStats(
      _record.verified,
      _record.pending,
      _record.not_verified,
      stats.outdated - 1,
    );
  } else {
    return stats;
  }
}
