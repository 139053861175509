/// <reference types="./organization_setup.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $button from "../../ds/ds/components/button.mjs";
import * as $input from "../../ds/ds/components/input.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $icons from "../../ds/ds/icons.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $el from "../../lustre/lustre/element.mjs";
import * as $h from "../../lustre/lustre/element/html.mjs";
import * as $e from "../../lustre/lustre/event.mjs";
import * as $model from "../data/model.mjs";
import * as $data from "../data/model/data.mjs";
import { Data } from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";
import { Ok, toList } from "../gleam.mjs";
import * as $s from "../view/styles/organization_setup.mjs";

function on_remove_tag(tag) {
  return $e.on(
    "click",
    (event) => {
      $e.prevent_default(event);
      $e.stop_propagation(event);
      return new Ok(new $msg.OrganizationSetup(new $msg.UserRemovedTag(tag)));
    },
  );
}

export function view(model) {
  let $ = $bright.data(model);
  let tags = $.tags;
  let tag_input = $.tag_input;
  let qm_rows = $.qm_rows;
  let on_setup_click = $e.on_click(
    new $msg.OrganizationSetup(new $msg.UserClickedSetup()),
  );
  return $el.fragment(
    toList([
      $h.div(
        toList([]),
        toList([
          $button.primary(
            toList([on_setup_click]),
            toList([$h.text("Setup default config")]),
          ),
        ]),
      ),
      $s.section_title(toList([]), toList([$h.text("AI config")])),
      $h.div(
        toList([]),
        toList([
          $h.text(
            "Please check the current AI config in the corresponding tab.",
          ),
        ]),
      ),
      $s.section_title(toList([]), toList([$h.text("Tags")])),
      $layout.column(
        toList([$layout.gap(12)]),
        toList([]),
        toList([
          $layout.column(
            toList([$layout.gap(12)]),
            toList([]),
            $list.map(
              tags,
              (tag) => {
                return $layout.row(
                  toList([]),
                  toList([]),
                  toList([
                    $s.details_tag_deletion(
                      toList([]),
                      toList([
                        $h.text(tag.name),
                        $s.icon_delete(
                          toList([on_remove_tag(tag.name)]),
                          $icons.cross(),
                        ),
                      ]),
                    ),
                  ]),
                );
              },
            ),
          ),
          $s.input_wrapper(
            toList([]),
            toList([
              $input.input(
                toList([
                  $input.placeholder("Add a tag here..."),
                  $input.value(tag_input),
                  $input.on_input(
                    (t) => {
                      return new $msg.OrganizationSetup(
                        new $msg.UserUpdatedTagInput(t),
                      );
                    },
                  ),
                  $input.on_submit(
                    new $msg.OrganizationSetup(new $msg.UserSubmittedTag()),
                  ),
                ]),
              ),
            ]),
          ),
        ]),
      ),
      $s.section_title(toList([]), toList([$h.text("Qualification matrix")])),
      $layout.column(
        toList([$layout.gap(12)]),
        toList([]),
        $list.map(
          qm_rows,
          (qm) => {
            return $layout.column(
              toList([]),
              toList([]),
              toList([
                $h.div(toList([]), toList([$h.text(qm.title)])),
                $h.div(toList([]), toList([$h.text(qm.content)])),
                $h.div(toList([]), toList([$h.text(qm.org_id)])),
                $h.div(
                  toList([]),
                  toList([
                    $h.text(
                      (() => {
                        let _pipe = qm.qm_order;
                        return $int.to_string(_pipe);
                      })(),
                    ),
                  ]),
                ),
              ]),
            );
          },
        ),
      ),
    ]),
  );
}
