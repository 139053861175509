/// <reference types="./progress_bar.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { px } from "../../../../sketch/sketch/css/length.mjs";
import * as $html from "../../../../sketch_lustre/sketch/lustre/element/html.mjs";
import { toList, divideInt } from "../../../gleam.mjs";
import { var$ } from "../../../grille_pain/internals/css.mjs";
import * as $toast from "../../../grille_pain/internals/data/toast.mjs";
import * as $theme from "../../../grille_pain/internals/view/theme.mjs";
import * as $level from "../../../grille_pain/toast/level.mjs";

function pb_base() {
  return $css.class$(
    toList([$css.animation_fill_mode("forwards"), $css.height(px(5))]),
  );
}

function pb_animation(duration) {
  let duration$1 = $int.to_string(divideInt(duration, 1000));
  let animation = duration$1 + "s linear 0s progress_bar";
  return $css.class$(toList([$css.animation(animation)]));
}

function pb_play_state(running) {
  let running_str = $toast.running_to_string(running);
  return $css.class$(
    toList([
      (() => {
        let _pipe = $css.animation_play_state(running_str);
        return $css.important(_pipe);
      })(),
    ]),
  );
}

function background_color(level) {
  if (level instanceof $level.Info) {
    return $theme.color_transparent;
  } else if (level instanceof $level.Success) {
    return $theme.color_transparent;
  } else if (level instanceof $level.Warning) {
    return $theme.color_transparent;
  } else if (level instanceof $level.Error) {
    return $theme.color_transparent;
  } else {
    let $ = $theme.is_dark();
    if ($) {
      return $theme.dark_transparent;
    } else {
      return $theme.light_transparent;
    }
  }
}

function pb_background_color(level) {
  let back_color = background_color(level);
  let level$1 = $level.to_string(level);
  let background = var$(
    ("grille_pain-" + level$1) + "-progress-bar",
    back_color,
  );
  return $css.class$(toList([$css.background(background)]));
}

export function view(toast) {
  let _pipe = $css.class$(
    toList([
      $css.compose(pb_base()),
      $css.compose(pb_background_color(toast.level)),
      $css.compose(pb_animation(toast.animation_duration)),
      $css.compose(pb_play_state(toast.running)),
    ]),
  );
  return $html.div(_pipe, toList([]), toList([]));
}
