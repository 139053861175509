/// <reference types="./content_libray.d.mts" />
import * as $bright from "../../../bright/bright.mjs";
import * as $data_source from "../../../common/data/data_source.mjs";
import * as $button from "../../../ds/ds/components/button.mjs";
import * as $checkbox from "../../../ds/ds/components/checkbox.mjs";
import * as $label from "../../../ds/ds/components/label.mjs";
import * as $layout from "../../../ds/ds/components/layout.mjs";
import * as $skeleton from "../../../ds/ds/components/skeleton.mjs";
import * as $table from "../../../ds/ds/components/table.mjs";
import * as $utils from "../../../ds/ds/utils.mjs";
import * as $dict from "../../../gleam_stdlib/gleam/dict.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $el from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $e from "../../../lustre/lustre/event.mjs";
import * as $content_library from "../../data/content_library.mjs";
import * as $model from "../../data/model.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList, prepend as listPrepend } from "../../gleam.mjs";
import * as $common from "../../view/common.mjs";
import * as $ic_common from "../../view/ingestion_control/common.mjs";
import * as $s from "../../view/styles/ingestion_control.mjs";

function view_loading_table() {
  let _pipe = $table.row(
    listPrepend(
      $table.cell($el.none()),
      (() => {
        let _pipe = $table.cell($skeleton.skeleton(100, 25));
        return $list.repeat(_pipe, 4);
      })(),
    ),
  );
  let _pipe$1 = $list.repeat(_pipe, 4);
  return $table.body(_pipe$1);
}

function content_library_table_headers() {
  return $table.headers(
    toList([
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Format");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Name");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Status");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("");
        return $table.fit(_pipe);
      })(),
      (() => {
        let _pipe = $table.header("Preview");
        return $table.fit(_pipe);
      })(),
    ]),
  );
}

function view_table_body(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $table.body(
    $list.map(
      (() => {
        let _pipe = data.content_library;
        return $content_library.filtered_non_qna_sources(_pipe);
      })(),
      (source) => {
        let visible = (() => {
          let _pipe = data.ingestion_control_sources_visibility;
          let _pipe$1 = $dict.get(_pipe, source.id);
          return $result.unwrap(_pipe$1, false);
        })();
        return $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(
                $checkbox.checkbox(
                  $checkbox.checked(
                    $content_library.is_selected(
                      data.content_library,
                      source.id,
                    ),
                  ),
                  (checked) => {
                    return new $msg.UserSelectedSource(source.id, checked);
                  },
                ),
              );
              return $table.stop_cell_propagation(_pipe);
            })(),
            $table.cell(
              $common.small_icon($utils.select_icon(source.display.mime_type)),
            ),
            $table.cell($el.text(source.display.name)),
            (() => {
              let _pipe = $table.cell(
                (() => {
                  let $1 = (() => {
                    let $2 = source.status;
                    if ($2 instanceof $data_source.Pending) {
                      return [$label.current(), "Pending"];
                    } else if ($2 instanceof $data_source.NotVerified) {
                      return [$label.neutral(), "Not verified"];
                    } else if ($2 instanceof $data_source.Verified) {
                      return [$label.success(), "Verified"];
                    } else if ($2 instanceof $data_source.Outdated) {
                      return [$label.error(), "Outdated"];
                    } else {
                      return [$label.error(), "Archived"];
                    }
                  })();
                  let status = $1[0];
                  let content = $1[1];
                  return $label.label(toList([status, $label.content(content)]));
                })(),
              );
              return $table.stop_cell_propagation(_pipe);
            })(),
            $table.cell(
              $layout.row(
                toList([$layout.gap(8)]),
                toList([]),
                toList([
                  $button.primary(
                    toList([
                      $e.on_click(
                        new $msg.UserClickedOnSourceInspect(source.id),
                      ),
                    ]),
                    toList([$h.text("Inspect")]),
                  ),
                  $button.primary(
                    toList([
                      $e.on_click(
                        new $msg.UserClickedOnSourcePreview(source.id),
                      ),
                    ]),
                    toList([$h.text("Preview")]),
                  ),
                ]),
              ),
            ),
            $table.cell(
              (() => {
                if (visible) {
                  return $ic_common.preview_source(
                    model,
                    source.id,
                    new $option.Some(10),
                  );
                } else {
                  return $el.none();
                }
              })(),
            ),
          ]),
        );
      },
    ),
  );
}

const empty_text = "Nothing is uploaded in the contant library, go to the app and upload files.";

function empty_cell() {
  return $s.empty_cell($s.empty_text_content($h.text(empty_text)));
}

function view_empty_table() {
  return $table.body(
    toList([
      (() => {
        let _pipe = $table.row(
          toList([
            (() => {
              let _pipe = $table.cell(empty_cell());
              return $table.span(_pipe, 7);
            })(),
          ]),
        );
        return $table.hover(_pipe, false);
      })(),
    ]),
  );
}

function content_library_table(model) {
  let $ = $bright.unwrap(model);
  let data = $[0];
  return $table.table(
    toList([
      content_library_table_headers(),
      (() => {
        let $1 = data.loading.content_library;
        let $2 = $list.is_empty(data.content_library.non_qna_sources.all);
        if ($1) {
          return view_loading_table();
        } else if ($2) {
          return view_empty_table();
        } else {
          return view_table_body(model);
        }
      })(),
    ]),
  );
}

export function view(model) {
  return $el.fragment(
    toList([
      (() => {
        let _pipe = content_library_table(model);
        return $el.map(
          _pipe,
          (var0) => { return new $msg.ContentLibrary(var0); },
        );
      })(),
    ]),
  );
}
