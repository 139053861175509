/// <reference types="./organization_setup.d.mts" />
import * as $utils from "../../../common/common/utils.mjs";
import * as $ai_config from "../../../common/data/ai_config.mjs";
import * as $qualification_matrix from "../../../common/data/qualification_matrix.mjs";
import * as $tag from "../../../common/data/tag.mjs";
import * as $decrypt from "../../../common/decrypt.mjs";
import * as $http from "../../../gleam_http/gleam/http.mjs";
import * as $json from "../../../gleam_json/gleam/json.mjs";
import * as $decode from "../../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $level from "../../../grille_pain/grille_pain/toast/level.mjs";
import * as $effect from "../../../lustre/lustre/effect.mjs";
import * as $ask from "../../admin/ask.mjs";
import * as $middleware from "../../admin/middleware.mjs";
import * as $data from "../../data/model/data.mjs";
import * as $msg from "../../data/msg.mjs";
import { toList } from "../../gleam.mjs";

export function set_defaults(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $middleware.require_org_id(
            data,
            (org_id) => {
              return $effect.from(
                (dispatch) => {
                  let ai_config = $json.object(
                    toList([
                      ["org_id", $json.string(org_id)],
                      [
                        "config",
                        $ai_config.encode_config($ai_config.default_config()),
                      ],
                    ]),
                  );
                  let tags = $json.array($utils.default_tags, $json.string);
                  let qualification_matrix = $json.array(
                    $utils.default_qm_rows,
                    (r) => {
                      return $json.object(
                        toList([
                          ["id", $json.string("")],
                          ["org_id", $json.string("")],
                          ["title", $json.string(r[0])],
                          ["content", $json.string(r[1])],
                          ["qm_order", $json.int(r[2])],
                        ]),
                      );
                    },
                  );
                  let defaults = $json.object(
                    toList([
                      ["ai_config", ai_config],
                      ["tags", tags],
                      ["qualification_matrix", qualification_matrix],
                    ]),
                  );
                  let _pipe = $ask.to(
                    new $ask.Heimdall(),
                    toList(["setup", "organization"]),
                  );
                  let _pipe$1 = $ask.via(_pipe, new $http.Post());
                  let _pipe$2 = $ask.bearing(_pipe$1, access_token);
                  let _pipe$3 = $ask.with$(_pipe$2, defaults);
                  let _pipe$4 = $ask.expect(
                    _pipe$3,
                    (() => {
                      let ai_config$1 = $decode.optional(
                        $decrypt.json($ai_config.decoder()),
                      );
                      return $decode.field(
                        "ai_config",
                        ai_config$1,
                        (ai_config) => {
                          return $decode.field(
                            "tags",
                            $decode.list($decrypt.json($tag.decoder())),
                            (tags) => {
                              let qm = $decode.list(
                                $decrypt.json($qualification_matrix.decoder()),
                              );
                              return $decode.field(
                                "qualification_matrix",
                                qm,
                                (qualification_matrix) => {
                                  return $decode.success(
                                    [ai_config, tags, qualification_matrix],
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    })(),
                  );
                  let _pipe$5 = $ask.notify(
                    _pipe$4,
                    (defaults) => {
                      let ai_config$1 = defaults[0];
                      let tags$1 = defaults[1];
                      let qm_rows = defaults[2];
                      let _pipe$5 = new $msg.ApiSavedDefaults(
                        ai_config$1,
                        tags$1,
                        qm_rows,
                      );
                      let _pipe$6 = new $msg.OrganizationSetup(_pipe$5);
                      return dispatch(_pipe$6);
                    },
                  );
                  $ask.run(_pipe$5)
                  return undefined;
                },
              );
            },
          );
        },
      );
    },
  );
}

export function add_tag(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["setup", "tag"]));
              let _pipe$1 = $ask.via(_pipe, new $http.Post());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(_pipe$2, $json.string(data.tag_input));
              let _pipe$4 = $ask.expect(_pipe$3, $decode.list($tag.decoder()));
              let _pipe$5 = $ask.notify(
                _pipe$4,
                (tags) => {
                  return dispatch(
                    new $msg.OrganizationSetup(new $msg.ApiReturnedTags(tags)),
                  );
                },
              );
              $ask.run(_pipe$5)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function remove_tag(data, tag) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["setup", "tag"]));
              let _pipe$1 = $ask.via(_pipe, new $http.Delete());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.with$(_pipe$2, $json.string(tag));
              let _pipe$4 = $ask.expect(_pipe$3, $decode.list($tag.decoder()));
              let _pipe$5 = $ask.error(
                _pipe$4,
                (_) => {
                  dispatch(
                    new $msg.ApplicationDisplayedToast(
                      new $level.Error(),
                      "Can't delete tag as there is already a source tagged with it.",
                    ),
                  );
                  return dispatch(
                    new $msg.OrganizationSetup(
                      new $msg.ApiReturnedTags(data.tags),
                    ),
                  );
                },
              );
              let _pipe$6 = $ask.notify(
                _pipe$5,
                (tags) => {
                  return dispatch(
                    new $msg.OrganizationSetup(new $msg.ApiReturnedTags(tags)),
                  );
                },
              );
              $ask.run(_pipe$6)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function fetch_tags(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(new $ask.Heimdall(), toList(["tags"]));
              let _pipe$1 = $ask.via(_pipe, new $http.Get());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.expect(_pipe$2, $decode.list($tag.decoder()));
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (tags) => {
                  return dispatch(
                    new $msg.OrganizationSetup(new $msg.ApiReturnedTags(tags)),
                  );
                },
              );
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}

export function fetch_qualification_matrix(data) {
  return $middleware.require_access_token(
    (access_token) => {
      return $middleware.require_super_admin(
        data,
        () => {
          return $effect.from(
            (dispatch) => {
              let _pipe = $ask.to(
                new $ask.Heimdall(),
                toList(["qualification-matrix"]),
              );
              let _pipe$1 = $ask.via(_pipe, new $http.Get());
              let _pipe$2 = $ask.bearing(_pipe$1, access_token);
              let _pipe$3 = $ask.expect(
                _pipe$2,
                $decode.list($qualification_matrix.decoder()),
              );
              let _pipe$4 = $ask.notify(
                _pipe$3,
                (qm_rows) => {
                  return dispatch(
                    new $msg.OrganizationSetup(
                      new $msg.ApiReturnedQualificationMatrix(qm_rows),
                    ),
                  );
                },
              );
              $ask.run(_pipe$4)
              return undefined;
            },
          );
        },
      );
    },
  );
}
