/// <reference types="./keyframes.d.mts" />
import * as $css from "../../sketch/sketch/css.mjs";
import * as $keyframe from "../../sketch/sketch/css/keyframe.mjs";
import * as $length from "../../sketch/sketch/css/length.mjs";
import { percent } from "../../sketch/sketch/css/length.mjs";
import * as $transform from "../../sketch/sketch/css/transform.mjs";
import { toList } from "../gleam.mjs";

export function pulse() {
  return $css.keyframes(
    "pulse",
    toList([
      $keyframe.at(0, toList([$css.opacity(1.0)])),
      $keyframe.at(50, toList([$css.opacity(0.4)])),
      $keyframe.at(100, toList([$css.opacity(1.0)])),
    ]),
  );
}

function translate_x(value) {
  let transform = $transform.translate_x(percent(value));
  return $css.transform(toList([transform]));
}

export function wave() {
  return $css.keyframes(
    "wave",
    toList([
      $keyframe.at(0, toList([translate_x(-100)])),
      $keyframe.at(50, toList([translate_x(0)])),
      $keyframe.at(100, toList([translate_x(100)])),
    ]),
  );
}

export function fading_text() {
  return $css.keyframes(
    "fading-text",
    toList([
      $keyframe.at(0, toList([$css.opacity(0.0), translate_x(-30)])),
      $keyframe.at(2, toList([$css.opacity(1.0), translate_x(-50)])),
      $keyframe.at(18, toList([$css.opacity(1.0), translate_x(-50)])),
      $keyframe.at(20, toList([$css.opacity(0.0), translate_x(-80)])),
      $keyframe.at(100, toList([$css.opacity(0.0), translate_x(-80)])),
    ]),
  );
}
