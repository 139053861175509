/// <reference types="./table.d.mts" />
import * as $int from "../../../../gleam_stdlib/gleam/int.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import * as $length from "../../../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../../../sketch/sketch/css/length.mjs";
import * as $h from "../../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import * as $colors from "../../../ds/colors.mjs";
import * as $text from "../../../ds/typography/text.mjs";
import { toList } from "../../../gleam.mjs";

export function table(columns, attrs, children) {
  let template = columns[1];
  let _pipe = $css.class$(
    toList([
      $css.border_radius(px(8)),
      $css.border("1px solid " + $colors.light.grey_400),
      $css.display("grid"),
      $css.grid_template_columns(template),
      $css.overflow("auto"),
    ]),
  );
  return $h.table(_pipe, attrs, children);
}

export function theader(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.medium)),
      $css.padding_("10px 20px"),
      $css.background($colors.light.grey_100),
      $css.color($colors.light.onyx),
      $css.white_space("nowrap"),
    ]),
  );
  return $h.th(_pipe, attrs, children);
}

export function tfoot(columns, attrs, children) {
  let columns$1 = $int.to_string(columns);
  let _pipe = $css.class$(
    toList([
      $css.display("flex"),
      $css.grid_column("span " + columns$1),
      $css.border_top("1px solid " + $colors.light.grey_200),
      $css.justify_content("space-between"),
      $css.selector(
        "[disabled='True']",
        toList([$css.pointer_events("none"), $css.opacity(0.3)]),
      ),
    ]),
  );
  return $h.tfoot(_pipe, attrs, children);
}

export function tcell(custom_style, attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.padding(px(20)),
      $css.compose($css.class$(custom_style)),
      $css.padding_right(px(20)),
      $css.padding_left(px(20)),
      $css.overflow("visible"),
      $css.text_overflow("ellipsis"),
      $css.max_width(percent(100)),
      $css.white_space("wrap"),
      $css.hover(toList([$css.background("inherit")])),
    ]),
  );
  return $h.td(_pipe, attrs, children);
}

export function footer_dropdown(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.align_items("center"), $css.gap(px(24))]),
  );
  return $h.div(_pipe, attrs, children);
}

export function footer_actions(attrs, children) {
  let _pipe = $css.class$(
    toList([$css.display("flex"), $css.align_items("center"), $css.gap(px(16))]),
  );
  return $h.div(_pipe, attrs, children);
}

function subgrid(count) {
  let column_end = $int.to_string(count + 1);
  return $css.class$(
    toList([
      $css.display("grid"),
      $css.grid_template_columns("subgrid"),
      $css.grid_column("1 / " + column_end),
    ]),
  );
}

export function thead(columns, attrs, children) {
  let count = columns[0];
  let _pipe = $css.class$(
    toList([
      $css.compose(subgrid(count)),
      $css.border_bottom("1px solid " + $colors.light.grey_400),
      $css.text_align("left"),
      $css.last_child(toList([$css.border_bottom("none")])),
      $css.border_top_right_radius(px(7)),
      $css.border_top_left_radius(px(7)),
      $css.overflow("hidden"),
    ]),
  );
  return $h.thead(_pipe, attrs, children);
}

export function thr(column, attrs, children) {
  let count = column[0];
  return $h.tr(
    $css.class$(toList([$css.compose(subgrid(count))])),
    attrs,
    children,
  );
}

export function tbody(columns, attrs, children) {
  let count = columns[0];
  let _pipe = $css.class$(
    toList([
      $css.compose(subgrid(count)),
      $css.compose($text.sm($text.regular)),
      $css.color($colors.light.paragraph),
      $css.white_space("nowrap"),
      $css.overflow("auto"),
    ]),
  );
  return $h.tbody(_pipe, attrs, children);
}

export function tr(columns, hover, attrs, children) {
  let count = columns[0];
  let _pipe = $css.class$(
    toList([
      $css.compose(subgrid(count)),
      $css.align_items("center"),
      $css.hover(
        (() => {
          if (hover) {
            return toList([
              $css.background($colors.light.grey_100),
              $css.cursor("pointer"),
            ]);
          } else {
            return toList([]);
          }
        })(),
      ),
    ]),
  );
  return $h.tr(_pipe, attrs, children);
}

export function separator(columns) {
  let count = columns[0];
  let _pipe = $css.class$(
    toList([
      $css.compose(subgrid(count)),
      $css.height(px(1)),
      $css.background($colors.light.grey_200),
    ]),
  );
  return $h.tr(_pipe, toList([]), toList([]));
}

export function icon_wrapper(attrs, children) {
  return $h.div(
    $css.class$(toList([$css.width(px(20)), $css.height(px(20))])),
    attrs,
    children,
  );
}
