/// <reference types="./check.d.mts" />
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $svg from "../../../lustre/lustre/element/svg.mjs";
import { toList } from "../../gleam.mjs";

const content = "<rect width=\"256\" height=\"256\" fill=\"none\"/><polyline points=\"40 144 96 200 224 72\" fill=\"none\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"16\"/>";

export function check() {
  return $svg.svg(
    toList([
      $a.style(toList([["max-width", "100%"], ["max-height", "100%"]])),
      $a.attribute("xmlns", "http://www.w3.org/2000/svg"),
      $a.attribute("viewBox", "0 0 256 256"),
      $a.attribute("fill", "currentColor"),
      $a.attribute("dangerous-unescaped-html", content),
    ]),
    toList([]),
  );
}
