/// <reference types="./element.d.mts" />
import * as $attribute from "../../../../lustre/lustre/attribute.mjs";
import * as $el from "../../../../lustre/lustre/element.mjs";
import * as $sketch from "../../../../sketch/sketch.mjs";
import * as $css from "../../../../sketch/sketch/css.mjs";
import { prepend as listPrepend, makeError } from "../../../gleam.mjs";
import * as $global from "../../../sketch/lustre/experimental/internals/global.mjs";

export function element_(tag, attributes, children) {
  return $el.element(tag, attributes, children);
}

export function namespaced_(tag, namespace, attributes, children) {
  return $el.namespaced(tag, namespace, attributes, children);
}

export function class_name(class$) {
  let $ = $global.get_stylesheet();
  if (!$.isOk()) {
    throw makeError(
      "let_assert",
      "sketch/lustre/experimental/element",
      76,
      "class_name",
      "Pattern match failed, no pattern matched the value.",
      { value: $ }
    )
  }
  let stylesheet = $[0];
  let $1 = $sketch.class_name(class$, stylesheet);
  let stylesheet$1 = $1[0];
  let class_name$1 = $1[1];
  let $2 = $global.set_stylesheet(stylesheet$1);
  
  return class_name$1;
}

export function element(tag, class$, attributes, children) {
  let class_name$1 = class_name(class$);
  return $el.element(
    tag,
    listPrepend($attribute.class$(class_name$1), attributes),
    children,
  );
}

export function namespaced(tag, namespace, class$, attributes, children) {
  let class_name$1 = class_name(class$);
  let attributes$1 = listPrepend($attribute.class$(class_name$1), attributes);
  return $el.namespaced(tag, namespace, attributes$1, children);
}

export const keyed = $el.keyed;

export const fragment = $el.fragment;

export const none = $el.none;

export const text = $el.text;

export const map = $el.map;
