/// <reference types="./style_guidelines.d.mts" />
import * as $display from "../../../ds/ds/typography/display.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $h from "../../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList } from "../../gleam.mjs";

export function section_title(attrs, children) {
  let _pipe = $css.class$(toList([$css.compose($display.xs())]));
  return $h.div(_pipe, attrs, children);
}
