import * as Sentry from '@sentry/browser'
import * as prelude from '#gleam/prelude'
import * as option from '#stdlib/gleam/option'

export function init(dsn) {
  try {
    const environment = selectEnvironment()
    // Development, returns None.
    if (environment === 'development') return new prelude.Ok(new option.None())

    const client = Sentry.init({
      dsn,
      environment,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
    })

    // No client, initialisation bugged, returns an error.
    if (!client) return new prelude.Error()
    // Client exists, return Some(client).
    return new prelude.Ok(new option.Some(client))
  } catch (error) {
    return new prelude.Error()
  }
}

export function captureError(error) {
  try {
    if (!Sentry.isInitialized()) return new prelude.Error()
    const err = new Error(error)
    const id = Sentry.captureException(err)
    return new prelude.Ok(id)
  } catch (error) {
    return new prelude.Error()
  }
}

export function captureMessage(message) {
  try {
    if (!Sentry.isInitialized()) return new prelude.Error()
    const id = Sentry.captureMessage(message)
    return new prelude.Ok(id)
  } catch (error) {
    return new prelude.Error()
  }
}

function selectEnvironment() {
  if (typeof window === 'undefined') throw new Error()
  const host = window?.location?.host ?? ''
  if (host.includes('localhost')) return 'development'
  return import.meta.env.VITE_GLEAM_ENV ?? 'production'
}
