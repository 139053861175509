/// <reference types="./sketch.d.mts" />
import { Ok, CustomType as $CustomType } from "./gleam.mjs";
import * as $css from "./sketch/css.mjs";
import * as $error from "./sketch/error.mjs";
import * as $cache from "./sketch/internals/cache/cache.mjs";

class StyleSheet extends $CustomType {
  constructor(cache, is_persistent) {
    super();
    this.cache = cache;
    this.is_persistent = is_persistent;
  }
}

export class Ephemeral extends $CustomType {}

export class Persistent extends $CustomType {}

export function render(cache) {
  return $cache.render_sheet(cache.cache);
}

export function class_name(class$, stylesheet) {
  let $ = $cache.class_name(class$, stylesheet.cache);
  let cache = $[0];
  let class_name$1 = $[1];
  return [
    (() => {
      let _record = stylesheet;
      return new StyleSheet(cache, _record.is_persistent);
    })(),
    class_name$1,
  ];
}

export function at_rule(rule, stylesheet) {
  let cache = $cache.at_rule(rule, stylesheet.cache);
  let _record = stylesheet;
  return new StyleSheet(cache, _record.is_persistent);
}

export function stylesheet(strategy) {
  return new Ok(
    (() => {
      if (strategy instanceof Ephemeral) {
        return new StyleSheet($cache.new$(), false);
      } else {
        return new StyleSheet($cache.new$(), true);
      }
    })(),
  );
}
