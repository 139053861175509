/// <reference types="./model.d.mts" />
import * as $bright from "../../bright/bright.mjs";
import * as $local_storage from "../../ds/plinth/browser/local_storage.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import * as $effect from "../../lustre/lustre/effect.mjs";
import * as $computed from "../data/model/computed.mjs";
import * as $data from "../data/model/data.mjs";
import * as $msg from "../data/msg.mjs";

export function access_token_selector(data) {
  return data.access_token;
}

export function save_access_token(access_token) {
  return $effect.from(
    (dispatch) => {
      if (access_token instanceof $option.None) {
        return $local_storage.remove_item("access-token");
      } else {
        let access_token$1 = access_token[0];
        let $ = $local_storage.set_item("access-token", access_token$1);
        
        return dispatch(new $msg.ApplicationStoredAccessToken());
      }
    },
  );
}
