/// <reference types="./common.d.mts" />
import * as $user from "../../common/data/user.mjs";
import * as $colors from "../../ds/ds/colors.mjs";
import * as $layout from "../../ds/ds/components/layout.mjs";
import * as $pp from "../../ds/ds/components/profile_picture.mjs";
import * as $text from "../../ds/ds/typography/text.mjs";
import * as $bool from "../../gleam_stdlib/gleam/bool.mjs";
import * as $int from "../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../gleam_stdlib/gleam/list.mjs";
import * as $result from "../../gleam_stdlib/gleam/result.mjs";
import * as $a from "../../lustre/lustre/attribute.mjs";
import * as $css from "../../sketch/sketch/css.mjs";
import * as $length from "../../sketch/sketch/css/length.mjs";
import { percent, px } from "../../sketch/sketch/css/length.mjs";
import * as $el from "../../sketch_lustre_experimental/sketch/lustre/experimental/element.mjs";
import * as $h from "../../sketch_lustre_experimental/sketch/lustre/experimental/element/html.mjs";
import { toList, makeError } from "../gleam.mjs";

export function separator() {
  let _pipe = $css.class$(
    toList([
      $css.width(percent(100)),
      $css.height(px(1)),
      $css.background($colors.light.grey_400),
    ]),
  );
  return $h.hr(_pipe, toList([]));
}

export function name_small(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.sm($text.regular)),
      $css.display("flex"),
      $css.align_items("center"),
      $css.gap(px(8)),
      $css.white_space("nowrap"),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function underlined_title(attrs, children) {
  let _pipe = $css.class$(
    toList([
      $css.compose($text.xl($text.medium)),
      $css.display("flex"),
      $css.flex_direction("column"),
      $css.gap(px(16)),
    ]),
  );
  return $h.div(_pipe, attrs, children);
}

export function card() {
  return $css.class$(
    toList([
      $css.border("1px solid " + $colors.light.grey_400),
      $css.border_radius(px(8)),
      $css.padding(px(32)),
    ]),
  );
}

export function small_icon(child) {
  let _pipe = $css.class$(
    toList([
      $css.width(px(20)),
      $css.height(px(20)),
      $css.property("flex-shrink", "0"),
    ]),
  );
  return $h.div(_pipe, toList([]), toList([child]));
}

function stacked_pictures(size, children) {
  let _pipe = $css.class$(
    toList([
      $css.display("grid"),
      $css.grid_template_columns(
        ("repeat(" + $int.to_string(size)) + ", 12px) 12px",
      ),
      $css.transition("all .2s"),
      $css.hover(
        toList([
          $css.grid_template_columns(
            ("repeat(" + $int.to_string(size)) + ", 24px) 0px",
          ),
        ]),
      ),
    ]),
  );
  return $h.div(_pipe, toList([]), children);
}

export function stacked_avatars(users_id, users) {
  let firsts = $list.take(users_id, 4);
  let first_size = $list.length(firsts);
  let rest = $list.length($list.drop(users_id, 4));
  return $layout.row(
    toList([$layout.align("center"), $layout.gap(8)]),
    toList([]),
    toList([
      stacked_pictures(
        first_size,
        $list.index_map(
          firsts,
          (user_id, index) => {
            let user = $list.find(users, (u) => { return u.id === user_id; });
            return $bool.lazy_guard(
              $result.is_error(user),
              $el.none,
              () => {
                if (!user.isOk()) {
                  throw makeError(
                    "let_assert",
                    "view/common",
                    88,
                    "",
                    "Pattern match failed, no pattern matched the value.",
                    { value: user }
                  )
                }
                let user$1 = user[0];
                let start = $int.to_string(index + 1);
                let end = $int.to_string(index + 3);
                let col = (start + " / ") + end;
                return $h.div_(
                  toList([
                    $a.style(toList([["grid-row", "1"], ["grid-column", col]])),
                  ]),
                  toList([
                    $pp.from_user(
                      user$1.picture,
                      user$1.initials,
                      new $pp.Medium(),
                    ),
                  ]),
                );
              },
            );
          },
        ),
      ),
      (() => {
        if (rest === 0) {
          return $el.none();
        } else {
          let rest$1 = rest;
          return $h.text("+" + $int.to_string(rest$1));
        }
      })(),
    ]),
  );
}
