/// <reference types="./table.d.mts" />
import * as $int from "../../../gleam_stdlib/gleam/int.mjs";
import * as $list from "../../../gleam_stdlib/gleam/list.mjs";
import * as $option from "../../../gleam_stdlib/gleam/option.mjs";
import { None, Some } from "../../../gleam_stdlib/gleam/option.mjs";
import * as $pair from "../../../gleam_stdlib/gleam/pair.mjs";
import * as $result from "../../../gleam_stdlib/gleam/result.mjs";
import * as $string from "../../../gleam_stdlib/gleam/string.mjs";
import * as $a from "../../../lustre/lustre/attribute.mjs";
import * as $e from "../../../lustre/lustre/element.mjs";
import * as $h from "../../../lustre/lustre/element/html.mjs";
import * as $event from "../../../lustre/lustre/event.mjs";
import * as $css from "../../../sketch/sketch/css.mjs";
import * as $length from "../../../sketch/sketch/css/length.mjs";
import * as $s from "../../ds/internals/styles/table.mjs";
import * as $utils from "../../ds/utils.mjs";
import { Ok, Error, toList, CustomType as $CustomType } from "../../gleam.mjs";

class Fit extends $CustomType {}

class MinContent extends $CustomType {}

class Fraction extends $CustomType {
  constructor(x0) {
    super();
    this[0] = x0;
  }
}

class Clamp extends $CustomType {
  constructor(x0, x1) {
    super();
    this[0] = x0;
    this[1] = x1;
  }
}

class Header extends $CustomType {
  constructor(title, size) {
    super();
    this.title = title;
    this.size = size;
  }
}

class Cell extends $CustomType {
  constructor(content, style, stop_propagation) {
    super();
    this.content = content;
    this.style = style;
    this.stop_propagation = stop_propagation;
  }
}

class Row extends $CustomType {
  constructor(cells, hover, on_click, id) {
    super();
    this.cells = cells;
    this.hover = hover;
    this.on_click = on_click;
    this.id = id;
  }
}

class NoRow extends $CustomType {}

class Headers extends $CustomType {
  constructor(headers) {
    super();
    this.headers = headers;
  }
}

class Rows extends $CustomType {
  constructor(rows) {
    super();
    this.rows = rows;
  }
}

class Footer extends $CustomType {
  constructor(disabled, elements) {
    super();
    this.disabled = disabled;
    this.elements = elements;
  }
}

function size_to_string(size) {
  if (size instanceof Fit) {
    return "auto";
  } else if (size instanceof MinContent) {
    return "min-content";
  } else if (size instanceof Fraction) {
    let value = size[0];
    return $int.to_string(value) + "fr";
  } else {
    let preferred = size[0];
    let value = size[1];
    return ((("clamp(0px, " + preferred) + ", ") + $int.to_string(value)) + "px)";
  }
}

function is_headers(node) {
  if (node instanceof Headers) {
    return true;
  } else if (node instanceof Rows) {
    return false;
  } else {
    return false;
  }
}

function is_rows(node) {
  if (node instanceof Headers) {
    return false;
  } else if (node instanceof Rows) {
    return true;
  } else {
    return false;
  }
}

function is_footer(node) {
  if (node instanceof Headers) {
    return false;
  } else if (node instanceof Rows) {
    return false;
  } else {
    return true;
  }
}

function compute_columns(lines) {
  if (lines instanceof Headers && lines.headers.hasLength(0)) {
    return new Error(undefined);
  } else if (lines instanceof Rows && lines.rows.hasLength(0)) {
    return new Error(undefined);
  } else if (lines instanceof Rows) {
    return new Error(undefined);
  } else if (lines instanceof Footer) {
    return new Error(undefined);
  } else {
    let headers$1 = lines.headers;
    let count = $list.length(headers$1);
    let _pipe = $list.map(headers$1, (h) => { return h.size; });
    let _pipe$1 = $list.map(_pipe, size_to_string);
    let _pipe$2 = $string.join(_pipe$1, " ");
    let _pipe$3 = ((_capture) => { return $pair.new$(count, _capture); })(
      _pipe$2,
    );
    return new Ok(_pipe$3);
  }
}

function view_table(columns, headers, body, footer) {
  return $s.table(
    columns,
    toList([]),
    toList([
      (() => {
        if (!headers.isOk()) {
          return $e.none();
        } else if (headers.isOk() && headers[0] instanceof Footer) {
          return $e.none();
        } else if (headers.isOk() && headers[0] instanceof Rows) {
          return $e.none();
        } else if (headers.isOk() &&
        headers[0] instanceof Headers &&
        headers[0].headers.hasLength(0)) {
          return $e.none();
        } else {
          let headers$1 = headers[0].headers;
          return $s.thead(
            columns,
            toList([]),
            toList([
              $s.thr(
                columns,
                toList([]),
                $list.map(
                  headers$1,
                  (header) => {
                    return $s.theader(
                      toList([]),
                      toList([$h.text(header.title)]),
                    );
                  },
                ),
              ),
            ]),
          );
        }
      })(),
      (() => {
        if (!body.isOk()) {
          return $e.none();
        } else if (body.isOk() && body[0] instanceof Footer) {
          return $e.none();
        } else if (body.isOk() &&
        body[0] instanceof Rows &&
        body[0].rows.hasLength(0)) {
          return $e.none();
        } else if (body.isOk() && body[0] instanceof Headers) {
          return $e.none();
        } else {
          let rows = body[0].rows;
          let _pipe = $list.flat_map(
            rows,
            (row) => {
              if (row instanceof NoRow) {
                return toList([]);
              } else {
                let on_click$1 = (() => {
                  let $ = row.on_click;
                  if ($ instanceof $option.None) {
                    return $a.none();
                  } else {
                    let msg = $[0];
                    return $event.on_click(msg);
                  }
                })();
                let tr_attributes = (() => {
                  let $ = row.id;
                  if ($ instanceof Some) {
                    let id = $[0];
                    return toList([on_click$1, $a.id("row-id-" + id)]);
                  } else {
                    return toList([on_click$1]);
                  }
                })();
                let _pipe = $s.tr(
                  columns,
                  row.hover,
                  tr_attributes,
                  $list.map(
                    row.cells,
                    (data) => {
                      let on_click$2 = (() => {
                        let $ = data.stop_propagation;
                        if ($) {
                          return $utils.stop_propagation();
                        } else {
                          return $a.none();
                        }
                      })();
                      return $s.tcell(
                        $option.unwrap(data.style, toList([])),
                        toList([on_click$2]),
                        toList([data.content]),
                      );
                    },
                  ),
                );
                return $list.wrap(_pipe);
              }
            },
          );
          let _pipe$1 = $list.intersperse(_pipe, $s.separator(columns));
          return ((_capture) => {
            return $s.tbody(columns, toList([]), _capture);
          })(_pipe$1);
        }
      })(),
      (() => {
        if (!footer.isOk()) {
          return $e.none();
        } else if (footer.isOk() && footer[0] instanceof Rows) {
          return $e.none();
        } else if (footer.isOk() && footer[0] instanceof Headers) {
          return $e.none();
        } else {
          let disabled = footer[0].disabled;
          let elements = footer[0].elements;
          if (disabled) {
            return $e.none();
          } else {
            return $s.tfoot(columns[0], toList([]), elements);
          }
        }
      })(),
    ]),
  );
}

export function table(children) {
  let headers$1 = $list.find(children, is_headers);
  let body$1 = $list.find(children, is_rows);
  let footer$1 = $list.find(children, is_footer);
  let _pipe = $result.try$(headers$1, compute_columns);
  let _pipe$1 = $result.try_recover(
    _pipe,
    (_) => { return $result.try$(body$1, compute_columns); },
  );
  let _pipe$2 = $result.map(
    _pipe$1,
    (_capture) => { return view_table(_capture, headers$1, body$1, footer$1); },
  );
  return $result.unwrap(_pipe$2, $e.none());
}

export function headers(headers) {
  return new Headers(headers);
}

export function header(title) {
  return new Header(title, new Fraction(1));
}

export function fit(header) {
  let _record = header;
  return new Header(_record.title, new Fit());
}

export function fraction(header, fr) {
  let _record = header;
  return new Header(_record.title, new Fraction(fr));
}

export function min_content(header) {
  let _record = header;
  return new Header(_record.title, new MinContent());
}

export function clamp(header, preferred, max_width) {
  let _record = header;
  return new Header(_record.title, new Clamp(preferred, max_width));
}

export function body(rows) {
  return new Rows(rows);
}

export function row(cells) {
  return new Row(cells, true, new $option.None(), new $option.None());
}

export function hover(row, value) {
  if (row instanceof NoRow) {
    return new NoRow();
  } else {
    let _record = row;
    return new Row(_record.cells, value, _record.on_click, _record.id);
  }
}

export function on_click(row, on_click) {
  if (row instanceof NoRow) {
    return new NoRow();
  } else {
    let _record = row;
    return new Row(
      _record.cells,
      _record.hover,
      new $option.Some(on_click),
      _record.id,
    );
  }
}

export function row_id(row, id) {
  if (row instanceof NoRow) {
    return new NoRow();
  } else {
    let _record = row;
    return new Row(
      _record.cells,
      _record.hover,
      _record.on_click,
      new $option.Some(id),
    );
  }
}

export function cell(cell) {
  return new Cell(cell, new $option.None(), false);
}

export function none() {
  return new NoRow();
}

export function stop_cell_propagation(cell) {
  let _record = cell;
  return new Cell(_record.content, _record.style, true);
}

function apply_style(cell, style) {
  let style$1 = (() => {
    let _pipe = cell.style;
    let _pipe$1 = $option.unwrap(_pipe, toList([]));
    let _pipe$2 = $list.prepend(_pipe$1, style);
    return new $option.Some(_pipe$2);
  })();
  let _record = cell;
  return new Cell(_record.content, style$1, _record.stop_propagation);
}

export function span(cell, span) {
  return apply_style(cell, $css.grid_column("span " + $int.to_string(span)));
}

export function background(cell, background) {
  return apply_style(cell, $css.background(background));
}

export function justify(cell, justify) {
  return apply_style(cell, $css.justify_self(justify));
}

export function padding(cell, padding) {
  return apply_style(cell, $css.padding($length.px(padding)));
}

export function footer(disabled, elements) {
  return new Footer(disabled, elements);
}
