/// <reference types="./ai_config.d.mts" />
import * as $json from "../../gleam_json/gleam/json.mjs";
import * as $decode from "../../gleam_stdlib/gleam/dynamic/decode.mjs";
import * as $option from "../../gleam_stdlib/gleam/option.mjs";
import { Some } from "../../gleam_stdlib/gleam/option.mjs";
import * as $decrypt from "../decrypt.mjs";
import { toList, CustomType as $CustomType } from "../gleam.mjs";

export class Hybrid extends $CustomType {}

export class Rerank extends $CustomType {}

export class Fusion extends $CustomType {}

export class AzureGpt4o extends $CustomType {}

export class AiConfig extends $CustomType {
  constructor(org_id, config) {
    super();
    this.org_id = org_id;
    this.config = config;
  }
}

export class Config extends $CustomType {
  constructor(style_guidelines, ai_system, llm_config, models_config, query_config, retriever_config, pii_vocabulary) {
    super();
    this.style_guidelines = style_guidelines;
    this.ai_system = ai_system;
    this.llm_config = llm_config;
    this.models_config = models_config;
    this.query_config = query_config;
    this.retriever_config = retriever_config;
    this.pii_vocabulary = pii_vocabulary;
  }
}

export class AdvancedRag extends $CustomType {}

export class LlmEnhancedVectorSearch extends $CustomType {}

export class LLMConfig extends $CustomType {
  constructor(limit) {
    super();
    this.limit = limit;
  }
}

export class ModelsConfig extends $CustomType {
  constructor(rag, rag_temperature, target, style_2, summary, classifier, default_model, default_fallback_models) {
    super();
    this.rag = rag;
    this.rag_temperature = rag_temperature;
    this.target = target;
    this.style_2 = style_2;
    this.summary = summary;
    this.classifier = classifier;
    this.default_model = default_model;
    this.default_fallback_models = default_fallback_models;
  }
}

export class QueryConfig extends $CustomType {
  constructor(limit, alpha, parent_lookup, hyde, extract_question, translate_on) {
    super();
    this.limit = limit;
    this.alpha = alpha;
    this.parent_lookup = parent_lookup;
    this.hyde = hyde;
    this.extract_question = extract_question;
    this.translate_on = translate_on;
  }
}

export class RetrieverConfig extends $CustomType {
  constructor(option) {
    super();
    this.option = option;
  }
}

export function ai_system_decoder() {
  return $decode.then$(
    $decode.string,
    (ai_system) => {
      let expected = "advanced_rag | llm_enhanced_vector_search";
      if (ai_system === "advanced_rag") {
        return $decode.success(new AdvancedRag());
      } else if (ai_system === "llm_enhanced_vector_search") {
        return $decode.success(new LlmEnhancedVectorSearch());
      } else {
        return $decode.failure(new AdvancedRag(), expected);
      }
    },
  );
}

function llm_config_decoder() {
  return $decode.field(
    "limit",
    $decode.int,
    (limit) => { return $decode.success(new LLMConfig(limit)); },
  );
}

function query_config_decoder() {
  return $decode.field(
    "limit",
    $decode.int,
    (limit) => {
      return $decode.field(
        "alpha",
        $decrypt.float(),
        (alpha) => {
          let parent_lookup = $decode.optional($decode.bool);
          return $decode.field(
            "parent_lookup",
            parent_lookup,
            (parent_lookup) => {
              return $decode.field(
                "hyde",
                $decode.optional($decode.bool),
                (hyde) => {
                  let extract_question = $decode.optional($decode.bool);
                  return $decode.field(
                    "extract_question",
                    extract_question,
                    (extract_question) => {
                      return $decode.field(
                        "translate_on",
                        $decode.optional($decode.bool),
                        (translate_on) => {
                          return $decode.success(
                            new QueryConfig(
                              limit,
                              alpha,
                              parent_lookup,
                              hyde,
                              extract_question,
                              translate_on,
                            ),
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function model_decoder() {
  return $decode.then$(
    $decode.string,
    (option) => {
      let expected = "azure/azure-gpt-4o";
      if (option === "azure/azure-gpt-4o") {
        return $decode.success(new AzureGpt4o());
      } else {
        return $decode.failure(new AzureGpt4o(), expected);
      }
    },
  );
}

function models_config_decoder() {
  return $decode.field(
    "rag",
    $decode.optional($decode.string),
    (rag) => {
      return $decode.field(
        "rag_temperature",
        $decode.optional($decrypt.float()),
        (rag_temperature) => {
          return $decode.field(
            "target",
            $decode.optional($decode.string),
            (target) => {
              return $decode.field(
                "style_2",
                $decode.optional(model_decoder()),
                (style_2) => {
                  return $decode.field(
                    "summary",
                    $decode.optional(model_decoder()),
                    (summary) => {
                      return $decode.field(
                        "classifier",
                        $decode.optional(model_decoder()),
                        (classifier) => {
                          return $decode.field(
                            "default_model",
                            $decode.optional(model_decoder()),
                            (default_model) => {
                              return $decode.field(
                                "default_fallback_models",
                                $decode.list(model_decoder()),
                                (default_fallback_models) => {
                                  return $decode.success(
                                    new ModelsConfig(
                                      rag,
                                      rag_temperature,
                                      target,
                                      style_2,
                                      summary,
                                      classifier,
                                      default_model,
                                      default_fallback_models,
                                    ),
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

function retriever_option_type_decoder() {
  return $decode.then$(
    $decode.string,
    (option) => {
      let expected = "hybrid | rerank | fusion";
      if (option === "hybrid") {
        return $decode.success(new Hybrid());
      } else if (option === "rerank") {
        return $decode.success(new Rerank());
      } else if (option === "fusion") {
        return $decode.success(new Fusion());
      } else {
        return $decode.failure(new Hybrid(), expected);
      }
    },
  );
}

function retriever_config_decoder() {
  return $decode.field(
    "option",
    retriever_option_type_decoder(),
    (option) => { return $decode.success(new RetrieverConfig(option)); },
  );
}

export function config_decoder() {
  let style_guidelines = $decode.optional($decode.string);
  return $decode.field(
    "style_guidelines",
    style_guidelines,
    (style_guidelines) => {
      let ai_system = $decode.optional(ai_system_decoder());
      return $decode.field(
        "ai_system",
        ai_system,
        (ai_system) => {
          let llm_config = $decode.optional(llm_config_decoder());
          return $decode.field(
            "llm_config",
            llm_config,
            (llm_config) => {
              let models_config = $decode.optional(models_config_decoder());
              return $decode.field(
                "models_config",
                models_config,
                (models_config) => {
                  let query_config = $decode.optional(query_config_decoder());
                  return $decode.field(
                    "query_config",
                    query_config,
                    (query_config) => {
                      let retriever_config = $decode.optional(
                        retriever_config_decoder(),
                      );
                      return $decode.field(
                        "retriever_config",
                        retriever_config,
                        (retriever_config) => {
                          let pii_vocabulary = $decode.list($decode.string);
                          return $decode.field(
                            "pii_vocabulary",
                            pii_vocabulary,
                            (pii_vocabulary) => {
                              return $decode.success(
                                new Config(
                                  style_guidelines,
                                  ai_system,
                                  llm_config,
                                  models_config,
                                  query_config,
                                  retriever_config,
                                  pii_vocabulary,
                                ),
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    },
  );
}

export function decoder() {
  return $decode.field(
    "org_id",
    $decode.string,
    (org_id) => {
      return $decode.field(
        "config",
        $decrypt.json(config_decoder()),
        (config) => { return $decode.success(new AiConfig(org_id, config)); },
      );
    },
  );
}

function encode_ai_system(ai_system) {
  if (ai_system instanceof AdvancedRag) {
    return $json.string("advanced_rag");
  } else {
    return $json.string("llm_enhanced_vector_search");
  }
}

function encode_llm_config(llm_config) {
  return $json.object(toList([["limit", $json.int(llm_config.limit)]]));
}

function encode_query_config(query_config) {
  return $json.object(
    toList([
      ["limit", $json.int(query_config.limit)],
      ["alpha", $json.float(query_config.alpha)],
      ["parent_lookup", $json.nullable(query_config.parent_lookup, $json.bool)],
      ["hyde", $json.nullable(query_config.hyde, $json.bool)],
      [
        "extract_question",
        $json.nullable(query_config.extract_question, $json.bool),
      ],
      ["translate_on", $json.nullable(query_config.translate_on, $json.bool)],
    ]),
  );
}

function encode_retriever_option_type(option) {
  if (option instanceof Hybrid) {
    return $json.string("hybrid");
  } else if (option instanceof Rerank) {
    return $json.string("rerank");
  } else {
    return $json.string("fusion");
  }
}

function encode_retriever_config(retriever_config) {
  return $json.object(
    toList([["option", encode_retriever_option_type(retriever_config.option)]]),
  );
}

export function encode_model(model) {
  {
    return $json.string("azure/azure-gpt-4o");
  }
}

function encode_models_config(models_config) {
  return $json.object(
    toList([
      ["rag", $json.nullable(models_config.rag, $json.string)],
      [
        "rag_temperature",
        $json.nullable(models_config.rag_temperature, $json.float),
      ],
      ["target", $json.nullable(models_config.target, $json.string)],
      ["style_2", $json.nullable(models_config.style_2, encode_model)],
      ["summary", $json.nullable(models_config.summary, encode_model)],
      ["classifier", $json.nullable(models_config.classifier, encode_model)],
      [
        "default_model",
        $json.nullable(models_config.default_model, encode_model),
      ],
      [
        "default_fallback_models",
        $json.array(models_config.default_fallback_models, encode_model),
      ],
    ]),
  );
}

export function encode_config(config) {
  return $json.object(
    toList([
      [
        "style_guidelines",
        $json.nullable(config.style_guidelines, $json.string),
      ],
      ["ai_system", $json.nullable(config.ai_system, encode_ai_system)],
      ["llm_config", $json.nullable(config.llm_config, encode_llm_config)],
      [
        "models_config",
        $json.nullable(config.models_config, encode_models_config),
      ],
      ["query_config", $json.nullable(config.query_config, encode_query_config)],
      [
        "retriever_config",
        $json.nullable(config.retriever_config, encode_retriever_config),
      ],
      ["pii_vocabulary", $json.array(config.pii_vocabulary, $json.string)],
    ]),
  );
}

export function encode(ai_config) {
  return $json.object(
    toList([
      ["org_id", $json.string(ai_config.org_id)],
      ["config", encode_config(ai_config.config)],
    ]),
  );
}

export function default_config() {
  let retriever_config = new RetrieverConfig(new Rerank());
  let query_config = new QueryConfig(
    50,
    0.5,
    new Some(false),
    new Some(false),
    new Some(false),
    new Some(true),
  );
  let models_config = new ModelsConfig(
    new Some("azure/azure-gpt-4o"),
    new Some(0.0),
    new Some("auto_fill"),
    new Some(new AzureGpt4o()),
    new Some(new AzureGpt4o()),
    new Some(new AzureGpt4o()),
    new Some(new AzureGpt4o()),
    toList([new AzureGpt4o()]),
  );
  let llm_config = new LLMConfig(10);
  return new Config(
    new Some(""),
    new Some(new AdvancedRag()),
    new Some(llm_config),
    new Some(models_config),
    new Some(query_config),
    new Some(retriever_config),
    toList([]),
  );
}

export function current_or_default_config(config) {
  if (config instanceof $option.Some) {
    let config$1 = config[0];
    return config$1;
  } else {
    return default_config();
  }
}
